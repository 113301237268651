import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";
import validator from "validator";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";

// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
import Snackbars from "components/Snackbar/Snackbar.js";
import GridContainer from "../../components/Grid/GridContainer";
import NavPills from "components/NavPills/NavPills.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Muted from "components/Typography/Muted.js";
import CloudIcon from "@material-ui/icons/Cloud";
import Loading from "components/Loading/Loading.js";

// material-ui components
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

// Icons
import ClearIcon from "@material-ui/icons/Clear";
import LockIcon from "@material-ui/icons/Lock";
import DataUsage from "@material-ui/icons/DataUsage";
import PeopleIcon from "@material-ui/icons/People";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AddCommentIcon from "@material-ui/icons/AddComment";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ShareIcon from "@material-ui/icons/Share";
import EditIcon from "@material-ui/icons/Edit";
import ListAltIcon from "@material-ui/icons/ListAlt";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AddAlert from "@material-ui/icons/AddAlert";
import Check from "@material-ui/icons/Check";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Close from "@material-ui/icons/Close";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";

/*################
  # Form classes #
  ################*/

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import EmailIcon from "@material-ui/icons/Email";

import profileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { Tooltip } from "@material-ui/core";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import AddBoxIcon from "@material-ui/icons/AddBox";

import {
  roseColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useProfileStyles = makeStyles(profileStyles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PackageDetails = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const profileClasses = useProfileStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  // Retrieve package search parameters
  const query = new URLSearchParams(props.location.search);
  const packageParams = {
    data_id: query.get("data_id"),
    hash: query.get("hash"),
    sender: query.get("sender"),
    receiver: query.get("receiver"),
  };
  const otherUser = t("other");

  // Package details
  const [loading, setLoading] = React.useState(true);
  const [ownerActive, setOwnerActive] = React.useState(false);
  const [inviteActive, setInviteActive] = React.useState(false);
  const [showPublishDialog, setShowPublishDialog] = React.useState(false);
  const [packageDetails, setPackageDetails] = React.useState({});
  const [packageVerification, setPackageVerification] = React.useState({});
  const [packageDecryptionKey, setPackageDecryptionKey] = React.useState({});
  const [packageTransactionChunks, setPackageTransactionChunks] =
    React.useState([]);
  const [showVerificationDialog, setShowVerificationDialog] =
    React.useState(false);
  const [showHashVerificationDialog, setShowHashVerificationDialog] =
    React.useState(false);
  const [packageParentId, setPackageParentId] = React.useState("");
  const [packageShareable, setPackageShareable] = React.useState([]);
  const [updatePackageTTL, setUpdatePackageTTL] = React.useState([]);
  const [packageTTL, setPackageTTL] = React.useState(-1);
  const [packageStoreForever, setPackageStoreForever] = React.useState([]);
  const [packageState, setPackageState] = React.useState(
    ApiService.packageState.pending
  );
  const [showPackageProgress, setShowPackageProgress] = React.useState(false);
  const [packageProgress, setPackageProgress] = React.useState(0);
  const [packageProgressRuntime, setPackageProgressRuntime] = React.useState(0);
  const [packageJobId, setPackageJobId] = React.useState("");
  const [updatePackageButtonText, setUpdatePackageButtonText] = React.useState(
    t("update-package")
  );

  const [packageComment, setPackageComment] = React.useState("");
  const [packageErrorMessage, setPackageErrorMessage] = React.useState("");
  const [packageSuccessMessage, setPackageSuccessMessage] = React.useState("");
  const [lookupUsers, setLookupUsers] = React.useState({});

  // Package actions
  const [showShareDialog, setShowShareDialog] = React.useState(false);
  const [selectedPackage, setSelectedPackage] = React.useState({});
  const [sharingRecipients, setSharingRecipients] = React.useState([]);
  const [canShare, setCanShare] = React.useState([1]);
  const [sharingTitle, setSharingTitle] = React.useState("");
  const [sharingMessage, setSharingMessage] = React.useState("");
  const [shareTTL, setShareTTL] = React.useState(-1);
  const [storeForever, setStoreForever] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [progressRuntime, setProgressRuntime] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [shareAbortController, setShareAbortController] =
    React.useState(undefined);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [confirmButtonText, setConfirmButtonText] = React.useState(t("share"));
  const [jobId, setJobId] = React.useState("");
  const [refreshDisabled, setRefreshDisabled] = React.useState(false);
  const [userAddress, setUserAddress] = React.useState("");
  const [sharingUsers, setSharingUsers] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const [signatureVerification, setSignatureVerification] = React.useState({});
  const [signatureDetails, setSignatureDetails] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [modalUpdateAccess, setModalUpdateAccess] = React.useState(
    ApiService.signatureAccess.private
  );

  const [showSignatureModal, setShowSignatureModal] = React.useState(false);
  const [modalBlockchain, setModalBlockchain] = React.useState(
    ApiService.blockchainTypes.bsv
  );
  const [signatureAbortController, setSignatureAbortController] =
    React.useState(undefined);

  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("update")
  );

  const [packageQrCode, setPackageQrCode] = React.useState(undefined);

  const [packageMetadataName, setPackageMetadataName] = React.useState("");
  const [packageMetadataType, setPackageMetadataType] = React.useState(
    ApiService.metadataTypes.string
  );
  const [packageMetadata, setPackageMetadata] = React.useState("");

  const [packageAccessRightsEmail, setPackageAccessRightsEmail] =
    React.useState("");
  const [packageAccessRightsEmailUser, setPackageAccessRightsEmailUser] =
    React.useState("");
  const [packageAccessRightsTTL, setPackageAccessRightsTTL] =
    React.useState("-1");
  const [packageAccessRightsForever, setPackageAccessRightsForever] =
    React.useState([]);

  const [reportingFieldType, setReportingFieldType] = React.useState(
    ApiService.reportingFieldTypes.text
  );
  const [xCoordinate, setXCoordinate] = React.useState("");
  const [yCoordinate, setYCoordinate] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState("");
  const [rectangleWidth, setRectangleWidth] = React.useState("");
  const [rectangleHeight, setRectangleHeight] = React.useState("");
  const [pageToNumber, setPageToNumber] = React.useState("");
  const [signatureEmail, setSignatureEmail] = React.useState("");
  const [fontSize, setFontSize] = React.useState("");
  const [bold, setBold] = React.useState([]);
  const [italic, setItalic] = React.useState([]);
  const [dateFormat, setDateFormat] = React.useState("");
  const [text, setText] = React.useState("");

  const [packageAccessRightsType, setPackageAccessRightsType] = React.useState(
    ApiService.accessRightsTypes.sign
  );
  const [usersList, setUsersList] = React.useState([]);

  const clearModalState = () => {
    setShowModal(false);
    setShowSignatureModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("update"));
    setModalUpdateAccess(ApiService.signatureAccess.private);
    setModalBlockchain(ApiService.blockchainTypes.bsv);
    setSignatureDetails({});
    setSignatureAbortController(undefined);
  };

  const clearSharingState = () => {
    setShowPublishDialog(false);
    setShowShareDialog(false);
    setSharingRecipients([]);
    setCanShare([]);
    setSharingTitle("");
    setSharingMessage("");
    setShareTTL(-1);
    setStoreForever([]);
    setShowProgress(false);
    setProgress(0);
    setProgressRuntime(0);
    setMessage("");
    setSuccessMessage("");
    setShareAbortController(undefined);
    setConfirmDialog(false);
    setConfirmButtonText(t("share"));
    setJobId("");
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const downloadError = (resultMessage, responseData = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("error")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {responseData && (
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={responseData.progress}
            />
            <Muted>
              {responseData.jobId} - {responseData.progress}% (
              {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
            </Muted>
            <br />
          </React.Fragment>
        )}
        <Danger>{resultMessage}</Danger>
      </SweetAlert>
    );
  };

  const deletePackageCommentAsync = (data) => {
    const handlePackageCommentDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        commentId: data._id,
        ...packageParams,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deletePackageComment(params, signal);
        packageResult(true, t("package-comment-successfully-deleted"), false);
      } catch (e) {
        packageResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-package-comment")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePackageCommentDeletion();
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId = userProfileData._id;
      params.email = userProfileData.email;
    } else {
      params.userId = returnData._id;
      params.email = returnData.email;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const formatUserEntry = (address) => {
    if (lookupUsers.hasOwnProperty(address)) {
      return `${lookupUsers[address].user.email} (${address})`;
    }
    return address;
  };

  const handleAddPackageComment = async (event) => {
    event.preventDefault();
    const packageCommentParsed = packageComment.trim();
    if (packageCommentParsed.length === 0) {
      return showPackageError(t("package-comment-field-empty"));
    }
    setRefreshDisabled(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData = undefined;
    let error = false;
    try {
      const { userId } = await getQueryParams(signal);
      returnData = await ApiService.addPackageComment(
        {
          userId,
          data_id: packageDetails.data_id,
          hash: packageDetails.hash,
          sender: packageDetails.sender,
          receiver: packageDetails.receiver,
          comment: packageCommentParsed,
        },
        signal
      );
    } catch (e) {
      error = true;
      showPackageError(t("failed-to-add-package-comment"));
    }
    handleRefresh(returnData);
    if (!error) {
      return showPackageSuccess(t("package-comment-added-successfully"));
    }
  };

  const showPackageError = (messageContent) => {
    setPackageErrorMessage(messageContent);
    setTimeout(() => {
      setPackageErrorMessage("");
    }, ApiService.messageTimeout);
  };

  const showPackageSuccess = (messageContent) => {
    setPackageSuccessMessage(messageContent);
    setTimeout(() => {
      setPackageSuccessMessage("");
    }, ApiService.messageTimeout);
  };

  const getAllUsers = async (signal = undefined) => {
    try {
      const { userId } = await getQueryParams(signal);
      const { address } = await ApiService.getPublicKey({ userId }, signal);
      const returnData = await ApiService.getAllUsers({}, signal);
      setSharingUsers(
        returnData.filter((entry) => {
          return entry.address !== address;
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const getPublicShareUser = async (signal = undefined) => {
    try {
      const publicShareUser = await ApiService.getPublicShareUser(signal);
      setSharingUsers([publicShareUser]);
      setSharingRecipients([publicShareUser.address]);
    } catch (e) {
      console.error(e);
    }
  };

  const getValidPackageStates = () => {
    const validPackageStates = ApiService.getValidPackageStates(
      userAddress,
      packageDetails.sender,
      packageDetails.receiver,
      packageDetails.state
    );
    return Object.keys(ApiService.packageState)
      .sort()
      .map((key) => {
        return (
          <MenuItem
            key={key}
            disabled={
              !validPackageStates.includes(ApiService.packageState[key])
            }
            classes={{
              root: formClasses.selectMenuItem,
              selected: formClasses.selectMenuItemSelected,
            }}
            value={ApiService.packageState[key]}
          >
            {ApiService.packageState[key]}
          </MenuItem>
        );
      });
  };

  const getFrontendUrl = (queryPackageParams) => {
    const params = new URLSearchParams(queryPackageParams).toString();
    const url = new URL(`${ApiService.idFrontendURL}/auth/login-page`);
    url.search = new URLSearchParams({
      deploymentId: ApiService.instanceId,
      redirectPath: `/admin/package?${params}`,
    });
    return url.toString();
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;
    let packageParams;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      packageParams = {
        data_id: queryParams.get("data_id"),
        hash: queryParams.get("hash"),
        sender: queryParams.get("sender"),
        receiver: queryParams.get("receiver"),
      };
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
        params.email = userProfileData.email;
      } else {
        params.userId = returnData._id;
        params.email = returnData.email;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const handleRefresh = async (
      returnData = undefined,
      signal = undefined
    ) => {
      setRefreshDisabled(true);
      let packageData, userId, userEmail;
      if (!returnData) {
        try {
          const queryParams = await getQueryParams(signal);
          userId = queryParams.userId;
          userEmail = queryParams.email;
          const queryPackageParams = {
            ...packageParams,
            userId,
          };
          packageData = await ApiService.getPackageDetails(
            queryPackageParams,
            signal
          );
          const packageVerificationData = await ApiService.getPackageSignature(
            queryPackageParams,
            signal
          );
          setPackageVerification(packageVerificationData);
          if (packageData.blockchainStore) {
            const decryptionKey = await ApiService.getPackageDecryptionKey(
              queryPackageParams,
              signal
            );
            const transactionChunks =
              await ApiService.getPackageTransactionChunks(
                queryPackageParams,
                signal
              );
            setPackageDecryptionKey(decryptionKey);
            setPackageTransactionChunks(transactionChunks);
          }

          const qrCode = await ApiService.getPackageQrCode(
            {
              userId,
              frontendUrl: getFrontendUrl(packageParams),
              packageId: packageData._id,
            },
            signal
          );
          setPackageQrCode(qrCode.qrCode);
        } catch (e) {
          console.error(e);
          props.history.push("/");
        }
      } else {
        packageData = returnData;
      }
      const users = {};
      try {
        if (!userId) {
          const queryParams = await getQueryParams(signal);
          userId = queryParams.userId;
          userEmail = queryParams.email;
        }
        const { address } = await ApiService.getPublicKey({ userId }, signal);
        const responseData = await ApiService.getAllUsers({}, signal);
        // eslint-disable-next-line no-unused-vars
        for (const entry of responseData) {
          users[entry.address] = entry;
        }
        setUsersList(
          responseData.filter((entry) => {
            return entry.address !== address;
          })
        );
        setUserAddress(address);
        setLookupUsers(users);
        setInviteActive(
          !!packageData.accessRights.find(
            (entry) =>
              entry.status === ApiService.accessRightsStatus.active &&
              entry.email === userEmail &&
              entry.type === ApiService.accessRightsTypes.invite
          )
        );
        setOwnerActive(
          address === packageData.sender || address === packageData.receiver
        );
      } catch (e) {
        console.error(e);
      }
      setShowVerificationDialog(false);
      setSignatureVerification({});
      setPackageDetails(packageData);
      setPackageParentId(packageData.parentId ? packageData.parentId : "");
      setPackageShareable(packageData.shareable ? [1] : []);
      setUpdatePackageTTL([]);
      setPackageStoreForever([]);
      setPackageTTL(-1);
      setPackageState(packageData.state);
      setShowPackageProgress(false);
      setPackageProgress(0);
      setPackageProgressRuntime(0);
      setPackageJobId("");
      setUpdatePackageButtonText(t("update-package"));
      setPackageComment("");
      setPackageMetadataName("");
      setPackageMetadataType(ApiService.metadataTypes.string);
      setPackageMetadata("");
      setPackageAccessRightsType(ApiService.accessRightsTypes.sign);
      setPackageAccessRightsEmail("");
      setPackageAccessRightsEmailUser("");
      setPackageAccessRightsTTL("-1");
      setPackageAccessRightsForever([]);
      setReportingFieldType(ApiService.reportingFieldTypes.text);
      setXCoordinate("");
      setYCoordinate("");
      setPageNumber("");
      setRectangleWidth("");
      setRectangleHeight("");
      setPageToNumber("");
      setSignatureEmail("");
      setFontSize("");
      setBold([]);
      setItalic([]);
      setDateFormat("");
      setText("");
      setRefreshDisabled(false);
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh(undefined, signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.location.search, props.history, refreshClicked, t]);

  const showErrorMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setMessage(messageContent);
      setConfirmDialog(true);
      setConfirmButtonText(t("close"));
    } else {
      setMessage(messageContent);
      setTimeout(() => {
        setMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessMessage = (messageContent) => {
    setSuccessMessage(messageContent);
    setConfirmDialog(true);
    setConfirmButtonText(t("confirm"));
  };

  const handlePackageUpdate = async (event) => {
    event.preventDefault();
    const { userId } = await getQueryParams();

    const parentId = packageParentId.trim();
    const updateParentId =
      (parentId === "" && packageDetails.parentId) ||
      (parentId !== "" && parentId !== packageDetails.parentId);
    const sharePackage = packageShareable.length > 0;
    const updateSharePackage = sharePackage !== packageDetails.shareable;
    const ttl =
      packageTTL === -1
        ? -1
        : Math.ceil((packageTTL.endOf("day") - moment()) / 1000.0);
    const validTTL = !(
      (ttl === -1 && packageStoreForever.length === 0) ||
      ttl < -1 ||
      ttl === 0
    );
    const updateTTL =
      updatePackageTTL.length > 0 && !(ttl === -1 && !packageDetails.endOfLife);
    const updatePackageState = packageState !== packageDetails.state;

    const checksPassed = !(updatePackageTTL.length > 0 && !validTTL);
    if (!checksPassed) {
      const messageContent = [];
      if (!validTTL) {
        messageContent.push(t("invalid-eol-defined"));
      }
      return showPackageError(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`
      );
    }

    if (
      !updateParentId &&
      !updateSharePackage &&
      !updateTTL &&
      !updatePackageState
    ) {
      return;
    }

    setRefreshDisabled(true);
    setUpdatePackageButtonText(t("updating-package"));
    const abortController = new AbortController();
    const signal = abortController.signal;

    let error = false;
    const messageContent = [];
    if (updateParentId) {
      try {
        await ApiService.updatePackageParentId(
          {
            userId,
            data_id: packageDetails.data_id,
            hash: packageDetails.hash,
            sender: packageDetails.sender,
            receiver: packageDetails.receiver,
            parentId,
          },
          signal
        );
        messageContent.push(t("parent-id-0"));
      } catch (e) {
        error = true;
        showPackageError(t("error-updating-package-parent-id-attribute"));
      }
    }
    if (!signal.aborted && !error && updateSharePackage) {
      try {
        await ApiService.updatePackageShareable(
          {
            userId,
            data_id: packageDetails.data_id,
            hash: packageDetails.hash,
            sender: packageDetails.sender,
            receiver: packageDetails.receiver,
            shareable: sharePackage.toString(),
          },
          signal
        );
        messageContent.push(t("shareable-0"));
      } catch (e) {
        error = true;
        showPackageError(t("error-updating-package-shareable-attribute"));
      }
    }
    if (!signal.aborted && !error && updatePackageState) {
      try {
        await ApiService.updatePackageState(
          {
            userId,
            data_id: packageDetails.data_id,
            hash: packageDetails.hash,
            sender: packageDetails.sender,
            receiver: packageDetails.receiver,
            state: packageState,
          },
          signal
        );
        messageContent.push(t("state-0"));
      } catch (e) {
        error = true;
        showPackageError(t("error-updating-package-state-attribute"));
      }
    }
    if (!signal.aborted && !error && updateTTL) {
      // Set progress bar
      setShowPackageProgress(true);
      setPackageProgress(20);
      setPackageProgressRuntime(0);

      // Share package
      let responseData;
      try {
        responseData = await ApiService.updatePackageTTL(
          {
            userId,
            data_id: packageDetails.data_id,
            hash: packageDetails.hash,
            receiver: packageDetails.receiver,
            ttl,
          },
          signal
        );
      } catch (e) {
        error = true;
        showPackageError(t("error-updating-package-eol"));
      }
      if (!error) {
        const jobId = responseData.jobId;
        setPackageJobId(jobId);
        setPackageProgress(responseData.progress);
        setPackageProgressRuntime(responseData.runtime);
        try {
          const jobParams = { jobId, userId };
          while (!signal.aborted && responseData.progress !== 100) {
            responseData = await ApiService.queryLatestJobStatus(
              jobParams,
              signal
            );
            setPackageProgress(responseData.progress);
            setPackageProgressRuntime(responseData.runtime);
            if (responseData.status === ApiService.jobStatus.failed) {
              error = true;
              showPackageError(t("error-updating-package-eol"));
              break;
            }
            if (
              responseData.status === ApiService.jobStatus.failedBlockchain ||
              responseData.status === ApiService.jobStatus.done
            ) {
              messageContent.push(t("eol"));
            }
          }
          if (signal.aborted) {
            error = true;
            showPackageError(t("operation-aborted"));
          }
        } catch (e) {
          error = true;
          showPackageError(t("error-updating-package-eol"));
        }
      }
    }

    handleRefresh();
    if (!error) {
      return showPackageSuccess(
        `{${t(
          "successfully-updated-package-attributes"
        )}}: ${messageContent.join(", ")}`
      );
    }
  };

  const handleShare = async (event) => {
    event.preventDefault();
    if (confirmDialog) {
      return clearSharingState();
    }

    const { userId } = await getQueryParams();
    const validRecipients = sharingRecipients.length > 0;
    const title = sharingTitle.trim();
    const validTitle = title.length > 0;
    const message_ = sharingMessage.trim();
    const validMessage = message_.length > 0;
    const ttl =
      shareTTL === -1
        ? -1
        : Math.ceil((shareTTL.endOf("day") - moment()) / 1000.0);
    const validTTL = !(
      (ttl === -1 && storeForever.length === 0) ||
      ttl < -1 ||
      ttl === 0
    );
    const checksPassed =
      validRecipients && validTitle && validMessage && validTTL;
    if (!checksPassed) {
      const messageContent = [];
      if (!validRecipients) {
        messageContent.push(t("define-one-or-more-recipients"));
      }
      if (!validTitle) {
        messageContent.push(t("name-length-should-be-greater-than-1"));
      }
      if (!validMessage) {
        messageContent.push(t("message-length-should-be-greater-than-1"));
      }
      if (!validTTL) {
        messageContent.push(t("invalid-eol-defined"));
      }
      return showErrorMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const params = {
      userId,
      data_id: selectedPackage.data_id,
      hash: selectedPackage.hash,
      sender: selectedPackage.sender,
      receiver: selectedPackage.receiver,
      shareable: (canShare.length > 0).toString(),
      receivers: JSON.stringify(sharingRecipients),
      ttl: ttl.toString(),
      title,
      message: message_,
      frontendUrl: ApiService.getShareFrontendUrl(),
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    setShareAbortController(abortController);

    // Set progress bar
    setShowProgress(true);
    setProgress(20);
    setProgressRuntime(0);
    setConfirmButtonText(t("processing"));

    // Share package
    let responseData;
    try {
      responseData = await ApiService.shareFile(params, signal);
    } catch (e) {
      return showErrorMessage(t("error-processing-package"));
    }
    const jobId = responseData.jobId;
    setJobId(jobId);
    setProgress(responseData.progress);
    setProgressRuntime(responseData.runtime);
    try {
      const jobParams = { jobId, userId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        setProgress(responseData.progress);
        setProgressRuntime(responseData.runtime);
        if (responseData.status === ApiService.jobStatus.failed) {
          return showErrorMessage(t("error-processing-package"));
        }
        if (
          responseData.status === ApiService.jobStatus.failedBlockchain ||
          responseData.status === ApiService.jobStatus.done
        ) {
          return showSuccessMessage(t("package-successfully-processed"));
        }
      }
      if (signal.aborted) {
        return showErrorMessage(t("operation-aborted"));
      }
    } catch (e) {
      return showErrorMessage(t("error-processing-package"));
    }
  };

  const deleteFileResult = (
    result,
    resultMessage,
    responseData = undefined,
    refresh = true
  ) => {
    const handleDeletionResult = async () => {
      setAlert(null);
      if (refresh) {
        props.history.push("/");
      }
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleDeletionResult}
          onCancel={handleDeletionResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {responseData && (
            <React.Fragment>
              <CustomLinearProgress
                variant="determinate"
                color="primary"
                value={responseData.progress}
              />
              <Muted>
                {responseData.jobId} - {responseData.progress}% (
                {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
              </Muted>
              <br />
            </React.Fragment>
          )}
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleDeletionResult}
          onCancel={handleDeletionResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {responseData && (
            <React.Fragment>
              <CustomLinearProgress
                variant="determinate"
                color="primary"
                value={responseData.progress}
              />
              <Muted>
                {responseData.jobId} - {responseData.progress}% (
                {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
              </Muted>
              <br />
            </React.Fragment>
          )}
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const downloadFileError = (resultMessage, responseData = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("error")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {responseData && (
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={responseData.progress}
            />
            <Muted>
              {responseData.jobId} - {responseData.progress}% (
              {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
            </Muted>
            <br />
          </React.Fragment>
        )}
        <Danger>{resultMessage}</Danger>
      </SweetAlert>
    );
  };

  const deleteFileAsync = (data) => {
    const handleFileDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        sender: data.sender,
        receiver: data.receiver,
        data_id: data.data_id,
        hash: data.hash,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.deleteFile(params, signal);
      } catch (e) {
        return deleteFileResult(false, t("error-deleting-package"));
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId, userId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            return deleteFileResult(
              false,
              t("error-deleting-package"),
              responseData
            );
          }
          if (
            responseData.status === ApiService.jobStatus.failedBlockchain ||
            responseData.status === ApiService.jobStatus.done
          ) {
            return deleteFileResult(
              true,
              t("package-successfully-deleted"),
              responseData
            );
          }
        }
        if (signal.aborted) {
          return deleteFileResult(false, t("operation-aborted"), responseData);
        }
      } catch (e) {
        return deleteFileResult(
          false,
          t("error-deleting-package"),
          responseData
        );
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-package")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleFileDeletion();
  };

  const downloadFileAsync = (data) => {
    const handleDownloadFile = async (userId, parameters, signal) => {
      const params = {
        userId,
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadFile(params, signal);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      setAlert(null);
      a.click();
    };
    const handlePrepareFileDownload = async () => {
      // Download parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        sender: data.sender,
        receiver: data.receiver,
        data_id: data.data_id,
        hash: data.hash,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.prepareDownloadFile(params, signal);
      } catch (e) {
        return downloadFileError(t("error-downloading-package"));
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId, userId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            return downloadFileError(
              t("error-downloading-package"),
              responseData
            );
          }
          if (
            responseData.status === ApiService.jobStatus.failedBlockchain ||
            responseData.status === ApiService.jobStatus.done
          ) {
            await handleDownloadFile(userId, responseData, signal);
          }
        }
        if (signal.aborted) {
          return downloadFileError(t("operation-aborted"), responseData);
        }
      } catch (e) {
        return downloadFileError(t("error-downloading-package"), responseData);
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("preparing-package")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePrepareFileDownload();
  };

  const downloadReportAsync = (data) => {
    const handleDownloadReport = async (userId, parameters, signal) => {
      const params = {
        userId,
        jobId: parameters.jobId,
      };
      const responseData = await ApiService.downloadReport(params, signal);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      setAlert(null);
      a.click();
    };
    const handlePrepareReportDownload = async () => {
      // Download parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: data._id,
        parentType: ApiService.parentTypes.package,
        frontendUrl: getFrontendUrl(packageParams),
        creatorName: "UNISOT SDC",
        documentTitle: `UNISOT SDC ${t("report")}`,
        reportTitle: t("report"),
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.prepareDownloadReport(params, signal);
      } catch (e) {
        return downloadError(t("error-downloading-report"));
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId, userId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            return downloadError(t("error-downloading-report"), responseData);
          }
          if (
            responseData.status === ApiService.jobStatus.failedBlockchain ||
            responseData.status === ApiService.jobStatus.done
          ) {
            await handleDownloadReport(userId, responseData, signal);
          }
        }
        if (signal.aborted) {
          return downloadError(t("operation-aborted"), responseData);
        }
      } catch (e) {
        return downloadError(t("error-downloading-report"), responseData);
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("preparing-report")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePrepareReportDownload();
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const showDeleteFile = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-package")}?`}
        onConfirm={() => {
          deleteFileAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.title}</b>
        </p>
      </SweetAlert>
    );
  };

  const showDownloadFile = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-package")}?`}
        onConfirm={() => {
          downloadFileAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.title}</b>
        </p>
      </SweetAlert>
    );
  };

  const showDownloadReport = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-report")}?`}
        onConfirm={() => {
          downloadReportAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.title}</b>
        </p>
      </SweetAlert>
    );
  };

  const packageResult = (result, resultMessage, redirect = true) => {
    const handleResult = async () => {
      setAlert(null);
      if (redirect) {
        props.history.push("/");
      }
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleResult}
          onCancel={handleResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={100}
            />
            <Muted>100%</Muted>
            <br />
          </React.Fragment>
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleResult}
          onCancel={handleResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={100}
            />
            <Muted>100%</Muted>
            <br />
          </React.Fragment>
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const deletePackageSignatureAsync = (data) => {
    const handlePackageSignatureDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: data.parentId,
        parentType: data.type,
        signatureId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteSignature(params, signal);
        packageResult(true, t("package-signature-successfully-deleted"), false);
      } catch (e) {
        packageResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-package-signature")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePackageSignatureDeletion();
  };

  const showDeletePackageSignature = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-signature")}?`}
        onConfirm={() => {
          deletePackageSignatureAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const showVerifySignatureDialog = async (elem) => {
    try {
      const { userId } = await getQueryParams();
      const data = await ApiService.verifySignature({
        userId,
        signatureId: elem._id,
        parentId: elem.parentId,
        parentType: elem.type,
      });
      setSignatureVerification({
        _id: elem._id,
        verificationSteps: [
          {
            stage: t("input-data"),
            details: [
              ["parentId", elem.parentId],
              ["hash", elem.signedHash],
              ["metadataHash", elem.metadataHash],
              ["metadata", JSON.parse(elem.metadata)],
            ],
          },
          ...data.verificationSteps,
        ],
      });
      setShowVerificationDialog(true);
    } catch {
      showPackageError(t("failed-to-fetch-package-signature"));
    }
  };

  const showDeletePackageComment = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-comment")}?`}
        onConfirm={() => {
          deletePackageCommentAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleUpdateSignature = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const updateAccess = modalUpdateAccess !== signatureDetails.access;
    if (!updateAccess) {
      return clearModalState();
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      signatureId: signatureDetails._id,
      parentId: signatureDetails.parentId,
      parentType: signatureDetails.type,
      access: modalUpdateAccess,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("updating"));

    try {
      await ApiService.updateSignature(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("signature-successfully-updated"));
  };

  const handlePackageSigning = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      parentId: packageDetails._id,
      parentType: ApiService.parentTypes.package,
      access: packageDetails.access,
      blockchain: modalBlockchain,
      frontendUrl: getFrontendUrl(packageParams),
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    setSignatureAbortController(abortController);

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("processing"));

    // Sign package
    let responseData;
    try {
      responseData = await ApiService.addSignature(params, signal);
    } catch (e) {
      return showErrorModalMessage(t("error-signing-package"));
    }
    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId, userId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        setModalProgress(responseData.progress);
        if (responseData.status === ApiService.jobStatus.failed) {
          return showErrorModalMessage(t("error-signing-package"));
        }
        if (
          responseData.status === ApiService.jobStatus.failedBlockchain ||
          responseData.status === ApiService.jobStatus.done
        ) {
          return showSuccessModalMessage(t("package-successfully-signed"));
        }
      }
      if (signal.aborted) {
        return showErrorModalMessage(t("operation-aborted"));
      }
    } catch (e) {
      return showErrorModalMessage(t("error-signing-package"));
    }
  };

  const handleDownloadMetadata = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-metadata")}?`}
        onConfirm={() => {
          downloadMetadata(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const downloadMetadata = async (elem) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const responseData = await ApiService.downloadMetadata({
        userId: queryParams.userId,
        metadataId: elem._id,
      });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      let extension;
      if (elem.type === ApiService.metadataTypes.json) {
        extension = "json";
      } else if (elem.type === ApiService.metadataTypes.xml) {
        extension = "xml";
      } else {
        extension = "txt";
      }
      a.download = `${elem._id}.${extension}`;
      a.click();
    } catch (e) {
      downloadError(e.message);
    }
  };

  const handleAddPackageMetadata = async (event) => {
    event.preventDefault();
    const packageMetadataNameParsed = packageMetadataName.trim();
    if (packageMetadataNameParsed.length === 0) {
      return showPackageError(t("package-metadata-name-field-empty"));
    }
    const packageMetadataParsed = packageMetadata.trim();
    if (packageMetadataParsed.length === 0) {
      return showPackageError(t("package-metadata-field-empty"));
    }
    let metadata;
    try {
      metadata =
        packageMetadataType === ApiService.metadataTypes.json
          ? JSON.parse(packageMetadataParsed)
          : packageMetadataParsed;
    } catch (e) {
      return showPackageError(t("package-invalid-metadata"));
    }
    setRefreshDisabled(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData = undefined;
    let error = false;
    try {
      const { userId } = await getQueryParams(signal);
      returnData = await ApiService.addMetadata(
        {
          userId,
          parentId: packageDetails._id,
          parentType: ApiService.parentTypes.package,
          name: packageMetadataNameParsed,
          type: packageMetadataType,
          metadata,
        },
        signal
      );
    } catch (e) {
      error = true;
      showPackageError(t("failed-to-add-package-metadata"));
    }
    handleRefresh(returnData, signal);
    if (!error) {
      return showPackageSuccess(t("package-metadata-added-successfully"));
    }
  };

  const deletePackageMetadataAsync = (data) => {
    const handlePackageMetadataDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: packageDetails._id,
        parentType: ApiService.parentTypes.package,
        metadataId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteMetadata(params, signal);
        packageResult(true, t("package-metadata-successfully-deleted"), false);
      } catch (e) {
        packageResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-package-metadata")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePackageMetadataDeletion();
  };

  const showDeletePackageMetadata = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-metadata")}?`}
        onConfirm={() => {
          deletePackageMetadataAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const sendInviteAccessRightsAsync = (data) => {
    const handleSendInviteAccessRights = async () => {
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: packageDetails._id,
        parentType: ApiService.parentTypes.package,
        accessRightsId: data._id,
        frontendUrl: getFrontendUrl(packageParams),
        registerUrl: ApiService.idFrontendURL,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.inviteAccessRights(params, signal);
        packageResult(true, t("invite-email-successfully-sent"), false);
      } catch (e) {
        packageResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("sending-email-invite")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleSendInviteAccessRights();
  };

  const showSendInviteAccessRights = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("send-an-email-invite")}?`}
        onConfirm={() => {
          sendInviteAccessRightsAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.email}</b>
        </p>
      </SweetAlert>
    );
  };

  const deletePackageAccessRightsAsync = (data) => {
    const handlePackageAccessRightsDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: packageDetails._id,
        parentType: ApiService.parentTypes.package,
        accessRightsId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteAccessRights(params, signal);
        packageResult(
          true,
          t("package-access-rights-successfully-deleted"),
          false
        );
      } catch (e) {
        packageResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-package-access-rights")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePackageAccessRightsDeletion();
  };

  const handleAddPackageAccessRights = async (event) => {
    event.preventDefault();
    const email = packageAccessRightsEmail.trim();
    if (!validator.isEmail(email)) {
      return showPackageError(t("package-access-rights-invalid-email"));
    }
    const ttl =
      packageAccessRightsTTL !== "-1"
        ? packageAccessRightsTTL.endOf("day").toISOString()
        : "-1";
    const validTTL = !(ttl === "-1" && packageAccessRightsForever.length === 0);
    if (!validTTL) {
      return showPackageError(t("package-access-rights-invalid-eol"));
    }
    setRefreshDisabled(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData = undefined;
    let error = false;
    try {
      const { userId } = await getQueryParams(signal);
      returnData = await ApiService.addAccessRights(
        {
          userId,
          parentId: packageDetails._id,
          parentType: ApiService.parentTypes.package,
          type: packageAccessRightsType,
          email,
          endOfLife: ttl,
        },
        signal
      );
    } catch (e) {
      error = true;
      showPackageError(t("failed-to-add-package-access-rights"));
    }
    handleRefresh(returnData, signal);
    if (!error) {
      return showPackageSuccess(t("package-access-rights-added-successfully"));
    }
  };

  const showDeletePackageAccessRights = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-access-rights")}?`}
        onConfirm={() => {
          deletePackageAccessRightsAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.email}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleAddPackageReportingField = async (event) => {
    event.preventDefault();
    const request = {
      type: reportingFieldType,
    };
    if (
      !(
        validator.isNumeric(xCoordinate.trim()) &&
        validator.isNumeric(yCoordinate.trim())
      )
    ) {
      return showPackageError(t("package-reporting-field-invalid-coordinate"));
    } else {
      request.xCoordinate = parseInt(xCoordinate.trim());
      request.yCoordinate = parseInt(yCoordinate.trim());
    }
    if (!validator.isNumeric(pageNumber.trim())) {
      return showPackageError(t("package-reporting-field-invalid-page"));
    }
    request.pageNumber = parseInt(pageNumber.trim());
    if (pageToNumber.trim().length > 0) {
      if (!validator.isNumeric(pageToNumber.trim())) {
        return showPackageError(t("package-reporting-field-invalid-page"));
      }
      request.pageToNumber = parseInt(pageToNumber.trim());
    }
    if (reportingFieldType === ApiService.reportingFieldTypes.signature) {
      if (rectangleWidth.trim().length > 0) {
        if (!validator.isNumeric(rectangleWidth.trim())) {
          return showPackageError(
            t("package-reporting-field-invalid-coordinate")
          );
        }
        request.rectangleWidth = parseInt(rectangleWidth.trim());
      }
      if (rectangleHeight.trim().length > 0) {
        if (!validator.isNumeric(rectangleHeight.trim())) {
          return showPackageError(
            t("package-reporting-field-invalid-coordinate")
          );
        }
        request.rectangleHeight = parseInt(rectangleHeight.trim());
      }
      if (!validator.isEmail(signatureEmail.trim())) {
        return showPackageError(t("package-reporting-field-invalid-format"));
      }
      request.signatureEmail = signatureEmail.trim();
    } else {
      request.bold = bold.length > 0;
      request.italic = italic.length > 0;
      if (fontSize.trim().length > 0) {
        if (!validator.isNumeric(fontSize.trim())) {
          return showPackageError(t("package-reporting-field-invalid-font"));
        }
        request.fontSize = parseInt(fontSize.trim());
      }
    }
    if (reportingFieldType === ApiService.reportingFieldTypes.text) {
      if (text.trim().length > 0) {
        request.text = text.trim();
      } else {
        return showPackageError(t("package-reporting-field-invalid-format"));
      }
    } else if (reportingFieldType === ApiService.reportingFieldTypes.date) {
      if (dateFormat.trim().length > 0) {
        request.dateFormat = dateFormat.trim();
      }
      if (signatureEmail.trim().length > 0) {
        if (!validator.isEmail(signatureEmail.trim())) {
          return showPackageError(t("package-reporting-field-invalid-format"));
        }
        request.signatureEmail = signatureEmail.trim();
      }
    }
    setRefreshDisabled(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData = undefined;
    let error = false;
    try {
      const { userId } = await getQueryParams(signal);
      request.userId = userId;
      request.parentId = packageDetails._id;
      request.parentType = ApiService.parentTypes.package;
      returnData = await ApiService.addReportingField(request, signal);
    } catch (e) {
      error = true;
      showPackageError(t("failed-to-add-package-reporting-field"));
    }
    handleRefresh(returnData, signal);
    if (!error) {
      return showPackageSuccess(
        t("package-reporting-field-added-successfully")
      );
    }
  };

  const deletePackageReportingFieldAsync = (data) => {
    const handlePackageReportingFieldDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: packageDetails._id,
        parentType: ApiService.parentTypes.package,
        reportingFieldId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteReportingField(params, signal);
        packageResult(
          true,
          t("package-reporting-field-successfully-deleted"),
          false
        );
      } catch (e) {
        packageResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-package-reporting-field")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePackageReportingFieldDeletion();
  };

  const showDeletePackageReportingField = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-reporting-field")}?`}
        onConfirm={() => {
          deletePackageReportingFieldAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.type}</b>
        </p>
      </SweetAlert>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={7}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <ListAltIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {`${t("package")}: ${
                  packageDetails.title
                } ${formattedUserName}`}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  color="rose"
                  round
                  simple
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                  disabled={refreshDisabled}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button>
                  <ListItemIcon>
                    <PeopleIcon style={{ color: roseColor[0] }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t("details")} />
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("id")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {packageDetails._id}
                    </GridItem>
                  </GridContainer>
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("sender")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {formatUserEntry(packageDetails.sender)}
                    </GridItem>
                  </GridContainer>
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("receiver")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {formatUserEntry(packageDetails.receiver)}
                    </GridItem>
                  </GridContainer>
                </ListItem>
                <Divider />

                <ListItem button>
                  <ListItemIcon>
                    <InfoIcon style={{ color: roseColor[0] }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t("file-info")} />
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("file-name")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {packageDetails.fileName}
                    </GridItem>
                  </GridContainer>
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("file-type")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {packageDetails.dataType}
                    </GridItem>
                  </GridContainer>
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("file-size")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {packageDetails.fileSize}
                    </GridItem>
                  </GridContainer>
                </ListItem>
              </List>
              <Divider />
              <ListItem button>
                <ListItemIcon>
                  <CheckCircleOutlineIcon style={{ color: roseColor[0] }} />
                </ListItemIcon>
                <ListItemText disableTypography primary={t("process")} />
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("parent-id")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {packageDetails.parentId ? packageDetails.parentId : "-"}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("shareable")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {packageDetails.shareable ? t("true") : t("false")}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("status")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {packageDetails.status}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("state")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {packageDetails.state}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("storage-service")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {packageDetails.storageService}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("blockchain-store")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {packageDetails.blockchainStore
                      ? packageDetails.blockchainStore.toString()
                      : "false"}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("access")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {packageDetails.access}
                  </GridItem>
                </GridContainer>
              </ListItem>
              {packageDetails.access === ApiService.packageAccess.public && (
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("publish-link")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      <a
                        href={`${
                          ApiService.serviceFrontendURL
                        }/auth/public-shared-package/?${new URLSearchParams({
                          packageId: packageDetails._id,
                          publicShareSecret: packageDetails.publicShareSecret,
                        }).toString()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${
                          ApiService.serviceFrontendURL
                        }/auth/public-shared-package/?${new URLSearchParams({
                          packageId: packageDetails._id,
                          publicShareSecret: packageDetails.publicShareSecret,
                        }).toString()}`}
                      </a>
                    </GridItem>
                  </GridContainer>
                </ListItem>
              )}
              <Divider />
              <ListItem button>
                <ListItemIcon>
                  <DateRangeIcon style={{ color: roseColor[0] }} />
                </ListItemIcon>
                <ListItemText disableTypography primary={t("date-and-time")} />
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("eol")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {ApiService.formatDateTime(packageDetails.endOfLife)}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("created-at")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {ApiService.formatDateTime(packageDetails.createdAt)}
                  </GridItem>
                </GridContainer>
              </ListItem>

              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("updated-at")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {ApiService.formatDateTime(packageDetails.updatedAt)}
                  </GridItem>
                </GridContainer>
              </ListItem>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={8}>
                  <img
                    height="155px"
                    src={packageQrCode}
                    alt={t("package-qrcode")}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {ownerActive && (
            <Card>
              <CardHeader color="turq" icon>
                <CardIcon color="turq">
                  <EditIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t("edit-package")}</h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={handlePackageUpdate}>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        style={{ float: "left" }}
                      >
                        {t("parent-id")}
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        id="package-title"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          value: packageParentId,
                          disabled: userAddress !== packageDetails.sender,
                          onChange: (e) => {
                            setPackageParentId(e.target.value);
                          },
                        }}
                        helpText={t("change-parent-id")}
                      />
                    </GridItem>
                  </GridContainer>
                  {userAddress === packageDetails.sender &&
                    packageDetails.access ===
                      ApiService.packageAccess.private && (
                      <GridContainer
                        className={`${profileClasses.description}`}
                      >
                        <GridItem xs={12} sm={3}>
                          <FormLabel
                            className={
                              formClasses.labelHorizontal +
                              " " +
                              formClasses.labelHorizontalRadioCheckbox
                            }
                            style={{ float: "left" }}
                          >
                            {t("shareable")}
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={9} style={{ float: "left" }}>
                          <div className={formClasses.checkboxAndRadio}>
                            <FormControlLabel
                              style={{ float: "left" }}
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => {
                                    const currentIndex =
                                      packageShareable.indexOf(1);
                                    const newChecked = [...packageShareable];
                                    if (currentIndex === -1) {
                                      newChecked.push(1);
                                    } else {
                                      newChecked.splice(currentIndex, 1);
                                    }
                                    setPackageShareable(newChecked);
                                  }}
                                  checkedIcon={
                                    <Check
                                      className={formClasses.checkedIcon}
                                    />
                                  }
                                  icon={
                                    <Check
                                      className={formClasses.uncheckedIcon}
                                    />
                                  }
                                  classes={{
                                    checked: formClasses.checked,
                                    root: formClasses.checkRoot,
                                  }}
                                  checked={packageShareable.length > 0}
                                />
                              }
                              classes={{
                                label: formClasses.label,
                                root: formClasses.labelRoot,
                              }}
                              label={t("can-share")}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    )}
                  {userAddress === packageDetails.sender &&
                    !packageDetails.blockchainStore && (
                      <React.Fragment>
                        <GridContainer>
                          <GridItem xs={12} sm={3}>
                            <FormLabel
                              className={
                                formClasses.labelHorizontal +
                                " " +
                                formClasses.labelHorizontalRadioCheckbox
                              }
                              style={{ float: "left" }}
                            >
                              {t("change-eol")}
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={2}>
                            <div className={formClasses.checkboxAndRadio}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={() => {
                                      const currentIndex =
                                        updatePackageTTL.indexOf(1);
                                      const newChecked = [...updatePackageTTL];
                                      if (currentIndex === -1) {
                                        newChecked.push(1);
                                      } else {
                                        newChecked.splice(currentIndex, 1);
                                      }
                                      setUpdatePackageTTL(newChecked);
                                    }}
                                    checkedIcon={
                                      <Check
                                        className={formClasses.checkedIcon}
                                      />
                                    }
                                    icon={
                                      <Check
                                        className={formClasses.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: formClasses.checked,
                                      root: formClasses.checkRoot,
                                    }}
                                    checked={updatePackageTTL.length > 0}
                                  />
                                }
                                classes={{
                                  label: formClasses.label,
                                  root: formClasses.labelRoot,
                                }}
                                label={t("eol")}
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          {updatePackageTTL.length > 0 && (
                            <React.Fragment>
                              <GridItem
                                xs={12}
                                sm={3}
                                style={{ float: "left" }}
                              ></GridItem>
                              <GridItem
                                xs={12}
                                sm={4}
                                style={{ float: "left" }}
                              >
                                <div className={formClasses.checkboxAndRadio}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => {
                                          if (updatePackageTTL.length === 0) {
                                            return;
                                          }
                                          const currentIndex =
                                            packageStoreForever.indexOf(1);
                                          const newChecked = [
                                            ...packageStoreForever,
                                          ];
                                          if (currentIndex === -1) {
                                            newChecked.push(1);
                                          } else {
                                            newChecked.splice(currentIndex, 1);
                                          }
                                          setPackageStoreForever(newChecked);
                                          if (newChecked.length > 0) {
                                            setPackageTTL(-1);
                                          }
                                        }}
                                        checkedIcon={
                                          <Check
                                            className={formClasses.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={
                                              formClasses.uncheckedIcon
                                            }
                                          />
                                        }
                                        classes={{
                                          checked: formClasses.checked,
                                          root: formClasses.checkRoot,
                                        }}
                                        checked={
                                          updatePackageTTL.length > 0 &&
                                          packageStoreForever.length > 0
                                        }
                                      />
                                    }
                                    classes={{
                                      label: formClasses.label,
                                      root: formClasses.labelRoot,
                                    }}
                                    label={t("store-forever")}
                                  />
                                </div>
                              </GridItem>
                              <GridItem
                                xs={12}
                                sm={5}
                                style={{ marginTop: "10px" }}
                              >
                                <FormControl fullWidth>
                                  <Datetime
                                    dateFormat={"YYYY-MM-DD"}
                                    timeFormat={false}
                                    inputProps={{
                                      placeholder: t("select-date"),
                                      disabled:
                                        updatePackageTTL.length === 0 ||
                                        packageStoreForever.length > 0,
                                    }}
                                    value={
                                      packageTTL === -1 ? undefined : packageTTL
                                    }
                                    onChange={(e) => {
                                      setPackageTTL(e);
                                    }}
                                    closeOnSelect
                                  />
                                </FormControl>
                              </GridItem>
                            </React.Fragment>
                          )}
                        </GridContainer>
                      </React.Fragment>
                    )}
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel
                        className={
                          formClasses.labelHorizontal +
                          " " +
                          formClasses.labelHorizontalRadioCheckbox
                        }
                        style={{ float: "left", paddingTop: "40px" }}
                      >
                        {t("package-state")}
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9} className={formClasses.alignLeft}>
                      <FormControl
                        fullWidth
                        className={formClasses.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="package-state"
                          className={formClasses.selectLabel}
                        ></InputLabel>
                        <Select
                          MenuProps={{
                            className: formClasses.selectMenu,
                          }}
                          classes={{
                            select: formClasses.select,
                          }}
                          value={packageState}
                          onChange={(e) => setPackageState(e.target.value)}
                          inputProps={{
                            name: "simpleSelect",
                            id: "package-state",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: formClasses.selectMenuItem,
                            }}
                          >
                            {t("select-state")}
                          </MenuItem>
                          {getValidPackageStates()}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  {showPackageProgress && (
                    <React.Fragment>
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={packageProgress}
                      />
                      <Muted>
                        {packageJobId} - {packageProgress}% (
                        {moment
                          .utc(packageProgressRuntime)
                          .format("HH:mm:ss.SSS")}
                        )
                      </Muted>
                      <br />
                    </React.Fragment>
                  )}
                  <Button
                    color="primary"
                    style={{ float: "right" }}
                    type="submit"
                    disabled={refreshDisabled}
                  >
                    {updatePackageButtonText}
                  </Button>
                </form>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={8} md={8}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <LockIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("blockchain-info")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                className={`${profileClasses.description} ${profileClasses.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                  {t("txid")}
                </GridItem>
                <GridItem xs={12} sm={8} className={profileClasses.alignRight}>
                  {packageDetails.transactionStatus ===
                  ApiService.jobTransactionStatus.written ? (
                    <a
                      href={
                        ApiService.populatePath(ApiService.blockchainInfoURL, {
                          transactionId: packageDetails.transactionId,
                        }).populatedPath
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {packageDetails.transactionId}
                    </a>
                  ) : (
                    <React.Fragment>
                      {packageDetails.transactionId}
                    </React.Fragment>
                  )}
                </GridItem>
              </GridContainer>
              <Divider />

              <GridContainer
                className={`${profileClasses.description} ${profileClasses.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                  {t("status")}
                </GridItem>
                <GridItem xs={12} sm={8} className={profileClasses.alignRight}>
                  {packageDetails.transactionStatus}
                </GridItem>
              </GridContainer>
              <Divider />

              <GridContainer
                className={`${profileClasses.description} ${profileClasses.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                  {t("hash")}
                </GridItem>
                <GridItem xs={12} sm={8} className={profileClasses.alignRight}>
                  {packageDetails.hash}
                </GridItem>
              </GridContainer>
              <Divider />

              <GridContainer
                className={`${profileClasses.description} ${profileClasses.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                  {t("data-id")}
                </GridItem>
                <GridItem
                  xs={12}
                  sm={8}
                  className={`${profileClasses.alignRight}`}
                >
                  {packageDetails.data_id}
                </GridItem>
              </GridContainer>

              {packageDetails.blockchainStore && (
                <React.Fragment>
                  <Divider />
                  <GridContainer
                    className={`${profileClasses.description} ${profileClasses.marginIn}`}
                  >
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("aes-256-cbc-symmetric-key")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={`${profileClasses.alignRight}`}
                    >
                      {packageDecryptionKey.symmetricKey}
                    </GridItem>
                  </GridContainer>
                  <Divider />
                  <GridContainer
                    className={`${profileClasses.description} ${profileClasses.marginIn}`}
                  >
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("aes-256-cbc-initialization-vector")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={`${profileClasses.alignRight}`}
                    >
                      {packageDecryptionKey.initializationVector}
                    </GridItem>
                  </GridContainer>
                  <Divider />
                  <GridContainer
                    className={`${profileClasses.description} ${profileClasses.marginIn}`}
                  >
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("number-of-transaction-chunks")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={`${profileClasses.alignRight}`}
                    >
                      {packageTransactionChunks.length.toString()}
                    </GridItem>
                  </GridContainer>
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <SettingsIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("package-actions")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                className={`${profileClasses.description}`}
                style={{ textAlign: "center" }}
                justifyContent="center"
              >
                <GridItem xs={2}>
                  <Tooltip title={t("sign-package-hash-0")}>
                    <Button
                      justIcon
                      round
                      simple
                      color="rose"
                      className="edit"
                      onClick={() => {
                        setConfirmModalButtonText(t("sign"));
                        setShowSignatureModal(true);
                      }}
                    >
                      <BorderColorIcon />
                    </Button>
                  </Tooltip>
                </GridItem>
                <GridItem xs={2}>
                  <Tooltip title={t("package-hash-verification")}>
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        setShowHashVerificationDialog(true);
                      }}
                      color="rose"
                      className="remove"
                    >
                      <ReceiptIcon />
                    </Button>
                  </Tooltip>
                </GridItem>
                {ownerActive && (
                  <React.Fragment>
                    <GridItem xs={2}>
                      <Tooltip title={t("publish-package")}>
                        <Button
                          justIcon
                          round
                          simple
                          color="rose"
                          className="edit"
                          disabled={
                            !packageDetails.shareable ||
                            refreshDisabled ||
                            packageDetails.state !==
                              ApiService.packageState.accepted
                          }
                          onClick={async () => {
                            setSelectedPackage(packageDetails);
                            setSharingTitle(packageDetails.title);
                            setCanShare([]);
                            await getPublicShareUser();
                            setShowPublishDialog(true);
                            setConfirmButtonText(t("publish"));
                            setShowShareDialog(true);
                          }}
                        >
                          <CloudIcon />
                        </Button>
                      </Tooltip>
                    </GridItem>
                    <GridItem xs={2}>
                      <Tooltip title={t("share-package")}>
                        <Button
                          justIcon
                          round
                          simple
                          color="rose"
                          className="edit"
                          disabled={
                            !packageDetails.shareable ||
                            refreshDisabled ||
                            packageDetails.state !==
                              ApiService.packageState.accepted
                          }
                          onClick={async () => {
                            setSelectedPackage(packageDetails);
                            setSharingTitle(packageDetails.title);
                            setCanShare(packageDetails.shareable ? [1] : []);
                            await getAllUsers();
                            setShowPublishDialog(false);
                            setConfirmButtonText(t("share"));
                            setShowShareDialog(true);
                          }}
                        >
                          <ShareIcon />
                        </Button>
                      </Tooltip>
                    </GridItem>
                  </React.Fragment>
                )}
                <GridItem xs={2}>
                  <Tooltip title={t("download-report")}>
                    <Button
                      justIcon
                      round
                      simple
                      disabled={
                        refreshDisabled ||
                        packageDetails.state ===
                          ApiService.packageState.rejected
                      }
                      onClick={() => {
                        showDownloadReport(packageDetails);
                      }}
                      color="rose"
                      className="remove"
                    >
                      <AssessmentIcon />
                    </Button>
                  </Tooltip>
                </GridItem>
                <GridItem xs={2}>
                  <Tooltip title={t("download-package")}>
                    <Button
                      justIcon
                      round
                      simple
                      disabled={
                        refreshDisabled ||
                        packageDetails.state ===
                          ApiService.packageState.rejected
                      }
                      onClick={() => {
                        showDownloadFile(packageDetails);
                      }}
                      color="rose"
                      className="remove"
                    >
                      <CloudDownloadIcon />
                    </Button>
                  </Tooltip>
                </GridItem>
                {ownerActive && (
                  <GridItem xs={2}>
                    <Tooltip title={t("delete-package")}>
                      <Button
                        justIcon
                        round
                        simple
                        color="rose"
                        className="remove"
                        disabled={refreshDisabled}
                        onClick={() => {
                          showDeleteFile(packageDetails);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <NavPills
            color="info"
            alignCenter
            tabs={[
              {
                tabButton: t("details"),
                tabIcon: MailOutlineIcon,
                tabContent: (
                  <Card>
                    <CardBody>
                      <NavPills
                        color="rose"
                        horizontal={{
                          tabsGrid: { xs: 12, sm: 12, md: 2 },
                          contentGrid: { xs: 12, sm: 12, md: 10 },
                        }}
                        tabs={[
                          {
                            tabIcon: MailOutlineIcon,
                            tabContent: (
                              <span>
                                <h4>{t("title")}</h4>
                                <p>{packageDetails.title}</p>
                                <Divider />
                                <h4>{t("message")}</h4>
                                <p>{packageDetails.message}</p>
                              </span>
                            ),
                          },
                        ]}
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("signatures"),
                tabIcon: ReceiptIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <ReceiptIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("signatures")}
                      </h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          round
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => {
                            handleRefresh();
                          }}
                          disabled={refreshDisabled}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={
                          "signatures" in packageDetails
                            ? packageDetails.signatures.map((elem) => {
                                return {
                                  _id: elem._id,
                                  access: elem.access,
                                  parentId: elem.parentId,
                                  sender: formatUserEntry(elem.sender),
                                  type: elem.type,
                                  signedHash: elem.signedHash,
                                  metadataHash: elem.metadataHash,
                                  transactionId: elem.transactionId,
                                  transactionStatus: elem.transactionStatus,
                                  actions: (
                                    <div className="actions-right">
                                      <Tooltip
                                        title={t("signature-verification")}
                                      >
                                        <Button
                                          justIcon
                                          round
                                          simple
                                          onClick={() => {
                                            showVerifySignatureDialog(elem);
                                          }}
                                          color="rose"
                                          className="remove"
                                        >
                                          <ReceiptIcon />
                                        </Button>
                                      </Tooltip>
                                      {elem.transactionStatus ===
                                      ApiService.jobTransactionStatus
                                        .written ? (
                                        <a
                                          href={
                                            ApiService.populatePath(
                                              ApiService.blockchainInfoURL,
                                              {
                                                transactionId:
                                                  elem.transactionId,
                                              }
                                            ).populatedPath
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Tooltip
                                            title={t("blockchain-info-url")}
                                          >
                                            <Button
                                              justIcon
                                              round
                                              simple
                                              color="rose"
                                              className="remove"
                                            >
                                              <InfoIcon />
                                            </Button>
                                          </Tooltip>
                                        </a>
                                      ) : (
                                        <Tooltip
                                          title={t("blockchain-info-url")}
                                        >
                                          <Button
                                            justIcon
                                            round
                                            simple
                                            color="rose"
                                            className="remove"
                                            disabled
                                          >
                                            <InfoIcon />
                                          </Button>
                                        </Tooltip>
                                      )}
                                      {ownerActive && (
                                        <React.Fragment>
                                          <Tooltip
                                            title={t("update-signature")}
                                          >
                                            <Button
                                              justIcon
                                              round
                                              simple
                                              color="rose"
                                              className="edit"
                                              onClick={() => {
                                                setSignatureDetails(elem);
                                                setModalUpdateAccess(
                                                  elem.access
                                                );
                                                setShowModal(true);
                                              }}
                                            >
                                              <EditIcon />
                                            </Button>
                                          </Tooltip>
                                          <Tooltip
                                            title={t("delete-signature")}
                                          >
                                            <Button
                                              justIcon
                                              round
                                              simple
                                              className="remove"
                                              onClick={() => {
                                                showDeletePackageSignature(
                                                  elem
                                                );
                                              }}
                                            >
                                              <ClearIcon
                                                style={{ color: grayColor[17] }}
                                              />
                                            </Button>
                                          </Tooltip>
                                        </React.Fragment>
                                      )}
                                    </div>
                                  ),
                                };
                              })
                            : []
                        }
                        filterable
                        columns={[
                          {
                            Header: t("id"),
                            accessor: "_id",
                          },
                          {
                            Header: t("access"),
                            accessor: "access",
                          },
                          {
                            Header: t("user"),
                            accessor: "sender",
                          },
                          {
                            Header: t("type"),
                            accessor: "type",
                          },
                          {
                            Header: t("signed-hash"),
                            accessor: "signedHash",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("metadata-hash"),
                            accessor: "metadataHash",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("txid"),
                            accessor: "transactionId",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("status"),
                            accessor: "transactionStatus",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={6}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("metadata"),
                tabIcon: LibraryBooksIcon,
                tabContent: (
                  <Card>
                    <CardBody>
                      <NavPills
                        color="rose"
                        horizontal={{
                          tabsGrid: { xs: 12, sm: 12, md: 2 },
                          contentGrid: { xs: 12, sm: 12, md: 10 },
                        }}
                        tabs={[
                          {
                            tabIcon: LibraryBooksIcon,
                            tabContent: (
                              <React.Fragment>
                                <ReactTable
                                  data={
                                    "metadata" in packageDetails
                                      ? packageDetails.metadata.map((elem) => {
                                          return {
                                            id: elem._id,
                                            user: formatUserEntry(elem.user),
                                            date: elem.date,
                                            type: elem.type,
                                            name: elem.name,
                                            metadata: elem.metadata,
                                            actions: (
                                              <div className="actions-right">
                                                <Tooltip
                                                  title={t("download-metadata")}
                                                >
                                                  <Button
                                                    justIcon
                                                    round
                                                    simple
                                                    color="rose"
                                                    className="edit"
                                                    onClick={() => {
                                                      handleDownloadMetadata(
                                                        elem
                                                      );
                                                    }}
                                                  >
                                                    <CloudDownloadIcon />
                                                  </Button>
                                                </Tooltip>
                                                {ownerActive && (
                                                  <Tooltip
                                                    title={t("delete-metadata")}
                                                  >
                                                    <Button
                                                      justIcon
                                                      round
                                                      simple
                                                      className="remove"
                                                      onClick={() => {
                                                        showDeletePackageMetadata(
                                                          elem
                                                        );
                                                      }}
                                                    >
                                                      <ClearIcon
                                                        style={{
                                                          color: grayColor[17],
                                                        }}
                                                      />
                                                    </Button>
                                                  </Tooltip>
                                                )}
                                              </div>
                                            ),
                                          };
                                        })
                                      : []
                                  }
                                  columns={[
                                    {
                                      Header: t("user"),
                                      accessor: "user",
                                    },
                                    {
                                      Header: t("date"),
                                      accessor: "date",
                                      headerStyle: { textAlign: "right" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "right" }}>
                                          {ApiService.formatDateTime(row.value)}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("type"),
                                      accessor: "type",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("name"),
                                      accessor: "name",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("metadata"),
                                      accessor: "metadata",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("actions"),
                                      accessor: "actions",
                                      sortable: false,
                                      filterable: false,
                                    },
                                  ]}
                                  filterable
                                  defaultPageSize={8}
                                  showPaginationTop={false}
                                  showPaginationBottom
                                  className="-striped -highlight"
                                />
                              </React.Fragment>
                            ),
                          },
                          ownerActive
                            ? {
                                tabIcon: LibraryAddIcon,
                                tabContent: (
                                  <form onSubmit={handleAddPackageMetadata}>
                                    <h4 style={{ marginBottom: "15px" }}>
                                      {t("enter-your-metadata")}
                                    </h4>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {t("name")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <CustomInput
                                          id="doc-container-1-title"
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            type: "text",
                                            value: packageMetadataName,
                                            onChange: (e) => {
                                              setPackageMetadataName(
                                                e.target.value
                                              );
                                            },
                                          }}
                                          helpText={t("metadata-name")}
                                        />
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("type")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <FormControl
                                          fullWidth
                                          className={
                                            formClasses.selectFormControl
                                          }
                                        >
                                          <InputLabel
                                            htmlFor="data-type"
                                            className={formClasses.selectLabel}
                                          ></InputLabel>
                                          <Select
                                            value={packageMetadataType}
                                            onChange={(e) => {
                                              setPackageMetadataType(
                                                e.target.value
                                              );
                                            }}
                                            MenuProps={{
                                              className: formClasses.selectMenu,
                                            }}
                                            classes={{
                                              select: formClasses.select,
                                            }}
                                            inputProps={{
                                              name: "metadataType",
                                              id: "metadata-type",
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                              }}
                                            >
                                              {t("data-type")}
                                            </MenuItem>
                                            {Object.values(
                                              ApiService.metadataTypes
                                            ).map((metadataType) => {
                                              return (
                                                <MenuItem
                                                  key={metadataType}
                                                  classes={{
                                                    root: formClasses.selectMenuItem,
                                                    selected:
                                                      formClasses.selectMenuItemSelectedMultiple,
                                                  }}
                                                  value={metadataType}
                                                >
                                                  {metadataType}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("metadata")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <TextField
                                          required
                                          id="outlined-required"
                                          variant="outlined"
                                          fullWidth
                                          value={packageMetadata}
                                          multiline
                                          rows={3}
                                          onChange={(e) =>
                                            setPackageMetadata(e.target.value)
                                          }
                                        />
                                      </GridItem>
                                    </GridContainer>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      disabled={refreshDisabled}
                                      style={{
                                        float: "right",
                                        marginTop: "15px",
                                      }}
                                    >
                                      {t("add-metadata")}
                                    </Button>
                                  </form>
                                ),
                              }
                            : undefined,
                        ].filter((x) => x !== undefined)}
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("comments"),
                tabIcon: QuestionAnswerIcon,
                tabContent: (
                  <Card>
                    <CardBody>
                      <NavPills
                        color="rose"
                        horizontal={{
                          tabsGrid: { xs: 12, sm: 12, md: 2 },
                          contentGrid: { xs: 12, sm: 12, md: 10 },
                        }}
                        tabs={[
                          {
                            tabIcon: QuestionAnswerIcon,
                            tabContent: (
                              <React.Fragment>
                                <ReactTable
                                  data={
                                    "comments" in packageDetails
                                      ? packageDetails.comments.map(
                                          (elem, key) => {
                                            return {
                                              id: key,
                                              user: formatUserEntry(elem.user),
                                              date: elem.date,
                                              comment: elem.comment,
                                              actions: (
                                                <div className="actions-right">
                                                  <Tooltip
                                                    title={t("delete-comment")}
                                                  >
                                                    <Button
                                                      justIcon
                                                      round
                                                      simple
                                                      className="remove"
                                                      onClick={() => {
                                                        showDeletePackageComment(
                                                          elem
                                                        );
                                                      }}
                                                    >
                                                      <ClearIcon
                                                        style={{
                                                          color: grayColor[17],
                                                        }}
                                                      />
                                                    </Button>
                                                  </Tooltip>
                                                </div>
                                              ),
                                            };
                                          }
                                        )
                                      : []
                                  }
                                  columns={[
                                    {
                                      Header: t("user"),
                                      accessor: "user",
                                    },
                                    {
                                      Header: t("date"),
                                      accessor: "date",
                                      headerStyle: { textAlign: "right" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "right" }}>
                                          {ApiService.formatDateTime(row.value)}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("comment"),
                                      accessor: "comment",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("actions"),
                                      accessor: "actions",
                                      sortable: false,
                                      filterable: false,
                                    },
                                  ]}
                                  filterable
                                  defaultPageSize={8}
                                  showPaginationTop={false}
                                  showPaginationBottom
                                  className="-striped -highlight"
                                />
                              </React.Fragment>
                            ),
                          },
                          {
                            tabIcon: AddCommentIcon,
                            tabContent: (
                              <form onSubmit={handleAddPackageComment}>
                                <h4 style={{ marginBottom: "15px" }}>
                                  {t("enter-your-comment")}
                                </h4>
                                <TextField
                                  required
                                  id="outlined-required"
                                  variant="outlined"
                                  fullWidth
                                  value={packageComment}
                                  multiline
                                  rows={15}
                                  onChange={(e) =>
                                    setPackageComment(e.target.value)
                                  }
                                />
                                <Button
                                  color="primary"
                                  type="submit"
                                  disabled={refreshDisabled}
                                  style={{
                                    float: "right",
                                    marginTop: "15px",
                                  }}
                                >
                                  {t("add-comment")}
                                </Button>
                              </form>
                            ),
                          },
                        ]}
                      />
                    </CardBody>
                  </Card>
                ),
              },
              ownerActive || inviteActive
                ? {
                    tabButton: t("access-rights"),
                    tabIcon: LockIcon,
                    tabContent: (
                      <Card>
                        <CardBody>
                          <NavPills
                            color="rose"
                            horizontal={{
                              tabsGrid: { xs: 12, sm: 12, md: 2 },
                              contentGrid: { xs: 12, sm: 12, md: 10 },
                            }}
                            tabs={[
                              {
                                tabIcon: LockIcon,
                                tabContent: (
                                  <React.Fragment>
                                    <ReactTable
                                      data={
                                        "accessRights" in packageDetails
                                          ? packageDetails.accessRights.map(
                                              (elem) => {
                                                return {
                                                  id: elem._id,
                                                  type: elem.type,
                                                  email: elem.email,
                                                  invited:
                                                    elem.invited.toString(),
                                                  endOfLife: elem.endOfLife,
                                                  actions: (
                                                    <div className="actions-right">
                                                      <Tooltip
                                                        title={t(
                                                          "send-an-email-invite"
                                                        )}
                                                      >
                                                        <Button
                                                          justIcon
                                                          round
                                                          simple
                                                          color="rose"
                                                          className="edit"
                                                          onClick={() => {
                                                            showSendInviteAccessRights(
                                                              elem
                                                            );
                                                          }}
                                                        >
                                                          <EmailIcon />
                                                        </Button>
                                                      </Tooltip>
                                                      <Tooltip
                                                        title={t(
                                                          "delete-access-rights"
                                                        )}
                                                      >
                                                        <Button
                                                          justIcon
                                                          round
                                                          simple
                                                          className="remove"
                                                          onClick={() => {
                                                            showDeletePackageAccessRights(
                                                              elem
                                                            );
                                                          }}
                                                        >
                                                          <ClearIcon
                                                            style={{
                                                              color:
                                                                grayColor[17],
                                                            }}
                                                          />
                                                        </Button>
                                                      </Tooltip>
                                                    </div>
                                                  ),
                                                };
                                              }
                                            )
                                          : []
                                      }
                                      columns={[
                                        {
                                          Header: t("email"),
                                          accessor: "email",
                                        },
                                        {
                                          Header: t("type"),
                                          accessor: "type",
                                        },
                                        {
                                          Header: t("invited"),
                                          accessor: "invited",
                                        },
                                        {
                                          Header: t("eol"),
                                          accessor: "endOfLife",
                                          headerStyle: { textAlign: "right" },
                                          Cell: (row) => (
                                            <div style={{ textAlign: "right" }}>
                                              {ApiService.formatDateTime(
                                                row.value
                                              )}
                                            </div>
                                          ),
                                        },
                                        {
                                          Header: t("actions"),
                                          accessor: "actions",
                                          sortable: false,
                                          filterable: false,
                                        },
                                      ]}
                                      filterable
                                      defaultPageSize={8}
                                      showPaginationTop={false}
                                      showPaginationBottom
                                      className="-striped -highlight"
                                    />
                                  </React.Fragment>
                                ),
                              },
                              {
                                tabIcon: EnhancedEncryptionIcon,
                                tabContent: (
                                  <form onSubmit={handleAddPackageAccessRights}>
                                    <h4 style={{ marginBottom: "15px" }}>
                                      {t("add-access-rights")}
                                    </h4>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {t("eol")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem
                                        xs={12}
                                        sm={2}
                                        style={{ float: "left" }}
                                      >
                                        <div
                                          className={
                                            formClasses.checkboxAndRadio
                                          }
                                        >
                                          <FormControlLabel
                                            style={{ float: "left" }}
                                            control={
                                              <Checkbox
                                                tabIndex={-1}
                                                onClick={() => {
                                                  const currentIndex =
                                                    packageAccessRightsForever.indexOf(
                                                      1
                                                    );
                                                  const newChecked = [
                                                    ...packageAccessRightsForever,
                                                  ];
                                                  if (currentIndex === -1) {
                                                    newChecked.push(1);
                                                  } else {
                                                    newChecked.splice(
                                                      currentIndex,
                                                      1
                                                    );
                                                  }
                                                  setPackageAccessRightsForever(
                                                    newChecked
                                                  );
                                                  if (newChecked.length > 0) {
                                                    setPackageAccessRightsTTL(
                                                      "-1"
                                                    );
                                                  }
                                                }}
                                                checkedIcon={
                                                  <Check
                                                    className={
                                                      formClasses.checkedIcon
                                                    }
                                                  />
                                                }
                                                icon={
                                                  <Check
                                                    className={
                                                      formClasses.uncheckedIcon
                                                    }
                                                  />
                                                }
                                                classes={{
                                                  checked: formClasses.checked,
                                                  root: formClasses.checkRoot,
                                                }}
                                                checked={
                                                  packageAccessRightsForever.length >
                                                  0
                                                }
                                              />
                                            }
                                            classes={{
                                              label: formClasses.label,
                                              root: formClasses.labelRoot,
                                            }}
                                            label={t("forever")}
                                          />
                                        </div>
                                      </GridItem>
                                      <GridItem xs={12} sm={4}>
                                        <div
                                          className={
                                            formClasses.checkboxAndRadio
                                          }
                                        >
                                          <FormControl fullWidth>
                                            <Datetime
                                              dateFormat={"YYYY-MM-DD"}
                                              timeFormat={false}
                                              inputProps={{
                                                placeholder: t("select-date"),
                                                disabled:
                                                  packageAccessRightsForever.length >
                                                  0,
                                              }}
                                              value={
                                                packageAccessRightsTTL === "-1"
                                                  ? undefined
                                                  : packageAccessRightsTTL
                                              }
                                              onChange={(e) => {
                                                setPackageAccessRightsTTL(e);
                                              }}
                                              closeOnSelect
                                            />
                                          </FormControl>
                                        </div>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("type")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={6}>
                                        <FormControl
                                          fullWidth
                                          className={
                                            formClasses.selectFormControl
                                          }
                                        >
                                          <InputLabel
                                            htmlFor="access-rights-type"
                                            className={formClasses.selectLabel}
                                          ></InputLabel>
                                          <Select
                                            value={packageAccessRightsType}
                                            onChange={(e) => {
                                              setPackageAccessRightsType(
                                                e.target.value
                                              );
                                            }}
                                            MenuProps={{
                                              className: formClasses.selectMenu,
                                            }}
                                            classes={{
                                              select: formClasses.select,
                                            }}
                                            inputProps={{
                                              name: "accessRightsType",
                                              id: "access-rights-type",
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                              }}
                                            >
                                              {t("type")}
                                            </MenuItem>
                                            {Object.values(
                                              ApiService.accessRightsTypes
                                            ).map((accessRightsType) => {
                                              return (
                                                <MenuItem
                                                  key={accessRightsType}
                                                  classes={{
                                                    root: formClasses.selectMenuItem,
                                                    selected:
                                                      formClasses.selectMenuItemSelectedMultiple,
                                                  }}
                                                  value={accessRightsType}
                                                >
                                                  {accessRightsType}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {t("email")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={6}>
                                        <FormControl
                                          fullWidth
                                          className={
                                            formClasses.selectFormControl
                                          }
                                        >
                                          <InputLabel
                                            htmlFor="access-rights-email"
                                            className={formClasses.selectLabel}
                                          ></InputLabel>
                                          <Select
                                            value={packageAccessRightsEmailUser}
                                            onChange={(e) => {
                                              const selected = e.target.value;
                                              if (selected === otherUser) {
                                                setPackageAccessRightsEmail("");
                                              } else {
                                                setPackageAccessRightsEmail(
                                                  selected
                                                );
                                              }
                                              setPackageAccessRightsEmailUser(
                                                selected
                                              );
                                            }}
                                            MenuProps={{
                                              className: formClasses.selectMenu,
                                            }}
                                            classes={{
                                              select: formClasses.select,
                                            }}
                                            inputProps={{
                                              name: "accessRightsEmail",
                                              id: "access-rights-email",
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                              }}
                                            >
                                              {t("email")}
                                            </MenuItem>
                                            {usersList.map((entry) => {
                                              return (
                                                <MenuItem
                                                  key={entry.user._id}
                                                  classes={{
                                                    root: formClasses.selectMenuItem,
                                                    selected:
                                                      formClasses.selectMenuItemSelectedMultiple,
                                                  }}
                                                  value={entry.user.email}
                                                >
                                                  {formatUserEntry(
                                                    entry.address
                                                  )}
                                                </MenuItem>
                                              );
                                            })}
                                            <MenuItem
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                                selected:
                                                  formClasses.selectMenuItemSelectedMultiple,
                                              }}
                                              value={otherUser}
                                            >
                                              {t("other")}
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    </GridContainer>
                                    {packageAccessRightsEmailUser ===
                                      otherUser && (
                                      <GridContainer>
                                        <GridItem xs={12} sm={1}>
                                          <FormLabel
                                            className={
                                              formClasses.labelHorizontal
                                            }
                                            style={{ float: "left" }}
                                          ></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={3}>
                                          <CustomInput
                                            id="email"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              type: "text",
                                              value: packageAccessRightsEmail,
                                              onChange: (e) => {
                                                setPackageAccessRightsEmail(
                                                  e.target.value
                                                );
                                              },
                                            }}
                                            helpText={t("email")}
                                          />
                                        </GridItem>
                                      </GridContainer>
                                    )}
                                    <GridContainer>
                                      <GridItem xs={12} sm={7}>
                                        <Button
                                          color="primary"
                                          type="submit"
                                          disabled={refreshDisabled}
                                          style={{
                                            float: "right",
                                            marginTop: "15px",
                                          }}
                                        >
                                          {t("add-access")}
                                        </Button>
                                      </GridItem>
                                    </GridContainer>
                                  </form>
                                ),
                              },
                            ]}
                          />
                        </CardBody>
                      </Card>
                    ),
                  }
                : undefined,
              ownerActive || inviteActive
                ? {
                    tabButton: t("reporting-fields"),
                    tabIcon: TextFieldsIcon,
                    tabContent: (
                      <Card>
                        <CardBody>
                          <NavPills
                            color="rose"
                            horizontal={{
                              tabsGrid: { xs: 12, sm: 12, md: 2 },
                              contentGrid: { xs: 12, sm: 12, md: 10 },
                            }}
                            tabs={[
                              {
                                tabIcon: TextFieldsIcon,
                                tabContent: (
                                  <React.Fragment>
                                    <ReactTable
                                      data={
                                        "reportingFields" in packageDetails
                                          ? packageDetails.reportingFields.map(
                                              (elem) => {
                                                return {
                                                  type: elem.type,
                                                  coordinates: `(${
                                                    elem.xCoordinate
                                                  }, ${elem.yCoordinate})${
                                                    elem.rectangleWidth
                                                      ? ` [${elem.rectangleWidth}`
                                                      : ""
                                                  } ${
                                                    elem.rectangleHeight
                                                      ? `, ${elem.rectangleHeight}]`
                                                      : ""
                                                  }`,
                                                  pages: `${elem.pageNumber}${
                                                    elem.pageToNumber
                                                      ? ` -> ${elem.pageToNumber}`
                                                      : ""
                                                  }`,
                                                  font: [
                                                    elem.fontSize
                                                      ? `${elem.fontSize}`
                                                      : undefined,
                                                    elem.bold
                                                      ? "bold"
                                                      : undefined,
                                                    elem.italic
                                                      ? "italic"
                                                      : undefined,
                                                  ]
                                                    .filter(
                                                      (x) => x !== undefined
                                                    )
                                                    .join(", "),
                                                  dateFormat: elem.dateFormat,
                                                  text: elem.text,
                                                  signatureEmail:
                                                    elem.signatureEmail,
                                                  actions: (
                                                    <div className="actions-right">
                                                      <Tooltip
                                                        title={t(
                                                          "delete-reporting-field"
                                                        )}
                                                      >
                                                        <Button
                                                          justIcon
                                                          round
                                                          simple
                                                          className="remove"
                                                          onClick={() => {
                                                            showDeletePackageReportingField(
                                                              elem
                                                            );
                                                          }}
                                                        >
                                                          <ClearIcon
                                                            style={{
                                                              color:
                                                                grayColor[17],
                                                            }}
                                                          />
                                                        </Button>
                                                      </Tooltip>
                                                    </div>
                                                  ),
                                                };
                                              }
                                            )
                                          : []
                                      }
                                      columns={[
                                        {
                                          Header: t("type"),
                                          accessor: "type",
                                        },
                                        {
                                          Header: t("coordinates"),
                                          accessor: "coordinates",
                                        },
                                        {
                                          Header: t("pages"),
                                          accessor: "pages",
                                        },
                                        {
                                          Header: t("font"),
                                          accessor: "font",
                                        },
                                        {
                                          Header: t("dateFormat"),
                                          accessor: "dateFormat",
                                        },
                                        {
                                          Header: t("signatureEmail"),
                                          accessor: "signatureEmail",
                                        },
                                        {
                                          Header: t("text"),
                                          accessor: "text",
                                        },
                                        {
                                          Header: t("actions"),
                                          accessor: "actions",
                                          sortable: false,
                                          filterable: false,
                                        },
                                      ]}
                                      filterable
                                      defaultPageSize={8}
                                      showPaginationTop={false}
                                      showPaginationBottom
                                      className="-striped -highlight"
                                    />
                                  </React.Fragment>
                                ),
                              },
                              {
                                tabIcon: AddBoxIcon,
                                tabContent: (
                                  <form
                                    onSubmit={handleAddPackageReportingField}
                                  >
                                    <h4 style={{ marginBottom: "15px" }}>
                                      {t("add-reporting-field")}
                                    </h4>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("type")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={3}>
                                        <FormControl
                                          fullWidth
                                          className={
                                            formClasses.selectFormControl
                                          }
                                        >
                                          <InputLabel
                                            htmlFor="reporting-field-type"
                                            className={formClasses.selectLabel}
                                          ></InputLabel>
                                          <Select
                                            value={reportingFieldType}
                                            onChange={(e) => {
                                              setReportingFieldType(
                                                e.target.value
                                              );
                                            }}
                                            MenuProps={{
                                              className: formClasses.selectMenu,
                                            }}
                                            classes={{
                                              select: formClasses.select,
                                            }}
                                            inputProps={{
                                              name: "reportingFieldType",
                                              id: "reporting-field-type",
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                              }}
                                            >
                                              {t("type")}
                                            </MenuItem>
                                            {Object.values(
                                              ApiService.reportingFieldTypes
                                            ).map((reportingFieldType_) => {
                                              return (
                                                <MenuItem
                                                  key={reportingFieldType_}
                                                  classes={{
                                                    root: formClasses.selectMenuItem,
                                                    selected:
                                                      formClasses.selectMenuItemSelectedMultiple,
                                                  }}
                                                  value={reportingFieldType_}
                                                >
                                                  {reportingFieldType_}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("coordinates")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={3}>
                                        <CustomInput
                                          id="x-coordinate"
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            type: "text",
                                            value: xCoordinate,
                                            onChange: (e) => {
                                              setXCoordinate(e.target.value);
                                            },
                                          }}
                                          labelText={t("x-coordinate")}
                                        />
                                      </GridItem>
                                      <GridItem xs={12} sm={3}>
                                        <CustomInput
                                          id="y-coordinate"
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            type: "text",
                                            value: yCoordinate,
                                            onChange: (e) => {
                                              setYCoordinate(e.target.value);
                                            },
                                          }}
                                          labelText={t("y-coordinate")}
                                        />
                                      </GridItem>
                                      {reportingFieldType ===
                                        ApiService.reportingFieldTypes
                                          .signature && (
                                        <React.Fragment>
                                          <GridItem xs={12} sm={2}>
                                            <CustomInput
                                              id="rectangle-width"
                                              formControlProps={{
                                                fullWidth: true,
                                              }}
                                              inputProps={{
                                                type: "text",
                                                value: rectangleWidth,
                                                onChange: (e) => {
                                                  setRectangleWidth(
                                                    e.target.value
                                                  );
                                                },
                                              }}
                                              labelText={t("rectangle-width")}
                                            />
                                          </GridItem>
                                          <GridItem xs={12} sm={2}>
                                            <CustomInput
                                              id="rectangle-width"
                                              formControlProps={{
                                                fullWidth: true,
                                              }}
                                              inputProps={{
                                                type: "text",
                                                value: rectangleHeight,
                                                onChange: (e) => {
                                                  setRectangleHeight(
                                                    e.target.value
                                                  );
                                                },
                                              }}
                                              labelText={t("rectangle-height")}
                                            />
                                          </GridItem>
                                        </React.Fragment>
                                      )}
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("pages")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={4}>
                                        <CustomInput
                                          id="page-number"
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            type: "text",
                                            value: pageNumber,
                                            onChange: (e) => {
                                              setPageNumber(e.target.value);
                                            },
                                          }}
                                          labelText={t("page-number")}
                                        />
                                      </GridItem>
                                      <GridItem xs={12} sm={4}>
                                        <CustomInput
                                          id="page-to-number"
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            type: "text",
                                            value: pageToNumber,
                                            onChange: (e) => {
                                              setPageToNumber(e.target.value);
                                            },
                                          }}
                                          labelText={t("page-to-number")}
                                        />
                                      </GridItem>
                                    </GridContainer>
                                    {reportingFieldType !==
                                      ApiService.reportingFieldTypes
                                        .signature && (
                                      <GridContainer>
                                        <GridItem xs={12} sm={1}>
                                          <FormLabel
                                            className={
                                              formClasses.labelHorizontal +
                                              " " +
                                              formClasses.labelHorizontalRadioCheckbox
                                            }
                                            style={{
                                              float: "left",
                                              paddingTop: "40px",
                                            }}
                                          >
                                            {t("font")}
                                          </FormLabel>
                                        </GridItem>
                                        <GridItem
                                          xs={12}
                                          sm={2}
                                          style={{
                                            float: "left",
                                            marginTop: "16px",
                                          }}
                                        >
                                          <div
                                            className={
                                              formClasses.checkboxAndRadio
                                            }
                                          >
                                            <FormControlLabel
                                              style={{ float: "left" }}
                                              control={
                                                <Checkbox
                                                  tabIndex={-1}
                                                  onClick={() => {
                                                    const currentIndex =
                                                      bold.indexOf(1);
                                                    const newChecked = [
                                                      ...bold,
                                                    ];
                                                    if (currentIndex === -1) {
                                                      newChecked.push(1);
                                                    } else {
                                                      newChecked.splice(
                                                        currentIndex,
                                                        1
                                                      );
                                                    }
                                                    setBold(newChecked);
                                                  }}
                                                  checkedIcon={
                                                    <Check
                                                      className={
                                                        formClasses.checkedIcon
                                                      }
                                                    />
                                                  }
                                                  icon={
                                                    <Check
                                                      className={
                                                        formClasses.uncheckedIcon
                                                      }
                                                    />
                                                  }
                                                  classes={{
                                                    checked:
                                                      formClasses.checked,
                                                    root: formClasses.checkRoot,
                                                  }}
                                                  checked={bold.length > 0}
                                                />
                                              }
                                              classes={{
                                                label: formClasses.label,
                                                root: formClasses.labelRoot,
                                              }}
                                              label={t("bold")}
                                            />
                                          </div>
                                        </GridItem>
                                        <GridItem
                                          xs={12}
                                          sm={2}
                                          style={{
                                            float: "left",
                                            marginTop: "16px",
                                          }}
                                        >
                                          <div
                                            className={
                                              formClasses.checkboxAndRadio
                                            }
                                          >
                                            <FormControlLabel
                                              style={{ float: "left" }}
                                              control={
                                                <Checkbox
                                                  tabIndex={-1}
                                                  onClick={() => {
                                                    const currentIndex =
                                                      italic.indexOf(1);
                                                    const newChecked = [
                                                      ...italic,
                                                    ];
                                                    if (currentIndex === -1) {
                                                      newChecked.push(1);
                                                    } else {
                                                      newChecked.splice(
                                                        currentIndex,
                                                        1
                                                      );
                                                    }
                                                    setItalic(newChecked);
                                                  }}
                                                  checkedIcon={
                                                    <Check
                                                      className={
                                                        formClasses.checkedIcon
                                                      }
                                                    />
                                                  }
                                                  icon={
                                                    <Check
                                                      className={
                                                        formClasses.uncheckedIcon
                                                      }
                                                    />
                                                  }
                                                  classes={{
                                                    checked:
                                                      formClasses.checked,
                                                    root: formClasses.checkRoot,
                                                  }}
                                                  checked={italic.length > 0}
                                                />
                                              }
                                              classes={{
                                                label: formClasses.label,
                                                root: formClasses.labelRoot,
                                              }}
                                              label={t("italic")}
                                            />
                                          </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={4}>
                                          <CustomInput
                                            id="font-size"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              type: "text",
                                              value: fontSize,
                                              onChange: (e) => {
                                                setFontSize(e.target.value);
                                              },
                                            }}
                                            labelText={t("font-size")}
                                          />
                                        </GridItem>
                                      </GridContainer>
                                    )}
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("format")}
                                        </FormLabel>
                                      </GridItem>
                                      {reportingFieldType ===
                                        ApiService.reportingFieldTypes.date && (
                                        <GridItem xs={12} sm={3}>
                                          <CustomInput
                                            id="date-format"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              type: "text",
                                              value: dateFormat,
                                              onChange: (e) => {
                                                setDateFormat(e.target.value);
                                              },
                                            }}
                                            labelText={t("date-format")}
                                          />
                                        </GridItem>
                                      )}
                                      {reportingFieldType ===
                                        ApiService.reportingFieldTypes.text && (
                                        <GridItem xs={12} sm={3}>
                                          <CustomInput
                                            id="text"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              type: "text",
                                              value: text,
                                              onChange: (e) => {
                                                setText(e.target.value);
                                              },
                                            }}
                                            labelText={t("text")}
                                          />
                                        </GridItem>
                                      )}
                                      {reportingFieldType !==
                                        ApiService.reportingFieldTypes.text && (
                                        <GridItem xs={12} sm={3}>
                                          <CustomInput
                                            id="signature-email"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              type: "text",
                                              value: signatureEmail,
                                              onChange: (e) => {
                                                setSignatureEmail(
                                                  e.target.value
                                                );
                                              },
                                            }}
                                            labelText={t("signature-email")}
                                          />
                                        </GridItem>
                                      )}
                                      <GridItem xs={12} sm={2}>
                                        <Button
                                          color="primary"
                                          type="submit"
                                          disabled={refreshDisabled}
                                          style={{
                                            float: "left",
                                            marginTop: "16px",
                                          }}
                                        >
                                          {t("add-field")}
                                        </Button>
                                      </GridItem>
                                    </GridContainer>
                                  </form>
                                ),
                              },
                            ]}
                          />
                        </CardBody>
                      </Card>
                    ),
                  }
                : undefined,
              packageDetails.blockchainStore
                ? {
                    tabButton: t("blockchain-data-transactions"),
                    tabIcon: DataUsage,
                    tabContent: (
                      <Card>
                        <CardHeader
                          color="turq"
                          icon
                          style={{ display: "inline-flex" }}
                        >
                          <CardIcon color="turq">
                            <DataUsage style={{ color: roseColor[0] }} />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            {t("blockchain-data-transactions")}
                          </h4>
                          <div style={{ marginLeft: "auto" }}>
                            <Button
                              round
                              simple
                              color="rose"
                              className="remove"
                              onClick={() => {
                                handleRefresh();
                              }}
                              disabled={refreshDisabled}
                              style={{ marginTop: "28px" }}
                            >
                              {t("refresh")}
                            </Button>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <ReactTable
                            data={packageTransactionChunks.map((elem) => {
                              return {
                                ...elem,
                                transactionId: elem.transactionId
                                  ? elem.transactionId
                                  : "-",
                                encrypted: elem.encrypted.toString(),
                                actions: (
                                  <div className="actions-right">
                                    {elem.transactionStatus ===
                                    ApiService.jobTransactionStatus.written ? (
                                      <a
                                        href={
                                          ApiService.populatePath(
                                            ApiService.blockchainInfoURL,
                                            {
                                              transactionId: elem.transactionId,
                                            }
                                          ).populatedPath
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <Tooltip
                                          title={t("blockchain-info-url")}
                                        >
                                          <Button
                                            justIcon
                                            round
                                            simple
                                            color="rose"
                                            className="remove"
                                          >
                                            <InfoIcon />
                                          </Button>
                                        </Tooltip>
                                      </a>
                                    ) : (
                                      <Tooltip title={t("blockchain-info-url")}>
                                        <Button
                                          justIcon
                                          round
                                          simple
                                          color="rose"
                                          className="remove"
                                          disabled
                                        >
                                          <InfoIcon />
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </div>
                                ),
                              };
                            })}
                            filterable
                            columns={[
                              {
                                Header: t("chunk-id"),
                                accessor: "chunkId",
                              },
                              {
                                Header: t("encoding"),
                                accessor: "encoding",
                              },
                              {
                                Header: t("encrypted"),
                                accessor: "encrypted",
                              },
                              {
                                Header: t("txid"),
                                accessor: "transactionId",
                                headerStyle: { textAlign: "right" },
                                Cell: (row) => (
                                  <div style={{ textAlign: "right" }}>
                                    {row.value}
                                  </div>
                                ),
                              },
                              {
                                Header: t("status"),
                                accessor: "transactionStatus",
                                headerStyle: { textAlign: "right" },
                                Cell: (row) => (
                                  <div style={{ textAlign: "right" }}>
                                    {row.value}
                                  </div>
                                ),
                              },
                              {
                                Header: t("actions"),
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                              },
                            ]}
                            defaultPageSize={6}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        </CardBody>
                      </Card>
                    ),
                  }
                : undefined,
            ].filter((x) => x !== undefined)}
          />
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={packageSuccessMessage}
        open={packageSuccessMessage.length > 0}
        closeNotification={() => showPackageSuccess("")}
        close
      />
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={packageErrorMessage}
        open={packageErrorMessage.length > 0}
        closeNotification={() => showPackageError("")}
        close
      />
      <Dialog
        classes={{
          root: dialogClasses.center + " " + dialogClasses.modalRoot,
          paper: dialogClasses.modal,
        }}
        open={showShareDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearSharingState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {(showPublishDialog &&
              `${t("publish-package")} ${formattedUserName}`) ||
              `${t("share-package")} ${formattedUserName}`}
          </h4>
        </DialogTitle>
        <form onSubmit={handleShare}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            {!showPublishDialog && (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left", paddingTop: "40px" }}
                  >
                    {t("recipients")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    className={formClasses.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="multiple-select"
                      className={formClasses.selectLabel}
                    ></InputLabel>
                    <Select
                      multiple
                      value={sharingRecipients}
                      onChange={(e) => {
                        setSharingRecipients(e.target.value);
                      }}
                      MenuProps={{ className: formClasses.selectMenu }}
                      classes={{ select: formClasses.select }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "recipients",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: formClasses.selectMenuItem,
                        }}
                      >
                        {t("recipients")}
                      </MenuItem>
                      {sharingUsers.map((entry) => {
                        return (
                          <MenuItem
                            key={entry.address}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={entry.address}
                          >
                            {entry.user.email}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={4} style={{ float: "left" }}>
                  <div
                    className={formClasses.checkboxAndRadio}
                    style={{ marginTop: "20px" }}
                  >
                    <FormControlLabel
                      style={{ float: "left" }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => {
                            const currentIndex = canShare.indexOf(1);
                            const newChecked = [...canShare];
                            if (currentIndex === -1) {
                              newChecked.push(1);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }
                            setCanShare(newChecked);
                          }}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot,
                          }}
                          checked={canShare.length > 0}
                          disabled={showProgress}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot,
                      }}
                      label={t("can-share")}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            )}

            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("title")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="package-details-title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: sharingTitle,
                    disabled: showProgress,
                    onChange: (e) => {
                      setSharingTitle(e.target.value);
                    },
                  }}
                  helpText={t("title-of-document")}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("message")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="message"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: sharingMessage,
                    disabled: showProgress,
                    multiline: true,
                    onChange: (e) => {
                      setSharingMessage(e.target.value);
                    },
                  }}
                  helpText={t("message-of-document")}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("eol")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={4} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = storeForever.indexOf(1);
                          const newChecked = [...storeForever];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setStoreForever(newChecked);
                          if (newChecked.length > 0) {
                            setShareTTL(-1);
                          }
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={storeForever.length > 0}
                        disabled={showProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("store-forever")}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"YYYY-MM-DD"}
                      timeFormat={false}
                      inputProps={{
                        placeholder: t("select-date"),
                        disabled: showProgress || storeForever.length > 0,
                      }}
                      value={shareTTL === -1 ? undefined : shareTTL}
                      onChange={(e) => {
                        setShareTTL(e);
                      }}
                      closeOnSelect
                    />
                  </FormControl>
                </div>
              </GridItem>
            </GridContainer>

            <br />
            {showProgress && (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={progress}
                      />
                      <Muted>
                        {jobId} - {progress}% (
                        {moment.utc(progressRuntime).format("HH:mm:ss.SSS")})
                      </Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {message && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{message}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                if (shareAbortController) {
                  shareAbortController.abort();
                }
                clearSharingState();
              }}
              disabled={confirmDialog}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showProgress && !confirmDialog}
            >
              {confirmButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: dialogClasses.center,
          paper: dialogClasses.modal,
        }}
        open={showHashVerificationDialog}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth={true}
        maxWidth="md"
        onClose={() => setShowHashVerificationDialog(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <Button
            justIcon
            simple
            className={dialogClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="rose"
            onClick={() => setShowHashVerificationDialog(false)}
          >
            <Close className={dialogClasses.modalClose} />
          </Button>
          <h4 className={dialogClasses.modalTitle}>
            {t("package-hash-verification-0")}: <b>{packageDetails._id}</b>
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={dialogClasses.modalBody}
        >
          {packageVerification.verificationSteps &&
            packageVerification.verificationSteps.map((element) => {
              return (
                <React.Fragment key={uuidv4()}>
                  <GridContainer
                    className={dialogClasses.cardMargin}
                    key={uuidv4()}
                    style={{ fontWeight: "600" }}
                  >
                    <GridItem xs={12} sm={6} md={4}>
                      {t("stage")}
                    </GridItem>
                    <GridItem xs={12} sm={6} md={8}>
                      {element.stage.toUpperCase()}
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    style={{
                      borderBottom: "1px solid #c4c4c4",
                      paddingBottom: "15px",
                    }}
                  >
                    {element.details.map(([fieldName, fieldValue]) => {
                      let data;
                      if (typeof fieldValue === "object") {
                        data = (
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            {JSON.stringify(fieldValue, undefined, 2)}
                          </div>
                        );
                      } else {
                        data = (
                          <React.Fragment>
                            {fieldValue.toString()}
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment key={uuidv4()}>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={4}
                            style={{ fontWeight: "400" }}
                          >
                            {fieldName}
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={8}
                            className={dialogClasses.wordBreak}
                          >
                            {data}
                          </GridItem>
                        </React.Fragment>
                      );
                    })}
                  </GridContainer>
                </React.Fragment>
              );
            })}
        </DialogContent>
        <DialogActions
          className={
            dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
          }
        >
          <Button
            onClick={() => setShowHashVerificationDialog(false)}
            color="rose"
            simple
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: dialogClasses.center,
          paper: dialogClasses.modal,
        }}
        open={showVerificationDialog}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth={true}
        maxWidth="md"
        onClose={() => setShowVerificationDialog(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <Button
            justIcon
            className={dialogClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowVerificationDialog(false)}
          >
            <Close className={dialogClasses.modalClose} />
          </Button>
          <h4 className={dialogClasses.modalTitle}>
            {t("signature-verification-0")}: <b>{signatureVerification._id}</b>
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={dialogClasses.modalBody}
        >
          {signatureVerification.verificationSteps &&
            signatureVerification.verificationSteps.map((element) => {
              return (
                <React.Fragment key={uuidv4()}>
                  <GridContainer
                    className={dialogClasses.cardMargin}
                    key={uuidv4()}
                    style={{ fontWeight: "600" }}
                  >
                    <GridItem xs={12} sm={6} md={4}>
                      {t("stage")}
                    </GridItem>
                    <GridItem xs={12} sm={6} md={8}>
                      {element.stage.toUpperCase()}
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    style={{
                      borderBottom: "1px solid #c4c4c4",
                      paddingBottom: "15px",
                    }}
                  >
                    {element.details.map(([fieldName, fieldValue]) => {
                      let data;
                      if (typeof fieldValue === "object") {
                        data = (
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            {JSON.stringify(fieldValue, undefined, 2)}
                          </div>
                        );
                      } else {
                        data = (
                          <React.Fragment>
                            {fieldValue.toString()}
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment key={uuidv4()}>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={4}
                            style={{ fontWeight: "400" }}
                          >
                            {fieldName}
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={8}
                            className={dialogClasses.wordBreak}
                          >
                            {ApiService.checkValidLink(fieldValue) ? (
                              <a
                                href={fieldValue}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {fieldValue}
                              </a>
                            ) : (
                              data
                            )}
                          </GridItem>
                        </React.Fragment>
                      );
                    })}
                  </GridContainer>
                </React.Fragment>
              );
            })}
        </DialogContent>
        <DialogActions
          className={
            dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
          }
        >
          <Button
            onClick={() => setShowVerificationDialog(false)}
            color="rose"
            simple
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("update-signature-0")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleUpdateSignature}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("access")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="access-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalUpdateAccess}
                    onChange={(e) => {
                      setModalUpdateAccess(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "accessType",
                      id: "access-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("access")}
                    </MenuItem>
                    {Object.values(ApiService.signatureAccess).map(
                      (accessType) => {
                        return (
                          <MenuItem
                            key={accessType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={accessType}
                          >
                            {accessType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showSignatureModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("sign-package-hash-0")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handlePackageSigning}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("blockchain")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="blockchain-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalBlockchain}
                    onChange={(e) => {
                      setModalBlockchain(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "blockchainType",
                      id: "blockchain-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("blockchain")}
                    </MenuItem>
                    {Object.values(ApiService.blockchainTypes).map(
                      (blockchainType) => {
                        return (
                          <MenuItem
                            key={blockchainType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={blockchainType}
                          >
                            {blockchainType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                if (signatureAbortController) {
                  signatureAbortController.abort();
                }
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {alert}
    </div>
  );
};

PackageDetails.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default PackageDetails;
