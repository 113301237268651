import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Bookmarks from "@material-ui/icons/Bookmarks";

import { Link } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);

const SharedAccessPackages = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [packages, setPackages] = React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [userId, setUserId] = React.useState("");

  const [packagesCount, setPackagesCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);

  const [sortBy, setSortBy] = React.useState("createdAt:desc");
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const handleReturnData = (data, userId) => {
    return data.map((elem) => {
      if (!elem.endOfLife) {
        elem.endOfLife = t("forever");
      }
      elem.blockchainStoreFormatted = elem.blockchainStore
        ? elem.blockchainStore.toString()
        : "false";
      elem.shareableFormatted = elem.shareable.toString();
      const queryParameters = new URLSearchParams({
        userId,
        data_id: elem.data_id,
        sender: elem.sender,
        receiver: elem.receiver,
        hash: elem.hash,
      }).toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("package-details-0")}>
            <Link to={`/admin/package?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <MoreVertIcon />
              </Button>
            </Link>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleRefresh = async () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      setUserId(params.userId);
      return params;
    };

    const getPackages = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          parentType: ApiService.parentTypes.package,
          populateParent: "true",
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        const responseData = await ApiService.getAccessRights(params, signal);
        setPackages(responseData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        const { count: accessRightsCount } =
          await ApiService.getAccessRightsCount(params, signal);
        setPackagesCount(accessRightsCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const handleRefresh = async () => {
      try {
        await getPackages();
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && packagesCount > 0) {
      setTotalPages(Math.ceil(packagesCount / pageSize));
    }
    return () => {
      isMounted = false;
    };
  }, [packagesCount, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(packagesCount / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }

    setCurrentPage(currentIndex);
    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted, column, shiftKey) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
            <CardIcon color="turq">
              <Bookmarks style={{ color: roseColor[0] }} />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {t("shared-with-me-packages")} {formattedUserName}
            </h4>
            <div style={{ marginLeft: "auto" }}>
              <Button
                round
                color="rose"
                simple
                className="remove"
                onClick={handleRefresh}
                style={{ marginTop: "28px" }}
              >
                {t("refresh")}
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {filters.length > 0 && (
              <React.Fragment>
                <Button
                  color="primary"
                  round
                  className="remove"
                  onClick={() => {
                    handleFilterClicked();
                  }}
                >
                  {t("filter")}
                </Button>
                <Button
                  color="rose"
                  round
                  simple
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                >
                  {t("clear-filters")}
                </Button>
              </React.Fragment>
            )}
            <ReactTable
              data={handleReturnData(packages, userId)}
              sortable={true}
              multiSort={false}
              filterable={true}
              filtered={filters}
              columns={[
                {
                  Header: t("title"),
                  accessor: "title",
                },
                {
                  Header: t("parent-id"),
                  accessor: "parentId",
                },
                {
                  Header: t("file-name"),
                  accessor: "fileName",
                },
                {
                  Header: t("file-format"),
                  accessor: "dataType",
                },
                {
                  Header: t("file-size"),
                  accessor: "fileSize",
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>{row.value}</div>
                  ),
                },
                {
                  Header: t("service"),
                  accessor: "storageService",
                },
                {
                  Header: t("blockchain-store"),
                  accessor: "blockchainStoreFormatted",
                },
                {
                  Header: t("eol"),
                  accessor: "endOfLife",
                  headerStyle: { textAlign: "right" },
                  filterable: false,
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>
                      {ApiService.formatDateTime(row.value)}
                    </div>
                  ),
                },
                {
                  Header: t("hash"),
                  accessor: "hash",
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>{row.value}</div>
                  ),
                },
                {
                  Header: t("txid"),
                  accessor: "transactionId",
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>{row.value}</div>
                  ),
                },
                {
                  Header: t("actions"),
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={ApiService.defaultPageSize}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
              manual
              pages={totalPages}
              page={currentPage}
              pageSize={pageSize}
              onPageChange={(pageIndex) => handlePageChange(pageIndex)} // Called when the page index is changed by the user
              onPageSizeChange={(pageSize, pageIndex) =>
                handlePageSizeChange(pageSize, pageIndex)
              } // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
              onSortedChange={(newSorted, column, shiftKey) =>
                handleSortChange(newSorted, column, shiftKey)
              }
              onFilteredChange={(filtered, column) =>
                handleFilterColumn(filtered, column)
              }
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

SharedAccessPackages.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SharedAccessPackages;
