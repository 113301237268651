import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import Bookmarks from "@material-ui/icons/Bookmarks";
import ClearIcon from "@material-ui/icons/Clear";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Muted from "components/Typography/Muted.js";
import { Link } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DocumentContainers = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [documentContainers, setDocumentContainers] = React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  // Dialog fields
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalMessage, setModalMessage] = React.useState("");
  const [modalAccess, setModalAccess] = React.useState(
    ApiService.documentContainerAccess.private
  );
  const [modalShareTTL, setModalShareTTL] = React.useState("-1");
  const [modalStoreForever, setModalStoreForever] = React.useState([]);

  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [documentContainerDetails, setDocumentContainerDetails] =
    React.useState({});
  const [modalUpdateTitle, setModalUpdateTitle] = React.useState("");
  const [modalUpdateMessage, setModalUpdateMessage] = React.useState("");
  const [modalUpdateAccess, setModalUpdateAccess] = React.useState(
    ApiService.documentContainerAccess.private
  );
  const [modalUpdateTTL, setModalUpdateTTL] = React.useState([]);
  const [modalUpdateShareTTL, setModalUpdateShareTTL] = React.useState("-1");
  const [modalUpdateStoreForever, setModalUpdateStoreForever] = React.useState(
    []
  );
  const [showModalAccessId, setShowModalAccessId] = React.useState(false);
  const [modalAccessId, setModalAccessId] = React.useState("");

  const [containerCount, setContainerCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);

  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [refreshClicked, setRefreshClicked] = React.useState(false);
  const [userId, setUserId] = React.useState("");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId = userProfileData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalTitle("");
    setModalMessage("");
    setModalAccess(ApiService.documentContainerAccess.private);
    setModalShareTTL("-1");
    setModalStoreForever([]);
    setShowUpdateModal(false);
    setDocumentContainerDetails({});
    setModalUpdateTitle("");
    setModalUpdateMessage("");
    setModalUpdateAccess(ApiService.documentContainerAccess.private);
    setModalUpdateTTL([]);
    setModalUpdateShareTTL("-1");
    setModalUpdateStoreForever([]);
    setShowModalAccessId(false);
    setModalAccessId("");
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleReturnData = (data, userId) => {
    return data.map((elem) => {
      if (!elem.endOfLife) {
        elem.endOfLife = t("forever");
      }
      const queryParameters = new URLSearchParams({
        userId,
        documentContainerId: elem._id,
      }).toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("document-container-details-0")}>
            <Link to={`/admin/document-container?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <MoreVertIcon />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t("update-document-container-0")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                setDocumentContainerDetails(elem);
                setModalUpdateTitle(elem.title);
                setModalUpdateMessage(elem.message);
                setModalUpdateAccess(elem.access);
                setConfirmModalButtonText(t("update"));
                setShowUpdateModal(true);
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("delete-document-container")}>
            <Button
              justIcon
              round
              color="rose"
              simple
              className="remove"
              onClick={() => {
                handleDeleteDocumentContainer(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleDeleteDocumentContainer = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`"${t("delete-document-container")}?"`}
        onConfirm={() => {
          deleteDocumentContainer(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteDocumentContainer = async (documentContainer) => {
    setAlert(null);
    try {
      const { userId } = await getQueryParams();
      await ApiService.deleteDocumentContainer({
        userId,
        documentContainerId: documentContainer._id,
      });
      operationResult(true, t("document-container-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleAddDocumentContainer = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const title = modalTitle.trim();
    const validTitle = title.length > 0;
    const message = modalMessage.trim();
    const validMessage = message.length > 0;
    const ttl =
      modalShareTTL !== "-1" ? modalShareTTL.endOf("day").toISOString() : "-1";
    const validTTL = !(ttl === "-1" && modalStoreForever.length === 0);
    const checksPassed = validTitle && validMessage && validTTL;
    if (!checksPassed) {
      const messageContent = [];
      if (!validTitle) {
        messageContent.push(t("invalid-title"));
      }
      if (!validMessage) {
        messageContent.push(t("invalid-message"));
      }
      if (!validTTL) {
        messageContent.push(t("invalid-eol-defined"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      title,
      message,
      access: modalAccess,
      endOfLife: ttl,
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("processing"));
    try {
      await ApiService.addDocumentContainer(params, signal);
    } catch (e) {
      return showErrorModalMessage(t("error-processing-document-container"));
    }
    setModalProgress(100);
    return showSuccessModalMessage(
      t("document-container-successfully-processed")
    );
  };

  const handleUpdateDocumentContainer = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const title = modalUpdateTitle.trim();
    const updateTitle = title.length > 0;
    const message = modalUpdateMessage.trim();
    const updateMessage = message.length > 0;
    const ttl =
      modalUpdateShareTTL !== "-1"
        ? modalUpdateShareTTL.endOf("day").toISOString()
        : "-1";
    const validTTL = !(ttl === "-1" && modalUpdateStoreForever.length === 0);
    const updateTTL =
      modalUpdateTTL.length > 0 &&
      !(ttl === "-1" && !documentContainerDetails.endOfLife);
    const updateAccess = modalUpdateAccess !== documentContainerDetails.access;

    const checksPassed = !(modalUpdateTTL.length > 0 && !validTTL);
    if (!checksPassed) {
      const messageContent = [];
      if (!validTTL) {
        messageContent.push(t("invalid-eol-defined"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`
      );
    }

    if (!updateTitle && !updateMessage && !updateTTL && !updateAccess) {
      return clearModalState();
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      documentContainerId: documentContainerDetails._id,
    };
    if (updateTitle) {
      params.title = title;
    }
    if (updateMessage) {
      params.message = message;
    }
    if (updateAccess) {
      params.access = modalUpdateAccess;
    }
    if (updateTTL) {
      params.endOfLife = ttl;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("updating"));

    try {
      await ApiService.updateDocumentContainer(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(
      t("document-container-successfully-updated")
    );
  };

  const handleAccessDocumentContainer = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const accessId = modalAccessId.trim();
    const checksPassed = accessId.length === 24;
    if (!checksPassed) {
      return showErrorModalMessage(t("invalid-document-container-id"));
    }
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      documentContainerId: accessId,
    };
    const queryParameters = new URLSearchParams(params).toString();
    props.history.push(`/admin/document-container?${queryParameters}`);
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      setUserId(params.userId);
      return params;
    };

    const getDocumentContainers = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        const responseData = await ApiService.getDocumentContainers(
          params,
          signal
        );
        setDocumentContainers(responseData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        const { count: documentContainersCount } =
          await ApiService.getDocumentContainersCount(params, signal);
        setContainerCount(documentContainersCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const handleRefresh = async () => {
      try {
        await getDocumentContainers();
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && containerCount > 0) {
      setTotalPages(Math.ceil(containerCount / pageSize));
    }
    return () => {
      isMounted = false;
    };
  }, [containerCount, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(containerCount / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }

    setCurrentPage(currentIndex);
    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted, column, shiftKey) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("add-document-container")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleAddDocumentContainer}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("title")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="doc-container-title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalTitle,
                    disabled: showModalProgress,
                    onChange: (e) => {
                      setModalTitle(e.target.value);
                    },
                  }}
                  helpText={t("title-of-document")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("message")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="message"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalMessage,
                    disabled: showModalProgress,
                    multiline: true,
                    onChange: (e) => {
                      setModalMessage(e.target.value);
                    },
                  }}
                  helpText={t("message-of-document")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("access")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="access-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalAccess}
                    onChange={(e) => {
                      setModalAccess(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "accessType",
                      id: "access-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("access")}
                    </MenuItem>
                    {Object.values(ApiService.documentContainerAccess).map(
                      (accessType) => {
                        return (
                          <MenuItem
                            key={accessType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={accessType}
                          >
                            {accessType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("eol")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={4} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalStoreForever.indexOf(1);
                          const newChecked = [...modalStoreForever];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalStoreForever(newChecked);
                          if (newChecked.length > 0) {
                            setModalShareTTL("-1");
                          }
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalStoreForever.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("store-forever")}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"YYYY-MM-DD"}
                      timeFormat={false}
                      inputProps={{
                        placeholder: t("select-date"),
                        disabled:
                          showModalProgress || modalStoreForever.length > 0,
                      }}
                      value={modalShareTTL === "-1" ? undefined : modalShareTTL}
                      onChange={(e) => {
                        setModalShareTTL(e);
                      }}
                      closeOnSelect
                    />
                  </FormControl>
                </div>
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showUpdateModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("update-document-container")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleUpdateDocumentContainer}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("title")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="doc-container-1-title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalUpdateTitle,
                    disabled: showModalProgress,
                    onChange: (e) => {
                      setModalUpdateTitle(e.target.value);
                    },
                  }}
                  helpText={t("title-of-document")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("message")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="message"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalUpdateMessage,
                    disabled: showModalProgress,
                    multiline: true,
                    onChange: (e) => {
                      setModalUpdateMessage(e.target.value);
                    },
                  }}
                  helpText={t("message-of-document")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("access")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="access-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalUpdateAccess}
                    onChange={(e) => {
                      setModalUpdateAccess(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "accessType",
                      id: "access-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("access")}
                    </MenuItem>
                    {Object.values(ApiService.documentContainerAccess).map(
                      (accessType) => {
                        return (
                          <MenuItem
                            key={accessType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={accessType}
                          >
                            {accessType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                ></FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalUpdateTTL.indexOf(1);
                          const newChecked = [...modalUpdateTTL];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalUpdateTTL(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalUpdateTTL.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("change-eol")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            {modalUpdateTTL.length > 0 && (
              <React.Fragment>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel
                      className={
                        formClasses.labelHorizontal +
                        " " +
                        formClasses.labelHorizontalRadioCheckbox
                      }
                      style={{ float: "left" }}
                    >
                      {t("eol")}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={4} style={{ float: "left" }}>
                    <div className={formClasses.checkboxAndRadio}>
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => {
                              const currentIndex =
                                modalUpdateStoreForever.indexOf(1);
                              const newChecked = [...modalUpdateStoreForever];
                              if (currentIndex === -1) {
                                newChecked.push(1);
                              } else {
                                newChecked.splice(currentIndex, 1);
                              }
                              setModalUpdateStoreForever(newChecked);
                              if (newChecked.length > 0) {
                                setModalUpdateShareTTL("-1");
                              }
                            }}
                            checkedIcon={
                              <Check className={formClasses.checkedIcon} />
                            }
                            icon={
                              <Check className={formClasses.uncheckedIcon} />
                            }
                            classes={{
                              checked: formClasses.checked,
                              root: formClasses.checkRoot,
                            }}
                            checked={modalUpdateStoreForever.length > 0}
                            disabled={showModalProgress}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot,
                        }}
                        label={t("store-forever")}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <div className={formClasses.checkboxAndRadio}>
                      <FormControl fullWidth>
                        <Datetime
                          dateFormat={"YYYY-MM-DD"}
                          timeFormat={false}
                          inputProps={{
                            placeholder: t("select-date"),
                            disabled:
                              showModalProgress ||
                              modalUpdateStoreForever.length > 0,
                          }}
                          value={
                            modalUpdateShareTTL === "-1"
                              ? undefined
                              : modalUpdateShareTTL
                          }
                          onChange={(e) => {
                            setModalUpdateShareTTL(e);
                          }}
                          closeOnSelect
                        />
                      </FormControl>
                    </div>
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            )}

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModalAccessId}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("access-document-container")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleAccessDocumentContainer}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="cod-container-2-title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalAccessId,
                    onChange: (e) => {
                      setModalAccessId(e.target.value);
                    },
                  }}
                  helpText={t("id-of-the-document")}
                />
              </GridItem>
            </GridContainer>
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button type="submit" color="primary">
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <Bookmarks style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("my-document-containers")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  round
                  color="rose"
                  simple
                  className="remove"
                  onClick={handleRefresh}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="primary"
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                  style={{ marginTop: "20px" }}
                >
                  {t("add")}
                </Button>
                <Button
                  color="rose"
                  round
                  onClick={() => {
                    setConfirmModalButtonText(t("access"));
                    setShowModalAccessId(true);
                  }}
                  style={{ marginLeft: "5px", marginTop: "20px" }}
                >
                  {t("access")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {filters.length > 0 && (
                <React.Fragment>
                  <Button
                    color="primary"
                    round
                    className="remove"
                    onClick={() => {
                      handleFilterClicked();
                    }}
                  >
                    {t("filter")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    className="remove"
                    onClick={() => {
                      handleRefresh();
                    }}
                  >
                    {t("clear-filters")}
                  </Button>
                </React.Fragment>
              )}
              <ReactTable
                data={handleReturnData(documentContainers, userId)}
                sortable={true}
                multiSort={false}
                filterable={true}
                filtered={filters}
                columns={[
                  {
                    Header: t("id"),
                    accessor: "_id",
                  },
                  {
                    Header: t("title"),
                    accessor: "title",
                  },
                  {
                    Header: t("access"),
                    accessor: "access",
                  },
                  {
                    Header: t("status"),
                    accessor: "status",
                  },
                  {
                    Header: t("eol"),
                    accessor: "endOfLife",
                    filterable: false,
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {ApiService.formatDateTime(row.value)}
                      </div>
                    ),
                  },
                  {
                    Header: t("hash"),
                    accessor: "hash",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                manual
                pages={totalPages}
                page={currentPage}
                pageSize={pageSize}
                onPageChange={(pageIndex) => handlePageChange(pageIndex)} // Called when the page index is changed by the user
                onPageSizeChange={(pageSize, pageIndex) =>
                  handlePageSizeChange(pageSize, pageIndex)
                } // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
                onSortedChange={(newSorted, column, shiftKey) =>
                  handleSortChange(newSorted, column, shiftKey)
                }
                onFilteredChange={(filtered, column) =>
                  handleFilterColumn(filtered, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

DocumentContainers.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default DocumentContainers;
