import React from "react";
import PropTypes from "prop-types";

import Loading from "components/Loading/Loading.js";

import ApiService from "api/ApiService.js";

const RedirectPage = (props) => {
  React.useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      redirectPath: queryParams.get("redirectPath"),
      userId: queryParams.get("userId"),
      authToken: queryParams.get("authToken"),
    };
    if (!params.redirectPath) {
      params.redirectPath = "/admin/dashboard";
    }
    if (params.userId) {
      const formattedParams = new URLSearchParams({
        userId: params.userId,
      }).toString();
      params.redirectPath = `${params.redirectPath}?${formattedParams}`;
    }
    if (params.authToken) {
      ApiService.storeSessionToken(params.authToken);
    }
    props.history.push(params.redirectPath);
  }, []); // eslint-disable-line
  return <Loading />;
};

RedirectPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default RedirectPage;
