import React from "react";
import PropTypes from "prop-types";

// react component for creating dynamic tables
import ReactTable from "react-table";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShareIcon from "@material-ui/icons/Share";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CloudIcon from "@material-ui/icons/Cloud";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SweetAlert from "react-bootstrap-sweetalert";

import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

/*################
  # Form classes #
  ################*/

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";

import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Muted from "components/Typography/Muted.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MyFiles = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [showPublishDialog, setShowPublishDialog] = React.useState(false);
  const [showShareDialog, setShowShareDialog] = React.useState(false);
  const [selectedPackage, setSelectedPackage] = React.useState({});
  const [sharingRecipients, setSharingRecipients] = React.useState([]);
  const [canShare, setCanShare] = React.useState([1]);
  const [sharingTitle, setSharingTitle] = React.useState("");
  const [sharingMessage, setSharingMessage] = React.useState("");
  const [shareTTL, setShareTTL] = React.useState(-1);
  const [storeForever, setStoreForever] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [progressRuntime, setProgressRuntime] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [shareAbortController, setShareAbortController] =
    React.useState(undefined);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [confirmButtonText, setConfirmButtonText] = React.useState(t("share"));
  const [jobId, setJobId] = React.useState("");
  const [myFiles, setMyFiles] = React.useState([]);
  const [sharingUsers, setSharingUsers] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [pageTitle, setPageTitle] = React.useState(t("my-files"));
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [backLink, setBackLink] = React.useState(undefined);

  const [sendDataCount, setSendDataCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);

  const [refreshClicked, setRefreshClicked] = React.useState(false);
  const [userId, setUserId] = React.useState("");

  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const clearSharingState = () => {
    setShowPublishDialog(false);
    setShowShareDialog(false);
    setSharingRecipients([]);
    setCanShare([]);
    setSharingTitle("");
    setSharingMessage("");
    setShareTTL(-1);
    setStoreForever([]);
    setShowProgress(false);
    setProgress(0);
    setProgressRuntime(0);
    setMessage("");
    setSuccessMessage("");
    setShareAbortController(undefined);
    setConfirmDialog(false);
    setConfirmButtonText(t("share"));
    setJobId("");
  };

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId = userProfileData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    const parentId = queryParams.get("documentContainerId");
    if (parentId) {
      params.parentId = parentId;
      setBackLink(
        `/admin/document-container?${new URLSearchParams({
          userId: params.userId,
          documentContainerId: parentId,
        }).toString()}`
      );
      setPageTitle(t("document-container-files"));
    } else {
      setPageTitle(t("my-files"));
    }
    return params;
  };

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  const getAllUsers = async (signal = undefined) => {
    try {
      const { userId } = await getQueryParams(signal);
      const { address } = await ApiService.getPublicKey({ userId }, signal);
      const returnData = await ApiService.getAllUsers({}, signal);
      setSharingUsers(
        returnData.filter((entry) => {
          return entry.address !== address;
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const getPublicShareUser = async (signal = undefined) => {
    try {
      const publicShareUser = await ApiService.getPublicShareUser(signal);
      setSharingUsers([publicShareUser]);
      setSharingRecipients([publicShareUser.address]);
    } catch (e) {
      console.error(e);
    }
  };

  const handleReturnData = (data, userId) => {
    return data.map((elem) => {
      if (!elem.endOfLife) {
        elem.endOfLife = t("forever");
      }
      elem.blockchainStoreFormatted = elem.blockchainStore
        ? elem.blockchainStore.toString()
        : "false";
      elem.shareableFormatted = elem.shareable.toString();
      const queryParameters = new URLSearchParams({
        userId,
        data_id: elem.data_id,
        sender: elem.sender,
        receiver: elem.receiver,
        hash: elem.hash,
      }).toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("package-details-0")}>
            <Link to={`/admin/package?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <MoreVertIcon />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t("publish-file")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              disabled={!elem.shareable}
              onClick={async () => {
                setSelectedPackage(elem);
                setSharingTitle(elem.title);
                setCanShare([]);
                await getPublicShareUser();
                setShowPublishDialog(true);
                setConfirmButtonText(t("publish"));
                setShowShareDialog(true);
              }}
            >
              <CloudIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("share-file")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              disabled={!elem.shareable}
              onClick={async () => {
                setSelectedPackage(elem);
                setSharingTitle(elem.title);
                setCanShare(elem.shareable ? [1] : []);
                await getAllUsers();
                setShowPublishDialog(false);
                setConfirmButtonText(t("share"));
                setShowShareDialog(true);
              }}
            >
              <ShareIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("download-file")}>
            <Button
              justIcon
              round
              simple
              onClick={() => {
                showDownloadFile(elem);
              }}
              color="rose"
              className="remove"
            >
              <CloudDownloadIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("delete-file")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="remove"
              onClick={() => {
                showDeleteFile(elem);
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  React.useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      const parentId = queryParams.get("documentContainerId");
      if (parentId) {
        params.parentId = parentId;
        setBackLink(
          `/admin/document-container?${new URLSearchParams({
            userId: params.userId,
            documentContainerId: parentId,
          }).toString()}`
        );
        setPageTitle(t("document-container-files"));
      } else {
        setPageTitle(t("my-files"));
      }
      setUserId(params.userId);
      return params;
    };

    const getSendData = async (signal = undefined, queryParams) => {
      try {
        const { address } = await ApiService.getPublicKey(
          { userId: queryParams.userId },
          signal
        );

        const params = {
          userId: queryParams.userId,
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
          receiver: address,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        if (queryParams.parentId) {
          params.parentId = queryParams.parentId;
        }
        const returnData = await ApiService.getSendData(params, signal);
        setMyFiles(returnData);
        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        const { count: sendDataCount } = await ApiService.getSendDataCount(
          params,
          signal
        );
        setSendDataCount(sendDataCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const handleRefresh = async (signal = undefined) => {
      const queryParams = await getQueryParams(signal);
      await getSendData(signal, queryParams);
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh(signal, false);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
    t,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && sendDataCount > 0) {
      setTotalPages(Math.ceil(sendDataCount / pageSize));
    }
    return () => {
      isMounted = false;
    };
  }, [sendDataCount, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(sendDataCount / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }
    setCurrentPage(currentIndex);

    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted, column, shiftKey) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const showErrorMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setMessage(messageContent);
      setConfirmDialog(true);
      setConfirmButtonText(t("close"));
    } else {
      setMessage(messageContent);
      setTimeout(() => {
        setMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessMessage = (messageContent) => {
    setSuccessMessage(messageContent);
    setConfirmDialog(true);
    setConfirmButtonText(t("confirm"));
  };

  const handleShare = async (event) => {
    event.preventDefault();
    if (confirmDialog) {
      return clearSharingState();
    }

    const { userId } = await getQueryParams();
    const validRecipients = sharingRecipients.length > 0;
    const title = sharingTitle.trim();
    const validTitle = title.length > 0;
    const message_ = sharingMessage.trim();
    const validMessage = message_.length > 0;
    const ttl =
      shareTTL === -1
        ? -1
        : Math.ceil((shareTTL.endOf("day") - moment()) / 1000.0);
    const validTTL = !(
      (ttl === -1 && storeForever.length === 0) ||
      ttl < -1 ||
      ttl === 0
    );
    const checksPassed =
      validRecipients && validTitle && validMessage && validTTL;
    if (!checksPassed) {
      const messageContent = [];
      if (!validRecipients) {
        messageContent.push(t("define-one-or-more-recipients"));
      }
      if (!validTitle) {
        messageContent.push(t("name-length-should-be-greater-than-1"));
      }
      if (!validMessage) {
        messageContent.push(t("message-length-should-be-greater-than-1"));
      }
      if (!validTTL) {
        messageContent.push(t("invalid-eol-defined"));
      }
      return showErrorMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const params = {
      userId,
      data_id: selectedPackage.data_id,
      hash: selectedPackage.hash,
      sender: selectedPackage.sender,
      receiver: selectedPackage.receiver,
      shareable: (canShare.length > 0).toString(),
      receivers: JSON.stringify(sharingRecipients),
      ttl: ttl.toString(),
      title,
      message: message_,
      frontendUrl: ApiService.getShareFrontendUrl(),
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    setShareAbortController(abortController);

    // Set progress bar
    setShowProgress(true);
    setProgress(20);
    setProgressRuntime(0);
    setConfirmButtonText(t("processing"));

    // Share file
    let responseData;
    try {
      responseData = await ApiService.shareFile(params, signal);
    } catch (e) {
      return showErrorMessage(t("error-processing-file"));
    }
    const jobId = responseData.jobId;
    setJobId(jobId);
    setProgress(responseData.progress);
    setProgressRuntime(responseData.runtime);
    try {
      const jobParams = { jobId, userId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        setProgress(responseData.progress);
        setProgressRuntime(responseData.runtime);
        if (responseData.status === ApiService.jobStatus.failed) {
          return showErrorMessage(t("error-processing-file"));
        }
        if (
          responseData.status === ApiService.jobStatus.failedBlockchain ||
          responseData.status === ApiService.jobStatus.done
        ) {
          return showSuccessMessage(t("file-successfully-processed"));
        }
      }
      if (signal.aborted) {
        return showErrorMessage(t("operation-aborted"));
      }
    } catch (e) {
      return showErrorMessage(t("error-processing-file"));
    }
  };

  const deleteFileResult = (
    result,
    resultMessage,
    responseData = undefined
  ) => {
    const handleDeletionResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleDeletionResult}
          onCancel={handleDeletionResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {responseData && (
            <React.Fragment>
              <CustomLinearProgress
                variant="determinate"
                color="primary"
                value={responseData.progress}
              />
              <Muted>
                {responseData.jobId} - {responseData.progress}% (
                {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
              </Muted>
              <br />
            </React.Fragment>
          )}
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleDeletionResult}
          onCancel={handleDeletionResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {responseData && (
            <React.Fragment>
              <CustomLinearProgress
                variant="determinate"
                color="primary"
                value={responseData.progress}
              />
              <Muted>
                {responseData.jobId} - {responseData.progress}% (
                {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
              </Muted>
              <br />
            </React.Fragment>
          )}
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const downloadFileError = (resultMessage, responseData = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("error")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {responseData && (
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={responseData.progress}
            />
            <Muted>
              {responseData.jobId} - {responseData.progress}% (
              {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
            </Muted>
            <br />
          </React.Fragment>
        )}
        <Danger>{resultMessage}</Danger>
      </SweetAlert>
    );
  };

  const deleteFileAsync = (data) => {
    const handleFileDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        sender: data.sender,
        receiver: data.receiver,
        data_id: data.data_id,
        hash: data.hash,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.deleteFile(params, signal);
      } catch (e) {
        return deleteFileResult(false, t("error-deleting-file"));
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId, userId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            return deleteFileResult(
              false,
              t("error-deleting-file"),
              responseData
            );
          }
          if (
            responseData.status === ApiService.jobStatus.failedBlockchain ||
            responseData.status === ApiService.jobStatus.done
          ) {
            return deleteFileResult(
              true,
              t("file-successfully-deleted"),
              responseData
            );
          }
        }
        if (signal.aborted) {
          return deleteFileResult(false, t("operation-aborted"), responseData);
        }
      } catch (e) {
        return deleteFileResult(false, t("error-deleting-file"), responseData);
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-file")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleFileDeletion();
  };

  const downloadFileAsync = (data) => {
    const handleDownloadFile = async (userId, parameters, signal) => {
      const params = {
        userId,
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadFile(params, signal);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      setAlert(null);
      a.click();
    };
    const handlePrepareFileDownload = async () => {
      // Download parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        sender: data.sender,
        receiver: data.receiver,
        data_id: data.data_id,
        hash: data.hash,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.prepareDownloadFile(params, signal);
      } catch (e) {
        return downloadFileError(t("error-downloading-file"));
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId, userId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            return downloadFileError(t("error-downloading-file"), responseData);
          }
          if (
            responseData.status === ApiService.jobStatus.failedBlockchain ||
            responseData.status === ApiService.jobStatus.done
          ) {
            await handleDownloadFile(userId, responseData, signal);
          }
        }
        if (signal.aborted) {
          return downloadFileError(t("operation-aborted"), responseData);
        }
      } catch (e) {
        return downloadFileError(t("error-downloading-file"), responseData);
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("preparing-file")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePrepareFileDownload();
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const showDeleteFile = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-file")}?`}
        onConfirm={() => {
          deleteFileAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.title}</b>
        </p>
      </SweetAlert>
    );
  };

  const showDownloadFile = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-file")}?`}
        onConfirm={() => {
          downloadFileAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.title}</b>
        </p>
      </SweetAlert>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
            <CardIcon color="turq">
              <FileCopyIcon style={{ color: roseColor[0] }} />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {pageTitle} {formattedUserName}
            </h4>
            <div style={{ marginLeft: "auto" }}>
              {backLink && (
                <Button
                  justIcon
                  round
                  simple
                  className="remove"
                  onClick={() => {
                    props.history.push(backLink);
                  }}
                  style={{ marginTop: "20px" }}
                >
                  <KeyboardBackspaceIcon style={{ color: roseColor[0] }} />
                </Button>
              )}
              <Button
                color="rose"
                round
                simple
                className="remove"
                onClick={() => {
                  handleRefresh();
                }}
                style={{ marginTop: "28px" }}
              >
                {t("refresh")}
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {filters.length > 0 && (
              <React.Fragment>
                <Button
                  color="primary"
                  round
                  className="remove"
                  onClick={() => {
                    handleFilterClicked();
                  }}
                >
                  {t("filter")}
                </Button>
                <Button
                  color="rose"
                  round
                  simple
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                >
                  {t("clear-filters")}
                </Button>
              </React.Fragment>
            )}
            <ReactTable
              data={handleReturnData(myFiles, userId)}
              sortable={true}
              multiSort={false}
              filterable={true}
              filtered={filters}
              columns={[
                {
                  Header: t("title"),
                  accessor: "title",
                },
                {
                  Header: t("parent-id"),
                  accessor: "parentId",
                },
                {
                  Header: t("file-name"),
                  accessor: "fileName",
                },
                {
                  Header: t("file-format"),
                  accessor: "dataType",
                },
                {
                  Header: t("file-size"),
                  accessor: "fileSize",
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>{row.value}</div>
                  ),
                },
                {
                  Header: t("service"),
                  accessor: "storageService",
                },
                {
                  Header: t("blockchain-store"),
                  accessor: "blockchainStoreFormatted",
                },
                {
                  Header: t("eol"),
                  accessor: "endOfLife",
                  headerStyle: { textAlign: "right" },
                  filterable: false,
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>
                      {ApiService.formatDateTime(row.value)}
                    </div>
                  ),
                },
                {
                  Header: t("hash"),
                  accessor: "hash",
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>{row.value}</div>
                  ),
                },
                {
                  Header: t("txid"),
                  accessor: "transactionId",
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>{row.value}</div>
                  ),
                },
                {
                  Header: t("actions"),
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
              defaultPageSize={ApiService.defaultPageSize}
              manual
              pages={totalPages}
              page={currentPage}
              pageSize={pageSize}
              onPageChange={(pageIndex) => handlePageChange(pageIndex)} // Called when the page index is changed by the user
              onPageSizeChange={(pageSize, pageIndex) =>
                handlePageSizeChange(pageSize, pageIndex)
              } // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
              onSortedChange={(newSorted, column, shiftKey) =>
                handleSortChange(newSorted, column, shiftKey)
              } // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              onFilteredChange={(filtered, column) =>
                handleFilterColumn(filtered, column)
              }
            />
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        classes={{
          root: dialogClasses.center + " " + dialogClasses.modalRoot,
          paper: dialogClasses.modal,
        }}
        open={showShareDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearSharingState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {(showPublishDialog &&
              `${t("publish-file")} ${formattedUserName}`) ||
              `${t("share-file")} ${formattedUserName}`}
          </h4>
        </DialogTitle>
        <form onSubmit={handleShare}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            {!showPublishDialog && (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left", paddingTop: "40px" }}
                  >
                    {t("recipients")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    className={formClasses.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="multiple-select"
                      className={formClasses.selectLabel}
                    ></InputLabel>
                    <Select
                      multiple
                      value={sharingRecipients}
                      onChange={(e) => {
                        setSharingRecipients(e.target.value);
                      }}
                      MenuProps={{ className: formClasses.selectMenu }}
                      classes={{ select: formClasses.select }}
                      inputProps={{
                        name: "multipleSelect",
                        id: "recipients",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: formClasses.selectMenuItem,
                        }}
                      >
                        {t("recipients")}
                      </MenuItem>
                      {sharingUsers.map((entry) => {
                        return (
                          <MenuItem
                            key={entry.address}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={entry.address}
                          >
                            {entry.user.email}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={4} style={{ float: "left" }}>
                  <div
                    className={formClasses.checkboxAndRadio}
                    style={{ marginTop: "20px" }}
                  >
                    <FormControlLabel
                      style={{ float: "left" }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => {
                            const currentIndex = canShare.indexOf(1);
                            const newChecked = [...canShare];
                            if (currentIndex === -1) {
                              newChecked.push(1);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }
                            setCanShare(newChecked);
                          }}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot,
                          }}
                          checked={canShare.length > 0}
                          disabled={showProgress}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot,
                      }}
                      label={t("can-share")}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            )}

            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("title")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="myfiles-title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: sharingTitle,
                    disabled: showProgress,
                    onChange: (e) => {
                      setSharingTitle(e.target.value);
                    },
                  }}
                  helpText={t("title-of-document")}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("message")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="message"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: sharingMessage,
                    disabled: showProgress,
                    multiline: true,
                    onChange: (e) => {
                      setSharingMessage(e.target.value);
                    },
                  }}
                  helpText={t("message-of-document")}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                >
                  {t("eol")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={4} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = storeForever.indexOf(1);
                          const newChecked = [...storeForever];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setStoreForever(newChecked);
                          if (newChecked.length > 0) {
                            setShareTTL(-1);
                          }
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={storeForever.length > 0}
                        disabled={showProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("store-forever")}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"YYYY-MM-DD"}
                      timeFormat={false}
                      inputProps={{
                        placeholder: t("select-date"),
                        disabled: showProgress || storeForever.length > 0,
                      }}
                      value={shareTTL === -1 ? undefined : shareTTL}
                      onChange={(e) => {
                        setShareTTL(e);
                      }}
                      closeOnSelect
                    />
                  </FormControl>
                </div>
              </GridItem>
            </GridContainer>

            <br />
            {showProgress && (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={progress}
                      />
                      <Muted>
                        {jobId} - {progress}% (
                        {moment.utc(progressRuntime).format("HH:mm:ss.SSS")})
                      </Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {message && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{message}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                if (shareAbortController) {
                  shareAbortController.abort();
                }
                clearSharingState();
              }}
              disabled={confirmDialog}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showProgress && !confirmDialog}
            >
              {confirmButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {alert}
    </GridContainer>
  );
};

MyFiles.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default MyFiles;
