import React from "react";
import PropTypes from "prop-types";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput.js";
import Loading from "components/Loading/Loading.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import Muted from "components/Typography/Muted.js";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(sweetAlertStyles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AdminSystemConfiguration = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const alertClasses = useAlertStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();

  const [loading, setLoading] = React.useState(true);
  const [showConfig, setShowConfig] = React.useState(false);
  const [initialConfig, setInitialConfig] = React.useState({});
  const [systemConfig, setSystemConfig] = React.useState({});
  const [alert, setAlert] = React.useState(null);

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] =
    React.useState("Generate");

  // Dialog fields
  const [modalQRCodeEntry, setModalQRCodeEntry] = React.useState(
    "https://dpp.unisot.id/55/{{guid}}"
  );
  const [modalQRCodeTitle, setModalQRCodeTitle] = React.useState(
    "UNISOT Product Passport"
  );
  const [modalNumberOfQRCodes, setModalNumberOfQRCodes] = React.useState("10");

  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText("Generate");
    setModalQRCodeEntry("https://dpp.unisot.id/55/{{guid}}");
    setModalQRCodeTitle("UNISOT Product Passport");
    setModalNumberOfQRCodes("10");
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleGenerateQRCodes = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const qrCodeEntry = modalQRCodeEntry.trim();
    const title = modalQRCodeTitle.trim();
    const numberOfResults = modalNumberOfQRCodes.trim();

    const params = {
      qrCodeEntry,
      title,
      numberOfResults,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText("Generating");

    try {
      const responseData = await ApiService.downloadQRCodes(params);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = "qrCodes.zip";
      a.click();
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage("QR codes successfully generated");
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getSystemConfig = async (signal = undefined) => {
      try {
        const responseData = await ApiService.readSystemConfiguration(
          {},
          signal
        );
        setInitialConfig({ ...responseData });
        setSystemConfig(responseData);
        setShowConfig(true);
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal, true, true);
        setLoading(false);
        await getSystemConfig(signal);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [refreshClicked, t]);

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const operationResult = (result, resultMessage, refresh = true) => {
    const handleUpdateResult = async () => {
      if (refresh) {
        handleRefresh();
      }
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleUpdateSystemConfiguration = async (event) => {
    event.preventDefault();
    const requestData = {};
    const fieldNames = Object.keys(initialConfig);
    // eslint-disable-next-line no-unused-vars
    for (const fieldName of fieldNames) {
      const value = systemConfig[fieldName];
      if (typeof value !== undefined && value !== initialConfig[fieldName]) {
        requestData[fieldName] = value;
      }
    }
    try {
      const responseData = await ApiService.updateSystemConfiguration({
        config: requestData,
      });
      operationResult(true, responseData.message);
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const shutdownSystemService = async () => {
    setAlert(null);
    try {
      await ApiService.shutdownSystemService({});
    } catch (e) {
      if (e.message.toLowerCase().startsWith("failed to fetch")) {
        operationResult(true, t("restart-in-progress"));
      } else {
        operationResult(false, e.message);
      }
    }
  };

  const handleShutdownSystemService = () => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("restart-system-service-0")}
        onConfirm={() => {
          shutdownSystemService();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      />
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{"Generate QR codes"}</h4>
        </DialogTitle>
        <form onSubmit={handleGenerateQRCodes}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {"QR code entry"}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="qr-code-entry"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalQRCodeEntry,
                    required: true,
                    onChange: (e) => {
                      setModalQRCodeEntry(e.target.value);
                    },
                  }}
                  helpText={"QR code entry"}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {"QR code title"}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="qr-code-title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalQRCodeTitle,
                    required: true,
                    onChange: (e) => {
                      setModalQRCodeTitle(e.target.value);
                    },
                  }}
                  helpText={"QR code title"}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {"Number of QR codes"}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="user-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalNumberOfQRCodes,
                    required: true,
                    onChange: (e) => {
                      setModalNumberOfQRCodes(e.target.value);
                    },
                  }}
                  helpText={"Number of QR codes"}
                />
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <GridItem xs={6} style={{ textAlign: "left" }}>
          <Button
            simple
            color="rose"
            className="remove"
            onClick={() => {
              setShowModal(true);
            }}
            style={{ marginTop: "28px" }}
          >
            {"Generate QR codes"}
          </Button>
        </GridItem>
        {showConfig && (
          <GridItem xs={6} style={{ textAlign: "right" }}>
            <Button
              simple
              color="rose"
              className="remove"
              onClick={() => {
                handleRefresh();
              }}
              style={{ marginTop: "28px" }}
            >
              {t("refresh")}
            </Button>
          </GridItem>
        )}
      </GridContainer>
      {showConfig && (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardBody>
                  <h3>{t("system-configuration")}</h3>
                  <br />
                  <form onSubmit={handleUpdateSystemConfiguration}>
                    {Object.keys(systemConfig)
                      .sort()
                      .map((fieldName) => {
                        return (
                          <GridContainer key={fieldName}>
                            <GridItem xs={12}>
                              <CustomInput
                                labelText={fieldName}
                                id={fieldName}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => {
                                    const newConfig = { ...systemConfig };
                                    newConfig[fieldName] = e.target.value;
                                    setSystemConfig(newConfig);
                                  },
                                  value: systemConfig[fieldName],
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        );
                      })}
                    <Button
                      color="primary"
                      type="submit"
                      className={classes.updateProfileButton}
                    >
                      {t("edit")}
                    </Button>
                  </form>
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12}>
              <Card>
                <CardBody>
                  <h3>{t("restart-system-service")}</h3>
                  <br />
                  <GridContainer
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                  >
                    <GridItem xs={12} style={{ textAlign: "right" }}>
                      <Button
                        color="primary"
                        style={{ marginTop: "20px" }}
                        round
                        onClick={() => {
                          handleShutdownSystemService();
                        }}
                      >
                        {t("execute")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      )}
      {alert}
    </div>
  );
};

AdminSystemConfiguration.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AdminSystemConfiguration;
