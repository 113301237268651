import i18n from "i18next";
import ApiService from "api/ApiService";
import Dashboard from "views/Dashboard/Dashboard.js";
import RedirectPage from "views/Pages/RedirectPage";
import ErrorPage from "views/Pages/ErrorPage.js";
import PricingPage from "views/Pages/PricingPage.js";
import JobInspector from "views/Pages/JobInspector.js";
import MyFiles from "views/Pages/MyFiles.js";
import PackageDetails from "views/Pages/PackageDetails.js";
import ShareItemDetails from "views/Pages/ShareItemDetails.js";
import SharedPackages from "views/Pages/SharedPackages.js";
import PendingPackages from "views/Pages/PendingPackages.js";
import AcceptedPackages from "views/Pages/AcceptedPackages.js";
import RejectedPackages from "views/Pages/RejectedPackages.js";
import PublishedPackages from "views/Pages/PublishedPackages.js";
import PublicSharedPackage from "views/Pages/PublicSharedPackage.js";
import ShareItems from "views/Pages/ShareItems.js";
import DocumentContainers from "views/Pages/DocumentContainers.js";
import SharedAccessPackages from "views/Pages/SharedAccessPackages";
import SharedDocumentContainers from "views/Pages/SharedDocumentContainers.js";
import DocumentContainerDetails from "views/Pages/DocumentContainerDetails.js";
import Lookups from "views/Pages/Lookups.js";
import AdminSystemConfiguration from "views/Pages/AdminSystemConfiguration.js";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import ForwardIcon from "@material-ui/icons/Forward";
import Image from "@material-ui/icons/Image";
import DescriptionIcon from "@material-ui/icons/Description";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PeopleIcon from "@material-ui/icons/People";
import CloudIcon from "@material-ui/icons/Cloud";
import Assignment from "@material-ui/icons/Assignment";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import Public from "@material-ui/icons/Public";
import Bookmarks from "@material-ui/icons/Bookmarks";

let currentLanguage;
let renderedRoutes;

const getNavigationRoutes = () => {
  if (i18n.language !== currentLanguage) {
    currentLanguage = i18n.language;
    renderedRoutes = [
      {
        path: "/dashboard",
        name: i18n.t("dashboard"),
        rtlName: i18n.t("dashboard"),
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/admin",
        capability: ApiService.capabilities.sdcDashboardView,
      },
      {
        path: "/my-files",
        name: i18n.t("my-files"),
        rtlName: i18n.t("my-files"),
        icon: FileCopyIcon,
        component: MyFiles,
        layout: "/admin",
        capability: ApiService.capabilities.sdcMyFilesView,
      },
      {
        path: "/shared",
        name: i18n.t("shared"),
        rtlName: i18n.t("shared"),
        icon: PeopleIcon,
        component: SharedPackages,
        layout: "/admin",
        capability: ApiService.capabilities.sdcSharedPackagesView,
      },
      {
        path: "/published",
        name: i18n.t("published"),
        rtlName: i18n.t("published"),
        icon: CloudIcon,
        component: PublishedPackages,
        layout: "/admin",
        capability: ApiService.capabilities.sdcPublishedPackagesView,
      },
      {
        collapse: true,
        name: i18n.t("received"),
        rtlName: i18n.t("received"),
        icon: PlaylistAddCheckIcon,
        state: "pageCollapse",
        capability: ApiService.capabilities.sdcReceivedPackagesView,
        views: [
          {
            path: "/pending",
            name: i18n.t("pending"),
            rtlName: i18n.t("pending"),
            mini: "P",
            rtlMini: "P",
            component: PendingPackages,
            layout: "/admin",
            capability: ApiService.capabilities.sdcPendingPackagesView,
          },
          {
            path: "/accepted",
            name: i18n.t("accepted"),
            rtlName: i18n.t("accepted"),
            mini: "A",
            rtlMini: "A",
            component: AcceptedPackages,
            layout: "/admin",
            capability: ApiService.capabilities.sdcAcceptedPackagesView,
          },
          {
            path: "/rejected",
            name: i18n.t("rejected"),
            rtlName: i18n.t("rejected"),
            mini: "R",
            rtlMini: "R",
            component: RejectedPackages,
            layout: "/admin",
            capability: ApiService.capabilities.sdcRejectedPackagesView,
          },
          {
            path: "/shared-packages",
            name: i18n.t("shared-with-me"),
            rtlName: i18n.t("shared-with-me"),
            mini: "SP",
            rtlMini: "SP",
            component: SharedAccessPackages,
            layout: "/admin",
            capability: ApiService.capabilities.sdcSharedAccessPackagesView,
          },
        ],
      },
      {
        hidden: true,
        collapse: true,
        name: i18n.t("pages"),
        rtlName: i18n.t("pages"),
        icon: Image,
        state: "pagesPageCollapse",
        views: [
          {
            path: "/error-page",
            name: i18n.t("error-page"),
            rtlName: i18n.t("error-page"),
            mini: "E",
            rtlMini: "E",
            component: ErrorPage,
            layout: "/auth",
          },
          {
            path: "/public-shared-package/",
            name: i18n.t("public-shared-package"),
            rtlName: i18n.t("public-shared-package"),
            mini: "PS",
            rtlMini: "PS",
            component: PublicSharedPackage,
            layout: "/auth",
          },
          {
            path: "/redirect-page",
            name: i18n.t("redirect-page"),
            rtlName: i18n.t("redirect-page"),
            icon: ForwardIcon,
            component: RedirectPage,
            layout: "/auth",
          },
        ],
      },
      {
        path: "/share-items",
        name: i18n.t("shared-items"),
        rtlName: i18n.t("shared-items"),
        icon: Public,
        component: ShareItems,
        layout: "/admin",
        capability: ApiService.capabilities.sdcSharedItemsView,
      },
      {
        collapse: true,
        name: i18n.t("containers"),
        rtlName: i18n.t("containers"),
        icon: Bookmarks,
        state: "containersPageCollapse",
        capability: ApiService.capabilities.sdcContainersView,
        views: [
          {
            path: "/document-containers",
            name: i18n.t("my-containers"),
            rtlName: i18n.t("my-containers"),
            mini: "D",
            rtlMini: "D",
            component: DocumentContainers,
            layout: "/admin",
            capability: ApiService.capabilities.sdcMyContainersView,
          },
          {
            path: "/shared-containers",
            name: i18n.t("shared-with-me"),
            rtlName: i18n.t("shared-with-me"),
            mini: "SD",
            rtlMini: "SD",
            component: SharedDocumentContainers,
            layout: "/admin",
            capability: ApiService.capabilities.sdcSharedContainersView,
          },
        ],
      },
      {
        path: "/lookups",
        name: i18n.t("lookups"),
        rtlName: i18n.t("lookups"),
        icon: FindInPageIcon,
        component: Lookups,
        layout: "/admin",
        capability: ApiService.capabilities.sdcLookupsView,
      },
      {
        path: "/job-inspector",
        name: i18n.t("jobs"),
        rtlName: i18n.t("jobs"),
        icon: Assignment,
        component: JobInspector,
        layout: "/admin",
        capability: ApiService.capabilities.sdcJobsView,
      },
      {
        path: "/pricing-page",
        name: i18n.t("pricing"),
        rtlName: i18n.t("pricing"),
        mini: "P",
        rtlMini: "P",
        component: PricingPage,
        layout: "/auth",
        capability: ApiService.capabilities.sdcPricingView,
      },
      {
        admin: true,
        path: "/admin-system-configuration",
        name: i18n.t("system-configuration"),
        rtlName: i18n.t("system-configuration"),
        mini: "SC",
        rtlMini: "SC",
        component: AdminSystemConfiguration,
        layout: "/admin",
      },
      {
        hidden: true,
        path: "/package",
        name: i18n.t("package-details"),
        rtlName: i18n.t("package-details"),
        icon: DescriptionIcon,
        component: PackageDetails,
        layout: "/admin",
        capability: ApiService.capabilities.sdcPackageDetailsView,
      },
      {
        hidden: true,
        path: "/share-item",
        name: i18n.t("share-item-details-0"),
        rtlName: i18n.t("share-item-details-0"),
        icon: DescriptionIcon,
        component: ShareItemDetails,
        layout: "/admin",
        capability: ApiService.capabilities.sdcPackageDetailsView,
      },
      {
        hidden: true,
        path: "/document-container",
        name: i18n.t("document-container-details"),
        rtlName: i18n.t("document-container-details"),
        icon: DescriptionIcon,
        component: DocumentContainerDetails,
        layout: "/admin",
        capability: ApiService.capabilities.sdcContainerDetailsView,
      },
    ];
  }
  return renderedRoutes;
};

export default getNavigationRoutes;
