import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import Danger from "components/Typography/Danger.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Bookmarks from "@material-ui/icons/Bookmarks";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "components/CustomInput/CustomInput.js";

import { Link } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SharedDocumentContainers = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();

  const [loading, setLoading] = React.useState(true);
  const [documentContainers, setDocumentContainers] = React.useState([]);
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [userId, setUserId] = React.useState("");

  // Modal states
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("access")
  );

  // Dialog fields
  const [showModalAccessId, setShowModalAccessId] = React.useState(false);
  const [modalAccessId, setModalAccessId] = React.useState("");

  const [containerCount, setContainerCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);

  const [sortBy, setSortBy] = React.useState("createdAt:desc");
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId = userProfileData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const clearModalState = () => {
    setErrorModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("access"));
    setShowModalAccessId(false);
    setModalAccessId("");
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const handleReturnData = (data, userId) => {
    return data.map((elem) => {
      if (!elem.endOfLife) {
        elem.endOfLife = t("forever");
      }
      const queryParameters = new URLSearchParams({
        userId,
        documentContainerId: elem._id,
      }).toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("document-container-details-0")}>
            <Link to={`/admin/document-container?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <MoreVertIcon />
              </Button>
            </Link>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleAccessDocumentContainer = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      await handleRefresh();
      return clearModalState();
    }
    const accessId = modalAccessId.trim();
    const checksPassed = accessId.length === 24;
    if (!checksPassed) {
      return showErrorModalMessage(t("invalid-document-container-id"));
    }
    const queryParams = await getQueryParams();
    const params = {
      userId: queryParams.userId,
      documentContainerId: accessId,
    };
    const queryParameters = new URLSearchParams(params).toString();
    props.history.push(`/admin/document-container?${queryParameters}`);
  };

  const handleRefresh = async () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      setUserId(params.userId);
      return params;
    };

    const getDocumentContainers = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          parentType: ApiService.parentTypes.container,
          populateParent: "true",
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        const responseData = await ApiService.getAccessRights(params, signal);
        setDocumentContainers(responseData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        const { count: accessRightsCount } =
          await ApiService.getAccessRightsCount(params, signal);
        setContainerCount(accessRightsCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const handleRefresh = async () => {
      try {
        await getDocumentContainers();
      } catch (e) {
        console.error(e);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && containerCount > 0) {
      setTotalPages(Math.ceil(containerCount / pageSize));
    }
    return () => {
      isMounted = false;
    };
  }, [containerCount, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(containerCount / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }

    setCurrentPage(currentIndex);
    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted, column, shiftKey) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModalAccessId}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("access-document-container")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleAccessDocumentContainer}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalAccessId,
                    onChange: (e) => {
                      setModalAccessId(e.target.value);
                    },
                  }}
                  helpText={t("id-of-the-document")}
                />
              </GridItem>
            </GridContainer>
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button type="submit" color="primary">
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <Bookmarks style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("shared-with-me-document-containers")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  round
                  color="rose"
                  simple
                  className="remove"
                  onClick={handleRefresh}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="rose"
                  round
                  onClick={() => {
                    setShowModalAccessId(true);
                  }}
                  style={{ marginLeft: "5px", marginTop: "20px" }}
                >
                  {t("access")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {filters.length > 0 && (
                <React.Fragment>
                  <Button
                    color="primary"
                    round
                    className="remove"
                    onClick={() => {
                      handleFilterClicked();
                    }}
                  >
                    {t("filter")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    className="remove"
                    onClick={() => {
                      handleRefresh();
                    }}
                  >
                    {t("clear-filters")}
                  </Button>
                </React.Fragment>
              )}
              <ReactTable
                data={handleReturnData(documentContainers, userId)}
                sortable={true}
                multiSort={false}
                filterable={true}
                filtered={filters}
                columns={[
                  {
                    Header: t("id"),
                    accessor: "_id",
                    filterable: false,
                  },
                  {
                    Header: t("title"),
                    accessor: "title",
                  },
                  {
                    Header: t("access"),
                    accessor: "access",
                  },
                  {
                    Header: t("status"),
                    accessor: "status",
                  },
                  {
                    Header: t("eol"),
                    accessor: "endOfLife",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {ApiService.formatDateTime(row.value)}
                      </div>
                    ),
                    filterable: false,
                  },
                  {
                    Header: t("hash"),
                    accessor: "hash",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                manual
                pages={totalPages}
                page={currentPage}
                pageSize={pageSize}
                onPageChange={(pageIndex) => handlePageChange(pageIndex)} // Called when the page index is changed by the user
                onPageSizeChange={(pageSize, pageIndex) =>
                  handlePageSizeChange(pageSize, pageIndex)
                } // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
                onSortedChange={(newSorted, column, shiftKey) =>
                  handleSortChange(newSorted, column, shiftKey)
                }
                onFilteredChange={(filtered, column) =>
                  handleFilterColumn(filtered, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

SharedDocumentContainers.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default SharedDocumentContainers;
