import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CloudIcon from "@material-ui/icons/Cloud";
import Public from "@material-ui/icons/Public";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Loading from "components/Loading/Loading.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);

const Dashboard = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [myFiles, setMyFiles] = React.useState([]);
  const [shared, setShared] = React.useState(0);
  const [published, setPublished] = React.useState(0);
  const [pending, setPending] = React.useState(0);
  const [sharedItems, setSharedItems] = React.useState(0);
  const [refreshDisabled, setRefreshDisabled] = React.useState(false);
  const [query, setQuery] = React.useState({});
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getReceivedData = async (signal = undefined, userId, address) => {
      try {
        const { count: pendingCount } = await ApiService.getReceivedDataCount(
          {
            userId,
            state: ApiService.packageState.pending,
            excludeSenders: JSON.stringify([address]),
          },
          signal
        );
        setPending(pendingCount);
      } catch (e) {
        console.error(e);
      }
    };

    const getSendData = async (
      signal = undefined,
      userId,
      address,
      publicAddress
    ) => {
      try {
        const myFiles = await ApiService.getSendData(
          {
            userId,
            receiver: address,
            sortBy: "createdAt:desc",
            fromEntry: 0,
            numberOfResults: ApiService.defaultPageSize,
          },
          signal
        );
        const { count: sharedCount } = await ApiService.getSendDataCount(
          {
            userId,
            excludeReceivers: JSON.stringify([address, publicAddress]),
          },
          signal
        );
        const { count: publishedCount } = await ApiService.getSendDataCount(
          {
            userId,
            receiver: publicAddress,
          },
          signal
        );
        setMyFiles(myFiles);
        setShared(sharedCount);
        setPublished(publishedCount);
      } catch (e) {
        console.error(e);
      }
    };

    const getSharedItems = async (signal = undefined, userId) => {
      try {
        const { count: sharedItemsCount } =
          await ApiService.getSharedItemsCount(
            {
              userId,
              active: "true",
            },
            signal
          );
        setSharedItems(sharedItemsCount);
      } catch (e) {
        console.error(e);
      }
    };

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, true);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      params.ownAccount = returnData._id === params.userId;
      return params;
    };

    const handleRefresh = async (signal = undefined, disableButtons = true) => {
      if (disableButtons) {
        setRefreshDisabled(true);
      }
      const queryParams = await getQueryParams(signal);
      setQuery(queryParams);
      const { address } = await ApiService.getPublicKey(
        { userId: queryParams.userId },
        signal
      );
      const { address: publicAddress } = await ApiService.getPublicShareUser(
        signal
      );
      await getSendData(signal, queryParams.userId, address, publicAddress);
      await getReceivedData(signal, queryParams.userId, address);
      await getSharedItems(signal, queryParams.userId);
      if (disableButtons) {
        setRefreshDisabled(false);
      }
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh(signal, false);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, [props.location.search, refreshClicked]);

  const redirectToView = (viewLink) => {
    const queryParameters = new URLSearchParams({
      userId: query.userId,
    }).toString();
    props.history.push(`${viewLink}?${queryParameters}`);
  };

  const classes = useStyles();
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      {query.superuser && !query.ownAccount && (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
                <CardIcon color="turq">
                  <InsertLinkIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t("view-links")}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer justifyContent="center">
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/my-files")}
                  >
                    {t("my-files")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/shared")}
                  >
                    {t("shared")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/published")}
                  >
                    {t("published")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/pending")}
                  >
                    {t("pending")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/accepted")}
                  >
                    {t("accepted")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/rejected")}
                  >
                    {t("rejected")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/shared-packages")}
                  >
                    {t("packages-shared-with-me")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/share-items")}
                  >
                    {t("shared-items")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/document-containers")}
                  >
                    {t("my-containers")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/shared-containers")}
                  >
                    {t("containers-shared-with-me")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/lookups")}
                  >
                    {t("lookups")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/job-inspector")}
                  >
                    {t("jobs")}
                  </Button>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Link
            to="/admin/shared"
            onClick={(e) => {
              e.preventDefault();
              redirectToView("/admin/shared");
            }}
          >
            <Card>
              <CardHeader color="turq" stats icon>
                <CardIcon color="turq">
                  <PeopleIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <p className={classes.cardCategory}>{t("shared-packages")}</p>
                <h3 className={classes.cardTitle}>{shared}</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Link
            to="/admin/published"
            onClick={(e) => {
              e.preventDefault();
              redirectToView("/admin/published");
            }}
          >
            <Card>
              <CardHeader color="turq" stats icon>
                <CardIcon color="turq">
                  <CloudIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {t("published-packages")}
                </p>
                <h3 className={classes.cardTitle}>{published}</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Link
            to="/admin/pending"
            onClick={(e) => {
              e.preventDefault();
              redirectToView("/admin/pending");
            }}
          >
            <Card>
              <CardHeader color="turq" stats icon>
                <CardIcon color="turq">
                  <AssignmentLateIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <p className={classes.cardCategory}>{t("pending-packages")}</p>
                <h3 className={classes.cardTitle}>{pending}</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Link
            to="/admin/share-items"
            onClick={(e) => {
              e.preventDefault();
              redirectToView("/admin/share-items");
            }}
          >
            <Card>
              <CardHeader color="turq" stats icon>
                <CardIcon color="turq">
                  <Public style={{ color: roseColor[0] }} />
                </CardIcon>
                <p className={classes.cardCategory}>{t("shared-items")}</p>
                <h3 className={classes.cardTitle}>{sharedItems}</h3>
              </CardHeader>
              <CardFooter stats></CardFooter>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <FileCopyIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("recent-uploads")} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  round
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                  disabled={refreshDisabled}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={myFiles.map((elem) => {
                  const queryParameters = new URLSearchParams({
                    userId: query.userId,
                    data_id: elem.data_id,
                    sender: elem.sender,
                    receiver: elem.receiver,
                    hash: elem.hash,
                  }).toString();
                  return {
                    title: elem.title,
                    fileName: elem.fileName,
                    dataType: elem.dataType,
                    fileSize: elem.fileSize,
                    storageService: elem.storageService,
                    blockchainStoreFormatted: elem.blockchainStore
                      ? elem.blockchainStore.toString()
                      : "false",
                    endOfLife: ApiService.formatDateTime(elem.endOfLife),
                    hash: elem.hash,
                    transactionId: elem.transactionId,
                    actions: (
                      <div className="actions-right">
                        <Tooltip title={t("package-details-0")}>
                          <Link to={`/admin/package?${queryParameters}`}>
                            <Button
                              justIcon
                              round
                              simple
                              color="rose"
                              className="like"
                            >
                              <MoreVertIcon />
                            </Button>
                          </Link>
                        </Tooltip>
                      </div>
                    ),
                  };
                })}
                filterable
                columns={[
                  {
                    Header: t("title"),
                    accessor: "title",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: t("file-name"),
                    accessor: "fileName",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: t("file-format"),
                    accessor: "dataType",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: t("file-size"),
                    accessor: "fileSize",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: t("service"),
                    accessor: "storageService",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: t("blockchain-store"),
                    accessor: "blockchainStoreFormatted",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: t("eol"),
                    accessor: "endOfLife",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: t("hash"),
                    accessor: "hash",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: t("txid"),
                    accessor: "transactionId",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPagination={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

Dashboard.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Dashboard;
