/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import i18n from "i18next";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

import Button from "components/CustomButtons/Button";
import AddIcon from '@material-ui/icons/Add';
import { Link } from "react-router-dom";

var ps;

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

/*################
  # Form classes #
  ################*/

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Muted from "components/Typography/Muted.js";

import ApiService from "api/ApiService.js";

import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import combineStyles from "utils/CombineStyles.js";
import defaultImage from "assets/img/user.jpg";
import { grayColor, roseColor } from "assets/jss/material-dashboard-pro-react";

const clearFileUploadState = {
  showFileUploadDialog: false,
  parentId: undefined,
  fileTitle: "",
  fileTitleInputActive: false,
  fileTTL: moment().add(ApiService.defaultStorageDays, 'days'),
  storageService: ApiService.packageStorageService.cassandra,
  storeForever: [],
  selectedFile: undefined,
  pricing: {},
  showProgress: false,
  progress: 0,
  progressRuntime: 0,
  message: "",
  successMessage: "",
  abortController: undefined,
  confirmDialog: false,
  confirmButtonText: "",
  jobId: "",
  superuser: "",
  userId: "",
  formattedUserName: ""
};

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
        <div className={className} ref={this.sidebarWrapper}>
          {user}
          {headerLinks}
          {links}
        </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    clearFileUploadState.confirmButtonText = i18n.t("upload");
    this.state = {
      openAvatar: false,
      miniActive: true,
      userProfile: {},
      pricingPerOperation: false,
      userAvatar: defaultImage,
      ...this.getCollapseStates(props.routes),
      ...clearFileUploadState
    };
    this.fileInput = React.createRef();
    ApiService.loginRequired(
      undefined,
      false
    ).then((userProfile) => {
      const pricingPerOperation = ApiService.getPricingPerOperationActive(
        userProfile
      );
      const stateUpdate = { 
        userProfile,
        pricingPerOperation
      };
      ApiService.getUserDocuments({ userId: userProfile._id }).then((responseData) => {
        const documentData = responseData.find(
          entry => entry.type === ApiService.documentTypes.avatar
        );
        if (documentData) {
          ApiService.readUserDocument(
            {
              userId: userProfile._id,
              documentCriteria: ApiService.documentCriteria.documentType,
              documentFilter: ApiService.documentTypes.avatar
            }
          ).then((userAvatar) => {
            this.setState({
              userAvatar: `data:image/png;base64, ${userAvatar.contents}`
            });
          }).catch((e) => {
            console.error(e);
          });
        }
      }).catch((e) => {
        console.error(e);
      });
      this.setState(stateUpdate);
    }).catch((e) => {
      console.error(e);
    });
  }
  mainPanel = React.createRef();

  getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const params = {
      userId: queryParams.get("userId")
    };
    const returnData = await ApiService.loginRequired(
      signal,
      false
    );
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      this.setState({
        formattedUserName: ApiService.getFormattedUserName(returnData._id, userProfileData)
      });
      params.userId = userProfileData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    params.parentId = queryParams.get("documentContainerId");
    return params;
  };

  showErrorMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      this.setState({
        message: messageContent,
        confirmDialog: true,
        confirmButtonText: i18n.t("close")
      });
    } else {
      this.setState({
        message: messageContent
      });
      setTimeout(() => {
        this.setState({ message: "" });
      }, ApiService.messageTimeout);
    }
  };

  showSuccessMessage = messageContent => {
    this.setState({
      successMessage: messageContent,
      confirmDialog: true,
      confirmButtonText: i18n.t("confirm")
    });
  };

  getPricingPerOperation = async (selectedFile, selectedFileTTL, selectedStoreForever, storageType) => {
    try {
      const fileTTL = (selectedFileTTL === -1) ? -1 : Math.ceil((selectedFileTTL.endOf('day') - moment()) / 1000.0);
      const validFileTTL = !((selectedFileTTL === -1 && selectedStoreForever.length === 0) || fileTTL < -1 || fileTTL === 0);
      if (!selectedFile || !validFileTTL) {
        return {};
      }
      return await ApiService.getPricingPerOperation({
        fileSizeBytes: selectedFile.size,
        ttl: fileTTL.toString(),
        encoding: ApiService.storageFormat,
        storageService: 'package',
        storageType
      });
    } catch (e) {
      return {};
    }
  };

  handleFileUpload = async event => {
    event.preventDefault();
    if (this.state.confirmDialog) {
      this.fileInput.current.value = "";
      this.setState(clearFileUploadState);
      return;
    }

    const fileTitle = this.state.fileTitle.trim();
    const validFileTitle = fileTitle.length > 0;
    const selectedFile = this.state.selectedFile;
    const fileTTL = (this.state.fileTTL === -1) ? -1 : Math.ceil((this.state.fileTTL.endOf('day') - moment()) / 1000.0);
    const validFileTTL = !((this.state.fileTTL === -1 && this.state.storeForever.length === 0) || fileTTL < -1 || fileTTL === 0);
    const checksPassed = validFileTitle && selectedFile && validFileTTL;
    if (!checksPassed) {
      const messageContent = [];
      if (!validFileTitle) {
        messageContent.push(i18n.t("name-length-should-be-greater-than-1"));
      }
      if (!selectedFile) {
        messageContent.push(i18n.t("upload-file-not-selected"));
      }
      if (!validFileTTL) {
        messageContent.push(i18n.t("invalid-file-eol-defined"));
      }
      this.showErrorMessage(`${i18n.t("invalid-information-provided")}: ${messageContent.join(", ")}.`, false);
      return;
    }
    const params = {
      file: selectedFile,
      shareable: "true",
      ttl: fileTTL.toString(),
      storageService: this.state.storageService,
      encoding: ApiService.storageFormat,
      title: fileTitle,
      message: i18n.t("file-upload"),
      frontendUrl: ApiService.getShareFrontendUrl()
    };
    if (this.state.superuser) {
      params.userId = this.state.userId;
    }
    if (this.state.parentId) {
      params.parentId = this.state.parentId;
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    this.setState({ abortController });

    try {
      const keyParams = {};
      if (this.state.superuser) {
        keyParams.userId = this.state.userId;
      }
      const { address } = await ApiService.getPublicKey(keyParams, signal);
      params.receivers = JSON.stringify([address]);
    } catch (e) {
      this.showErrorMessage(i18n.t("error-fetching-user-data"));
      return;
    }

    // Set progress bar
    this.setState({
      showProgress: true,
      progress: 20,
      progressRuntime: 0,
      confirmButtonText: i18n.t("uploading")
    });

    // Upload file to server
    let responseData;
    try {
      responseData = await ApiService.uploadFile(params, signal);
    } catch (e) {
      this.showErrorMessage(i18n.t("error-uploading-file"));
      return;
    }
    const jobId = responseData.jobId;
    this.setState({
      jobId,
      progress: responseData.progress,
      progressRuntime: responseData.runtime
    });
    try {
      const jobParams = { jobId };
      if (this.state.superuser) {
        jobParams.userId = this.state.userId;
      }
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        this.setState({
          progress: responseData.progress,
          progressRuntime: responseData.runtime
        });
        if (responseData.status === ApiService.jobStatus.failed) {
          this.showErrorMessage(i18n.t("error-uploading-file"));
          return;
        }
        if (
          responseData.status === ApiService.jobStatus.failedBlockchain || 
          responseData.status === ApiService.jobStatus.done
        ) {
          this.showSuccessMessage(i18n.t("file-successfully-uploaded-and-stored"));
          return;
        }
      }
      if (signal.aborted) {
        this.showErrorMessage(i18n.t("operation-aborted"));
        return;
      }
    } catch (e) {
      this.showErrorMessage(i18n.t("error-uploading-file"));
      return;
    }
  };

  handleShowFileUploadDialog = () => {
    if (ApiService.isUserValidated(this.state.userProfile)) {
      this.getQueryParams().then(params => {
        this.setState({
          superuser: params.superuser,
          userId: params.userId,
          parentId: params.parentId,
          showFileUploadDialog: true
        });
      })
    }
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { classes, color, rtlActive } = this.props;
    return routes.map((prop, key) => {
      if (prop.admin) {
        prop.hidden = !(this.state.userProfile.superuser === true);
      }
      if (!ApiService.isCapable(this.state.userProfile, prop.capability)) {
        if (!(this.state.userProfile.superuser === true))
          return null;
      }
      if(prop.hidden) {
        return null;
      }
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes.collapseActive]: this.getCollapseInitialState(
                  prop.views
              )
            });
        const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive
            });
        const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
              [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
              [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.collapseItemTextRTL]: rtlActive
            });
        const itemIcon =
            classes.itemIcon +
            " " +
            cx({
              [classes.itemIconRTL]: rtlActive
            });
        const caret =
            classes.caret +
            " " +
            cx({
              [classes.caretRTL]: rtlActive
            });
        const collapseItemMini =
            classes.collapseItemMini +
            " " +
            cx({
              [classes.collapseItemMiniRTL]: rtlActive
            });
        return (
            <ListItem
                key={key}
                className={cx(
                    { [classes.item]: prop.icon !== undefined },
                    { [classes.collapseItem]: prop.icon === undefined }
                )}
            >
              <NavLink
                  to={"#"}
                  className={navLinkClasses}
                  onClick={e => {
                    e.preventDefault();
                    this.setState(st);
                  }}
              >
                {prop.icon !== undefined ? (
                    typeof prop.icon === "string" ? (
                        <Icon className={itemIcon}>{prop.icon}</Icon>
                    ) : (
                        <prop.icon className={itemIcon} />
                    )
                ) : (
                    <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
                )}
                <ListItemText
                    primary={rtlActive ? prop.rtlName : prop.name}
                    secondary={
                      <b
                          className={
                            caret +
                            " " +
                            (this.state[prop.state] ? classes.caretActive : "")
                          }
                      />
                    }
                    disableTypography={true}
                    className={cx(
                        { [itemText]: prop.icon !== undefined },
                        { [collapseItemText]: prop.icon === undefined }
                    )}
                />
              </NavLink>
              <Collapse in={this.state[prop.state]} unmountOnExit>
                <List className={classes.list + " " + classes.collapseList}>
                  {this.createLinks(prop.views)}
                </List>
              </Collapse>
            </ListItem>
        );
      }
      const innerNavLinkClasses =
          classes.collapseItemLink +
          " " +
          cx({
            [" " + classes[color]]: this.activeRoute(prop.path)
          });
      const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive
          });
      const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes[color]]: this.activeRoute(prop.path)
          });
      const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive
          });
      const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive
          });
      const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive
          });
      return (
          <ListItem
              key={key}
              className={cx(
                  { [classes.item]: prop.icon !== undefined },
                  { [classes.collapseItem]: prop.icon === undefined }
              )}
          >
            <NavLink
                to={prop.layout + prop.path}
                className={cx(
                    { [navLinkClasses]: prop.icon !== undefined },
                    { [innerNavLinkClasses]: prop.icon === undefined }
                )}
            >
              {prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                      <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                      <prop.icon className={itemIcon} />
                  )
              ) : (
                  <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : prop.mini}
              </span>
              )}
              <ListItemText
                  primary={rtlActive ? prop.rtlName : prop.name}
                  disableTypography={true}
                  className={cx(
                      { [itemText]: prop.icon !== undefined },
                      { [collapseItemText]: prop.icon === undefined }
                  )}
              />
            </NavLink>
          </ListItem>
      );
    });
  };
  render() {
    const {
      classes,
      logo,
      miniLogo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive
    } = this.props;
    const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive
        });
    const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive
        });
    const userWrapperClass =
        classes.user +
        " " +
        cx({
          [classes.whiteAfter]: bgColor === "white"
        });
    const caret =
        classes.caret +
        " " +
        cx({
          [classes.caretRTL]: rtlActive
        });
    const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive
        });
    const photo =
        classes.photo +
        " " +
        cx({
          [classes.photoRTL]: rtlActive
        });
    var user = (
        <React.Fragment>
          <div className={userWrapperClass}>
            <div className={photo}>
              <img src={this.state.userAvatar} className={classes.avatarImg} alt={i18n.t('user-avatar')} />
            </div>
            <List className={classes.list}>
              <ListItem className={classes.item + " " + classes.userItem}>
                <NavLink
                  to={"#"}
                  className={classes.itemLink + " " + classes.userCollapseButton}
                  onClick={() => this.openCollapse("openAvatar")}
                >
                  <ListItemText
                    primary={this.state.userProfile.name}
                    secondary={
                      <b
                        className={
                          caret +
                          " " +
                          classes.userCaret +
                          " " +
                          (this.state.openAvatar ? classes.caretActive : "")
                        }
                      />
                    }
                    disableTypography={true}
                    className={itemText + " " + classes.userItemText}
                  />
                </NavLink>
                <Collapse in={this.state.openAvatar} unmountOnExit>
                  <List className={classes.list + " " + classes.collapseList}>
                    <ListItem className={classes.collapseItem}>
                      <a
                        href={ApiService.getUserProfileViewPath()}
                        className={
                          classes.itemLink + " " + classes.userCollapseLinks
                        }
                      >
                        <span className={collapseItemMini}>
                          {"P"}
                        </span>
                        <ListItemText
                          primary={i18n.t("profile")}
                          disableTypography={true}
                          className={collapseItemText}
                        />
                      </a>
                    </ListItem>
                    <ListItem className={classes.collapseItem}>
                      <a
                        href={ApiService.getLockProfileViewPath()}
                        className={
                          classes.itemLink + " " + classes.userCollapseLinks
                        }
                      >
                        <span className={collapseItemMini}>
                          {"L"}
                        </span>
                        <ListItemText
                          primary={i18n.t("lock")}
                          disableTypography={true}
                          className={collapseItemText}
                        />
                      </a>
                    </ListItem>
                  </List>
                </Collapse>
              </ListItem>
            </List>
          </div>
          <div className={userWrapperClass}>
            <List className={classes.list}>
              <ListItem className={classes.item} style={{ textAlign: "center" }}>
                {(() => {
                  if (this.props.miniActive && this.state.miniActive) {
                    return (
                      <Button color="primary" block type="submit" style={{ borderRadius: "25px", maxWidth: "70%",  color: grayColor[16] }} onClick={this.handleShowFileUploadDialog}>
                        <AddIcon />
                      </Button>
                    );
                  } else {
                    return (
                      <Button color="primary" block type="submit" style={{ borderRadius: "25px", maxWidth: "80%",  color: grayColor[16] }} onClick={this.handleShowFileUploadDialog}>
                        <AddIcon style={{ marginRight: "10px" }} />
                          {i18n.t("upload")}
                      </Button>
                    );
                  }
                })()}
              </ListItem>
            </List>
          </div>
          <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
              }}
              open={this.state.showFileUploadDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={(event, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                  this.fileInput.current.value = "";
                  this.setState(clearFileUploadState);
                }
              }}
              fullWidth
              maxWidth="sm"
              aria-labelledby="notice-modal-slide-title"
              aria-describedby="notice-modal-slide-description"
              disableEnforceFocus
          >
            <DialogTitle
                id="notice-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
            <h4 className={classes.modalTitle}>{i18n.t("file-upload-0")} {this.state.formattedUserName}</h4>
            </DialogTitle>
            <form onSubmit={this.handleFileUpload}>
              <DialogContent
                  id="notice-modal-slide-description"
                  className={classes.modalBody}
              >
                <GridContainer>
                  <GridItem xs={12}>
                    <div className="picture-container">
                      <div className="picture">
                        <CloudUploadIcon style={{ width: "2.5em", height: "2.5em", color: roseColor[0], paddingTop: "1em"}}/>
                        <label htmlFor="files" className="btn" style={{ display: "block"}}>{i18n.t("select-file")}</label>
                        <input type="file" id="files" ref={this.fileInput} disabled={this.state.showProgress} onChange={e => {
                          const selectedFile = e.target.files[0];
                          const stateUpdate = {
                            selectedFile,
                            pricing: {}
                          };
                          if (selectedFile && (!this.state.fileTitleInputActive || this.state.fileTitle.length === 0)) {
                            stateUpdate.fileTitle = selectedFile.name;
                            stateUpdate.fileTitleInputActive = false;
                            if (this.state.pricingPerOperation) {
                              this.getPricingPerOperation(
                                selectedFile,
                                this.state.fileTTL,
                                this.state.storeForever,
                                this.state.storageService
                              ).then(pricing => {
                                stateUpdate.pricing = pricing;
                                this.setState(stateUpdate);
                              });
                            } else {
                              this.setState(stateUpdate);
                            }
                          } else {
                            this.setState(stateUpdate);
                          }
                        }} />
                      </div>
                      <h6 className="description">{ this.state.selectedFile && this.state.selectedFile.name }</h6>
                      <h6 className="description">{ this.state.selectedFile && `Size: ${this.state.selectedFile.size}` }</h6>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{ float: "left"}}>
                      {i18n.t("title")}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10} >
                    <CustomInput
                        id="sidebar-title"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          value: this.state.fileTitle,
                          disabled: this.state.showProgress,
                          onChange: e => {
                            this.setState({
                              fileTitle: e.target.value,
                              fileTitleInputActive: true
                            });
                          }
                        }}
                        helpText={i18n.t("title-of-uploaded-document")}
                    />
                  </GridItem>
                </GridContainer>

                {this.state.parentId && (
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal} style={{ float: "left"}}>
                        {i18n.t("parent-id")}
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10} >
                      <CustomInput
                          id="parentId"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: this.state.parentId,
                            disabled: true,
                          }}
                          helpText={i18n.t("document-parent-id")}
                      />
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel
                      className={
                        classes.labelHorizontal +
                        " " +
                        classes.labelHorizontalRadioCheckbox
                      }
                      style={{ float: "left", paddingTop: "40px" }}
                    >
                      {i18n.t("service")}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="storage-service"
                        className={classes.selectLabel}
                      ></InputLabel>
                      <Select
                        value={this.state.storageService}
                        onChange={(e) => {
                          const selectedStorageService = e.target.value;
                          const stateUpdate = {
                            storageService: selectedStorageService,
                          };
                          if (
                            selectedStorageService ===
                            ApiService.packageStorageService.blockchain
                          ) {
                            stateUpdate.fileTTL = -1;
                            stateUpdate.storeForever = [1];
                          }
                          if (this.state.pricingPerOperation) {
                            this.getPricingPerOperation(
                              this.state.selectedFile,
                              stateUpdate.fileTTL ? stateUpdate.fileTTL : this.state.fileTTL,
                              stateUpdate.storeForever ? stateUpdate.storeForever : this.state.storeForever,
                              stateUpdate.storageService
                            ).then(pricing => {
                              stateUpdate.pricing = pricing;
                              this.setState(stateUpdate);
                            });
                          } else {
                            this.setState(stateUpdate);
                          }
                        }}
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        inputProps={{
                          name: "storageService",
                          id: "storage-service",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          {i18n.t("service")}
                        </MenuItem>
                        {Object.values(ApiService.packageStorageService).map(
                          (serviceType) => {
                            return (
                              <MenuItem
                                key={serviceType}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                  classes.selectMenuItemSelectedMultiple,
                                }}
                                value={serviceType}
                              >
                                {serviceType}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                {this.state.storageService !== ApiService.packageStorageService.blockchain && (
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={
                        classes.labelHorizontal +
                        " " +
                        classes.labelHorizontalRadioCheckbox
                      } style={{ float: "left"}}>
                        EOL
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <div className={classes.checkboxAndRadio}>
                        <FormControl fullWidth>
                          <Datetime
                              dateFormat={"YYYY-MM-DD"}
                              timeFormat={false}
                              inputProps={{
                                placeholder: i18n.t("select-date"),
                                disabled: (this.state.showProgress || this.state.storeForever.length > 0),
                              }}
                              value={this.state.fileTTL === -1 ? undefined : this.state.fileTTL}
                              onChange={e => {
                                const stateUpdate = {
                                  fileTTL: e,
                                  pricing: {}
                                };
                                if (this.state.pricingPerOperation) {
                                  this.getPricingPerOperation(
                                    this.state.selectedFile,
                                    stateUpdate.fileTTL,
                                    this.state.storeForever,
                                    this.state.storageService
                                  ).then(pricing => {
                                    stateUpdate.pricing = pricing;
                                    this.setState(stateUpdate);
                                  });
                                } else {
                                  this.setState(stateUpdate);
                                }
                              }}
                              closeOnSelect
                          />
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={4} style={{ float: "left"}}>
                      <div className={classes.checkboxAndRadio}>
                        <FormControlLabel style={{ float: "left"}}
                          control={
                            <Checkbox
                                tabIndex={-1}
                                onClick={() => {
                                  const currentIndex = this.state.storeForever.indexOf(1);
                                  const newChecked = [...this.state.storeForever];
                                  if (currentIndex === -1) {
                                    newChecked.push(1);
                                  } else {
                                    newChecked.splice(currentIndex, 1);
                                  }
                                  const stateUpdate = {
                                    storeForever: newChecked,
                                    fileTTL: (newChecked.length > 0) ? -1 : this.state.fileTTL,
                                    pricing: {}
                                  };
                                  if (this.state.pricingPerOperation) {
                                    this.getPricingPerOperation(
                                      this.state.selectedFile,
                                      stateUpdate.fileTTL,
                                      stateUpdate.storeForever,
                                      this.state.storageService
                                    ).then(pricing => {
                                      stateUpdate.pricing = pricing;
                                      this.setState(stateUpdate);
                                    });
                                  } else {
                                    this.setState(stateUpdate);
                                  }
                                }}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot
                                }}
                                checked={this.state.storeForever.length > 0}
                                disabled={this.state.showProgress}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label={i18n.t("store-forever")}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                )}

                {ApiService.activePricingPerOperation && this.state.pricingPerOperation && (
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal} style={{ float: "left"}}>
                        {i18n.t("price")}
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10} >
                      <CustomInput
                          id="price"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: this.state.pricing.storagePrice ? ApiService.formatPricing(this.state.pricing.storagePrice, this.state.pricing.currency) : "",
                            disabled: true
                          }}
                          helpText={i18n.t("price-per-operation")}
                      />
                    </GridItem>
                  </GridContainer>
                )}

                <br />
                {this.state.showProgress && (<GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel
                        className={
                          classes.labelHorizontal +
                          " " +
                          classes.labelHorizontalRadioCheckbox
                        }  style={{ float: "left"}}
                    >
                      {i18n.t("progress")}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <div className={classes.checkboxAndRadio}>
                      <FormControl fullWidth>
                        <br />
                        <CustomLinearProgress
                            variant="determinate"
                            color="primary"
                            value={this.state.progress}
                        />
                        <Muted >{this.state.jobId} - {this.state.progress}% ({moment.utc(this.state.progressRuntime).format("HH:mm:ss.SSS")})</Muted>
                      </FormControl>
                    </div>
                  </GridItem>
                </GridContainer>)}
                <br />
                {this.state.message && (<GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Danger>
                      {this.state.message}
                    </Danger>
                  </GridItem>
                </GridContainer>)}
                {this.state.successMessage && (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Success>
                          {this.state.successMessage}
                        </Success>
                      </GridItem>
                    </GridContainer>)}
                <br />
              </DialogContent>
              <DialogActions
                  className={classes.modalFooter + " " + classes.modalFooterCenter}
              >
                <Button onClick={() => {
                  if (this.state.abortController) {
                    this.state.abortController.abort();
                  }
                  this.fileInput.current.value = "";
                  this.setState(clearFileUploadState);
                }} disabled={this.state.confirmDialog} color="rose" simple>
                  {i18n.t("cancel")}
                </Button>
                <Button type="submit" color="primary" disabled={this.state.showProgress && !this.state.confirmDialog}>
                  {this.state.confirmButtonText}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </React.Fragment>
    );
    var links = (
        <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal =
        classes.logoNormal +
        " " +
        cx({
          [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
          [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.logoNormalRTL]: rtlActive
        });
    const logoMini =
        classes.logoMini +
        " " +
        cx({
          [classes.logoMiniRTL]: rtlActive
        });
    const miniLogoMini = classes.miniLogoMini;
    const logoClasses =
        classes.logo +
        " " +
        cx({
          [classes.whiteAfter]: bgColor === "white"
        });
    var brand = (
      <div className={logoClasses}>
        <Link to="/admin/dashboard">
          <div
            className={
              this.props.miniActive && this.state.miniActive
                ? miniLogoMini
                : logoMini
            }
          >
            <img
              src={
                this.props.miniActive && this.state.miniActive ? miniLogo : logo
              }
              alt="logo"
              className={
                this.props.miniActive && this.state.miniActive
                  ? classes.miniImg
                  : classes.img
              }
            />
          </div>
        </Link>

{/*         <Link to="/admin/dashboard">
          <div className={logoNormal}>{logoText}</div>
        </Link> */}
      </div>
    );

    const drawerPaper =
        classes.drawerPaper +
        " " +
        cx({
          [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
          [classes.drawerPaperRTL]: rtlActive
        });
    const sidebarWrapper =
        classes.sidebarWrapper +
        " " +
        cx({
          [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
          [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
        });
    return (
        <div ref={this.mainPanel}>
          <Hidden mdUp implementation="css">
            <Drawer
                variant="temporary"
                anchor={rtlActive ? "left" : "right"}
                open={this.props.open}
                classes={{
                  paper: drawerPaper + " " + classes[bgColor + "Background"]
                }}
                onClose={this.props.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
            >
              {brand}
              <SidebarWrapper
                  className={sidebarWrapper}
                  user={user}
                  headerLinks={<AdminNavbarLinks rtlActive={rtlActive} history={this.props.history} />}
                  links={links}
              />
              {image !== undefined ? (
                  <div
                      className={classes.background}
                      style={{ backgroundImage: "url(" + image + ")" }}
                  />
              ) : null}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
                onMouseOver={() => this.setState({ miniActive: false })}
                onMouseOut={() => this.setState({ miniActive: true })}
                anchor={rtlActive ? "right" : "left"}
                variant="permanent"
                open
                classes={{
                  paper: drawerPaper + " " + classes[bgColor + "Background"]
                }}
            >
              {brand}
              <SidebarWrapper
                  className={sidebarWrapper}
                  user={user}
                  links={links}
              />
              {image !== undefined ? (
                  <div
                      className={classes.background}
                      style={{ backgroundImage: "url(" + image + ")" }}
                  />
              ) : null}
            </Drawer>
          </Hidden>
        </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  history: PropTypes.object.isRequired
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object
};

export default withStyles(combineStyles(sidebarStyle, notificationStyle, formStyles))(Sidebar);
