import React from "react";
import validator from "validator";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";

// @material-ui/icons
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";

const useStyles = makeStyles(styles);

export default function PricingPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [pricingPlans, setPricingPlans] = React.useState([]);
  const [activeSubscription, setActiveSubscription] = React.useState(
    ApiService.subscriptionPlans.operation
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        const params = {
          subscriptionType: ApiService.subscriptionTypes.sdc,
          paymentMethod: ApiService.subscriptionPaymentMethods.invoice,
          sortBy: "name:asc",
        };
        const returnData = await ApiService.getPricingPlans(params, signal);
        try {
          const userProfile = await ApiService.readUser({}, signal);
          const subscription = userProfile.subscriptions.filter(
            (subscription) =>
              subscription.type === ApiService.subscriptionTypes.sdc &&
              (subscription.status === ApiService.subscriptionStatuses.active ||
                subscription.status ===
                  ApiService.subscriptionStatuses.cancelled)
          );
          if (subscription.length > 0) {
            setActiveSubscription(subscription[0].plan);
          }
        } catch (e) {
          console.error(e);
        }
        const pricingPlans = returnData.filter(
          (pricingPlan) => pricingPlan.subscriptions.length === 1
        );
        setPricingPlans(pricingPlans);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, []);

  const getPricingIcon = (index, active) => {
    const icons = [
      <Business
        key={0}
        className={active ? classes.iconRose : classes.iconWhite}
      />,
      <Home
        key={1}
        className={active ? classes.iconRose : classes.iconWhite}
      />,
      <AccountBalance
        key={2}
        className={active ? classes.iconRose : classes.iconWhite}
      />,
      <AccountBalanceWalletIcon
        key={3}
        className={active ? classes.iconRose : classes.iconWhite}
      />,
    ];
    return icons[index % icons.length];
  };

  const renderPricingPlan = (pricingPlan, index, subscription) => {
    const active = pricingPlan.plan === subscription;
    if (active) {
      return (
        <GridItem key={index} xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>{pricingPlan.plan}</h6>
              <div className={classes.icon}>
                {getPricingIcon(index, active)}
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                {pricingPlan.plan === ApiService.subscriptionPlans.operation
                  ? t("pay-as-you-go")
                  : validator.isDecimal(pricingPlan.price)
                  ? `$${pricingPlan.price}`
                  : pricingPlan.price}
              </h3>
              <p className={classes.cardDescription}>
                {pricingPlan.shortDescription}
              </p>
              <Button round color="rose" disabled={true}>
                {t("active")}
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      );
    } else {
      return (
        <GridItem key={index} xs={12} sm={12} md={3}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h6 className={classes.cardCategory}>{pricingPlan.plan}</h6>
              <div className={classes.icon}>
                {getPricingIcon(index, active)}
              </div>
              <h3
                className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                {pricingPlan.plan === ApiService.subscriptionPlans.operation
                  ? t("pay-as-you-go")
                  : validator.isDecimal(pricingPlan.price)
                  ? `$${pricingPlan.price}`
                  : pricingPlan.price}
              </h3>
              <p className={classes.cardCategory}>
                {pricingPlan.shortDescription}
              </p>
              <a href={ApiService.getUpdatePricingPlanPage()}>
                <Button round color="white">
                  {t("choose-plan")}
                </Button>
              </a>
            </CardBody>
          </Card>
        </GridItem>
      );
    }
  };

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>{t("pick-the-best-plan-for-you")}</h2>
          <h5 className={classes.description}>
            {t(
              "you-have-free-unlimited-updates-and-premium-support-on-each-package"
            )}
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContent="center">
        {pricingPlans.map((pricingPlan, index) =>
          renderPricingPlan(pricingPlan, index, activeSubscription)
        )}
      </GridContainer>
    </div>
  );
}
