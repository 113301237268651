import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import PublicIcon from "@material-ui/icons/Public";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ClearIcon from "@material-ui/icons/Clear";

/*#############################
  # Modal view dialog classes #
  #############################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";
import Close from "@material-ui/icons/Close";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ShareItems = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [sharedItems, setSharedItems] = React.useState([]);
  const [pageTitle, setPageTitle] = React.useState(t("shared-items"));
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [backLink, setBackLink] = React.useState(undefined);
  const [pricingPerOperation, setPricingPerOperation] = React.useState(false);

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );
  const [showShareItemDialog, setShowShareItemDialog] = React.useState(false);
  const [shareItemDialogData, setShareItemDialogData] = React.useState({});

  // Dialog fields
  const [modalItemId, setModalItemId] = React.useState("");
  const [modalParentId, setModalParentId] = React.useState(undefined);
  const [modalAccess, setModalAccess] = React.useState(
    ApiService.shareItemAccess.public
  );
  const [modalStorageService, setModalStorageService] = React.useState(
    ApiService.shareItemStorageService.cassandra
  );
  const [modalShareTTL, setModalShareTTL] = React.useState(
    moment().add(ApiService.defaultStorageDays, "days")
  );
  const [modalStoreForever, setModalStoreForever] = React.useState([]);
  const [modalDataType, setModalDataType] = React.useState(
    ApiService.shareItemDataType.json
  );
  const [modalData, setModalData] = React.useState("{}");

  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [shareItemDetails, setShareItemDetails] = React.useState({});
  const [modalUpdateAccess, setModalUpdateAccess] = React.useState(
    ApiService.shareItemAccess.public
  );
  const [modalUpdateParentId, setModalUpdateParentId] = React.useState("");
  const [modalUpdateTTL, setModalUpdateTTL] = React.useState([]);
  const [modalUpdateShareTTL, setModalUpdateShareTTL] = React.useState(
    moment().add(ApiService.defaultStorageDays, "days")
  );
  const [modalUpdateStoreForever, setModalUpdateStoreForever] = React.useState(
    []
  );
  const [modalPricing, setModalPricing] = React.useState({});

  const [refreshClicked, setRefreshClicked] = React.useState(false);
  const [shareUserId, setShareUserId] = React.useState("");

  const [count, setCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);

  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId = userProfileData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    const parentId = queryParams.get("documentContainerId");
    if (parentId) {
      params.parentId = parentId;
      setBackLink(
        `/admin/document-container?${new URLSearchParams({
          userId: params.userId,
          documentContainerId: parentId,
        }).toString()}`
      );
      setModalParentId(parentId);
      setPageTitle(t("document-container-shared-items"));
    } else {
      setPageTitle(t("shared-items"));
    }
    return params;
  };

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalItemId("");
    setModalAccess(ApiService.shareItemAccess.public);
    setModalStorageService(ApiService.shareItemStorageService.cassandra);
    setModalShareTTL(moment().add(ApiService.defaultStorageDays, "days"));
    setModalStoreForever([]);
    setModalDataType(ApiService.shareItemDataType.json);
    setModalData("{}");
    setShowUpdateModal(false);
    setShareItemDetails({});
    setModalUpdateAccess(ApiService.shareItemAccess.public);
    setModalUpdateParentId("");
    setModalUpdateTTL([]);
    setModalUpdateShareTTL(moment().add(ApiService.defaultStorageDays, "days"));
    setModalUpdateStoreForever([]);
    setModalPricing({});
    setShowShareItemDialog(false);
    setShareItemDialogData({});
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleDownloadShareItem = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-share-item")}?`}
        onConfirm={() => {
          downloadShareItem(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const downloadShareItem = async (elem) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const responseData = await ApiService.downloadShareItem({
        userId: queryParams.userId,
        shareId: elem._id,
      });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      let extension;
      if (elem.dataType === ApiService.shareItemDataType.json) {
        extension = "json";
      } else if (elem.dataType === ApiService.shareItemDataType.xml) {
        extension = "xml";
      } else {
        extension = "txt";
      }
      a.download = `${elem._id}.${extension}`;
      a.click();
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const handleReturnData = (data, shareUserId) => {
    return data.map((elem) => {
      if (!elem.endOfLife) {
        elem.endOfLife = t("forever");
      }
      elem.blockchainStoreFormatted = elem.blockchainStore
        ? elem.blockchainStore.toString()
        : "false";
      const queryParameters = new URLSearchParams({
        userId: shareUserId,
        shareId: elem._id,
      }).toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("share-item-details")}>
            <Link to={`/admin/share-item?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <MoreVertIcon />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t("view-details")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="remove"
              onClick={async () => {
                try {
                  const queryParams = await getQueryParams();
                  const params = {
                    shareId: elem._id,
                    userId: queryParams.userId,
                  };
                  const responseData = await ApiService.getShareItem(params);
                  setShareItemDialogData(responseData);
                  setShowShareItemDialog(true);
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              <VisibilityIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("download-share-item")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="edit"
              onClick={() => {
                handleDownloadShareItem(elem);
              }}
            >
              <CloudDownloadIcon />
            </Button>
          </Tooltip>
          {!elem.blockchainStore && (
            <Tooltip title={t("update-share-item")}>
              <Button
                justIcon
                round
                simple
                color="rose"
                className="edit"
                onClick={() => {
                  setShareItemDetails(elem);
                  setModalUpdateParentId(elem.parentId ? elem.parentId : "");
                  setModalUpdateAccess(elem.access);
                  setConfirmModalButtonText(t("update"));
                  setShowUpdateModal(true);
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          )}
          <Tooltip title={t("delete-share-item")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="remove"
              onClick={() => {
                handleDeleteShareItem(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  const handleDeleteShareItem = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-share-item")}?`}
        onConfirm={() => {
          deleteShareItem(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem.itemId}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteShareItem = async (shareItem) => {
    setAlert(null);
    try {
      const { userId } = await getQueryParams();
      await ApiService.deleteShareItem({
        userId,
        shareId: shareItem._id,
      });
      operationResult(true, t("share-item-successfully-deleted"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const handleAddShareItem = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    let itemId = modalItemId.trim();
    if (itemId.length === 0) {
      itemId = uuidv4();
    }
    let data;
    let validData = true;
    const modalDataParsed = modalData.trim();
    if (modalDataType === ApiService.shareItemDataType.json) {
      try {
        data = JSON.parse(modalDataParsed);
      } catch (e) {
        validData = false;
      }
    } else {
      data = modalDataParsed;
      if (modalDataParsed.length === 0) {
        validData = false;
      }
    }
    const ttl =
      modalShareTTL !== "-1" ? modalShareTTL.endOf("day").toISOString() : "-1";
    const validTTL = !(ttl === "-1" && modalStoreForever.length === 0);
    const checksPassed = validData && validTTL;
    if (!checksPassed) {
      const messageContent = [];
      if (!validData) {
        messageContent.push(t("invalid-data-format"));
      }
      if (!validTTL) {
        messageContent.push(t("invalid-eol-defined"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      itemId,
      access: modalAccess,
      sharingService: modalStorageService,
      endOfLife: ttl,
      dataType: modalDataType,
      data,
      encoding: ApiService.storageFormat,
    };
    if (modalParentId) {
      params.parentId = modalParentId;
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("processing"));

    let responseData;
    try {
      responseData = await ApiService.addShareItem(params, signal);
    } catch (e) {
      return showErrorModalMessage(t("error-processing-share-item"));
    }
    const jobId = responseData.jobId;
    setModalProgress(responseData.progress);
    try {
      const jobParams = { jobId, userId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        setModalProgress(responseData.progress);
        if (responseData.status === ApiService.jobStatus.failed) {
          return showErrorModalMessage(t("error-processing-share-item"));
        }
        if (
          responseData.status === ApiService.jobStatus.failedBlockchain ||
          responseData.status === ApiService.jobStatus.done
        ) {
          return showSuccessModalMessage(
            t("share-item-successfully-processed")
          );
        }
      }
      if (signal.aborted) {
        return showErrorModalMessage(t("operation-aborted"));
      }
    } catch (e) {
      showErrorModalMessage(t("error-processing-share-item"));
    }
  };

  const handleUpdateShareItem = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const parentId = modalUpdateParentId.trim();
    const updateParentId =
      (parentId === "" && shareItemDetails.parentId) ||
      (parentId !== "" && parentId !== shareItemDetails.parentId);
    const ttl =
      modalUpdateShareTTL !== "-1"
        ? modalUpdateShareTTL.endOf("day").toISOString()
        : "-1";
    const validTTL = !(ttl === "-1" && modalUpdateStoreForever.length === 0);
    const updateTTL =
      modalUpdateTTL.length > 0 &&
      !(ttl === "-1" && !shareItemDetails.endOfLife);
    const updateAccess = modalUpdateAccess !== shareItemDetails.access;

    const checksPassed = !(modalUpdateTTL.length > 0 && !validTTL);
    if (!checksPassed) {
      const messageContent = [];
      if (!validTTL) {
        messageContent.push(t("invalid-eol-defined"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`
      );
    }

    if (!updateParentId && !updateAccess && !updateTTL) {
      return clearModalState();
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      shareId: shareItemDetails._id,
    };
    if (updateParentId) {
      params.parentId = parentId;
    }
    if (updateAccess) {
      params.access = modalUpdateAccess;
    }
    if (updateTTL) {
      params.endOfLife = ttl;
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("updating"));

    try {
      await ApiService.updateShareItem(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("share-item-successfully-updated"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };

      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      const parentId = queryParams.get("documentContainerId");
      if (parentId) {
        params.parentId = parentId;
        setBackLink(
          `/admin/document-container?${new URLSearchParams({
            userId: params.userId,
            documentContainerId: parentId,
          }).toString()}`
        );
        setModalParentId(parentId);
        setPageTitle(t("document-container-shared-items"));
      } else {
        setPageTitle(t("shared-items"));
      }
      setShareUserId(params.userId);
      return params;
    };

    const getSharedItems = async (signal = undefined) => {
      try {
        const queryParams = await getQueryParams(signal);
        const params = {
          userId: queryParams.userId,
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        if (queryParams.parentId) {
          params.parentId = queryParams.parentId;
        }
        const responseData = await ApiService.getSharedItems(params, signal);
        setSharedItems(responseData);
        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        const { count: sharedCount } = await ApiService.getSharedItemsCount(
          params,
          signal
        );
        setCount(sharedCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const apiOperations = async () => {
      try {
        const userProfile = await ApiService.loginRequired(signal);
        const activePerOperation =
          ApiService.getPricingPerOperationActive(userProfile);
        setPricingPerOperation(activePerOperation);
        await getSharedItems(signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
    t,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && count > 0) {
      setTotalPages(Math.ceil(count / pageSize));
    }
    return () => {
      isMounted = false;
    };
  }, [count, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(count / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }
    setCurrentPage(currentIndex);

    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted, column, shiftKey) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (isMounted) {
      if (!showModal) {
        return setModalPricing({});
      }
      const validTTL = !(
        modalShareTTL === "-1" && modalStoreForever.length === 0
      );
      if (!validTTL) {
        return setModalPricing({});
      }
      const fileSizeBytes = Buffer.byteLength(Buffer.from(modalData));
      const ttl =
        modalShareTTL === "-1"
          ? -1
          : Math.ceil((modalShareTTL.endOf("day") - moment()) / 1000.0);

      const apiOperations = async () => {
        try {
          const pricing = await ApiService.getPricingPerOperation(
            {
              fileSizeBytes,
              ttl: ttl.toString(),
              encoding: ApiService.storageFormat,
              storageService: "shareItem",
              storageType: modalStorageService,
            },
            signal
          );
          setModalPricing(pricing);
        } catch (e) {
          setModalPricing({});
        }
      };
      apiOperations();
    }

    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    showModal,
    modalStorageService,
    modalShareTTL,
    modalStoreForever,
    modalData,
  ]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("add-share-item")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleAddShareItem}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("item-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="item-id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalItemId,
                    disabled: showModalProgress,
                    onChange: (e) => {
                      setModalItemId(e.target.value);
                    },
                  }}
                  helpText={t("item-id")}
                />
              </GridItem>
            </GridContainer>
            {modalParentId && (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    style={{ float: "left" }}
                  >
                    {t("parent-id")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="parent-id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: modalParentId,
                      disabled: true,
                    }}
                    helpText={t("parent-id")}
                  />
                </GridItem>
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("access")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="access-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalAccess}
                    onChange={(e) => {
                      setModalAccess(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "accessType",
                      id: "access-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("access")}
                    </MenuItem>
                    {Object.values(ApiService.shareItemAccess).map(
                      (accessType) => {
                        return (
                          <MenuItem
                            key={accessType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={accessType}
                          >
                            {accessType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("service")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="storage-service"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalStorageService}
                    onChange={(e) => {
                      const selectedStorageService = e.target.value;
                      setModalStorageService(selectedStorageService);
                      if (
                        selectedStorageService ===
                        ApiService.shareItemStorageService.blockchain
                      ) {
                        setModalShareTTL("-1");
                        setModalStoreForever([1]);
                      }
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "storageService",
                      id: "storage-service",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("service")}
                    </MenuItem>
                    {Object.values(ApiService.shareItemStorageService).map(
                      (serviceType) => {
                        return (
                          <MenuItem
                            key={serviceType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={serviceType}
                          >
                            {serviceType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("type")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="data-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalDataType}
                    onChange={(e) => {
                      setModalDataType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "dataType",
                      id: "data-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("data-type")}
                    </MenuItem>
                    {Object.values(ApiService.shareItemDataType).map(
                      (dataType) => {
                        return (
                          <MenuItem
                            key={dataType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={dataType}
                          >
                            {dataType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("data")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="share-data"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalData,
                    disabled: showModalProgress,
                    multiline: true,
                    onChange: (e) => {
                      setModalData(e.target.value);
                    },
                  }}
                  helpText={t("share-item-data")}
                />
              </GridItem>
            </GridContainer>
            {modalStorageService !==
              ApiService.shareItemStorageService.blockchain && (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("eol")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <Datetime
                        dateFormat={"YYYY-MM-DD"}
                        timeFormat={false}
                        inputProps={{
                          placeholder: t("select-date"),
                          disabled:
                            showModalProgress || modalStoreForever.length > 0,
                        }}
                        value={
                          modalShareTTL === "-1" ? undefined : modalShareTTL
                        }
                        onChange={(e) => {
                          setModalShareTTL(e);
                        }}
                        closeOnSelect
                      />
                    </FormControl>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={4} style={{ float: "left" }}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControlLabel
                      style={{ float: "left" }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => {
                            const currentIndex = modalStoreForever.indexOf(1);
                            const newChecked = [...modalStoreForever];
                            if (currentIndex === -1) {
                              newChecked.push(1);
                            } else {
                              newChecked.splice(currentIndex, 1);
                            }
                            setModalStoreForever(newChecked);
                            if (newChecked.length > 0) {
                              setModalShareTTL("-1");
                            }
                          }}
                          checkedIcon={
                            <Check className={formClasses.checkedIcon} />
                          }
                          icon={<Check className={formClasses.uncheckedIcon} />}
                          classes={{
                            checked: formClasses.checked,
                            root: formClasses.checkRoot,
                          }}
                          checked={modalStoreForever.length > 0}
                          disabled={showModalProgress}
                        />
                      }
                      classes={{
                        label: formClasses.label,
                        root: formClasses.labelRoot,
                      }}
                      label={t("store-forever")}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            )}

            {ApiService.activePricingPerOperation && pricingPerOperation && (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel
                    className={formClasses.labelHorizontal}
                    style={{ float: "left" }}
                  >
                    {t("price")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="share-data"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: modalPricing.storagePrice
                        ? ApiService.formatPricing(
                            modalPricing.storagePrice,
                            modalPricing.currency
                          )
                        : "",
                      disabled: true,
                    }}
                    helpText={t("share-item-data")}
                  />
                </GridItem>
              </GridContainer>
            )}
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showUpdateModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("update-share-item-0")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleUpdateShareItem}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("parent-id")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="share-item-title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalUpdateParentId,
                    disabled: showModalProgress,
                    onChange: (e) => {
                      setModalUpdateParentId(e.target.value);
                    },
                  }}
                  helpText={t("change-parent-id")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("access")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="access-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalUpdateAccess}
                    onChange={(e) => {
                      setModalUpdateAccess(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "accessType",
                      id: "access-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("access")}
                    </MenuItem>
                    {Object.values(ApiService.shareItemAccess).map(
                      (accessType) => {
                        return (
                          <MenuItem
                            key={accessType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={accessType}
                          >
                            {accessType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left" }}
                ></FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10} style={{ float: "left" }}>
                <div className={formClasses.checkboxAndRadio}>
                  <FormControlLabel
                    style={{ float: "left" }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => {
                          const currentIndex = modalUpdateTTL.indexOf(1);
                          const newChecked = [...modalUpdateTTL];
                          if (currentIndex === -1) {
                            newChecked.push(1);
                          } else {
                            newChecked.splice(currentIndex, 1);
                          }
                          setModalUpdateTTL(newChecked);
                        }}
                        checkedIcon={
                          <Check className={formClasses.checkedIcon} />
                        }
                        icon={<Check className={formClasses.uncheckedIcon} />}
                        classes={{
                          checked: formClasses.checked,
                          root: formClasses.checkRoot,
                        }}
                        checked={modalUpdateTTL.length > 0}
                        disabled={showModalProgress}
                      />
                    }
                    classes={{
                      label: formClasses.label,
                      root: formClasses.labelRoot,
                    }}
                    label={t("change-eol")}
                  />
                </div>
              </GridItem>
            </GridContainer>
            {modalUpdateTTL.length > 0 && (
              <React.Fragment>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel
                      className={
                        formClasses.labelHorizontal +
                        " " +
                        formClasses.labelHorizontalRadioCheckbox
                      }
                      style={{ float: "left" }}
                    >
                      {t("eol")}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={4} style={{ float: "left" }}>
                    <div className={formClasses.checkboxAndRadio}>
                      <FormControlLabel
                        style={{ float: "left" }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => {
                              const currentIndex =
                                modalUpdateStoreForever.indexOf(1);
                              const newChecked = [...modalUpdateStoreForever];
                              if (currentIndex === -1) {
                                newChecked.push(1);
                              } else {
                                newChecked.splice(currentIndex, 1);
                              }
                              setModalUpdateStoreForever(newChecked);
                              if (newChecked.length > 0) {
                                setModalUpdateShareTTL("-1");
                              }
                            }}
                            checkedIcon={
                              <Check className={formClasses.checkedIcon} />
                            }
                            icon={
                              <Check className={formClasses.uncheckedIcon} />
                            }
                            classes={{
                              checked: formClasses.checked,
                              root: formClasses.checkRoot,
                            }}
                            checked={modalUpdateStoreForever.length > 0}
                            disabled={showModalProgress}
                          />
                        }
                        classes={{
                          label: formClasses.label,
                          root: formClasses.labelRoot,
                        }}
                        label={t("store-forever")}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <div className={formClasses.checkboxAndRadio}>
                      <FormControl fullWidth>
                        <Datetime
                          dateFormat={"YYYY-MM-DD"}
                          timeFormat={false}
                          inputProps={{
                            placeholder: t("select-date"),
                            disabled:
                              showModalProgress ||
                              modalUpdateStoreForever.length > 0,
                          }}
                          value={
                            modalUpdateShareTTL === "-1"
                              ? undefined
                              : modalUpdateShareTTL
                          }
                          onChange={(e) => {
                            setModalUpdateShareTTL(e);
                          }}
                          closeOnSelect
                        />
                      </FormControl>
                    </div>
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            )}

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: dialogClasses.center,
          paper: dialogClasses.modal,
        }}
        open={showShareItemDialog}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth={true}
        maxWidth="md"
        onClose={() => setShowShareItemDialog(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <Button
            justIcon
            className={dialogClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowShareItemDialog(false)}
          >
            <Close className={dialogClasses.modalClose} />
          </Button>
          <h4 className={dialogClasses.modalTitle}>
            {t("share-item")}:
            <b>
              {shareItemDialogData.shareItem
                ? shareItemDialogData.shareItem._id
                : ""}
            </b>
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={dialogClasses.modalBody}
        >
          <GridContainer>
            <GridItem className={dialogClasses.wordBreak}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(shareItemDialogData, undefined, 2)}
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={
            dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
          }
        >
          <Button
            onClick={() => setShowShareItemDialog(false)}
            color="rose"
            simple
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <PublicIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {pageTitle} {formattedUserName}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                {backLink && (
                  <Button
                    justIcon
                    round
                    simple
                    className="remove"
                    onClick={() => {
                      props.history.push(backLink);
                    }}
                    style={{ marginTop: "20px" }}
                  >
                    <KeyboardBackspaceIcon style={{ color: roseColor[0] }} />
                  </Button>
                )}
                <Button
                  color="rose"
                  round
                  simple
                  className="remove"
                  onClick={handleRefresh}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {filters.length > 0 && (
                <React.Fragment>
                  <Button
                    color="primary"
                    round
                    className="remove"
                    onClick={() => {
                      handleFilterClicked();
                    }}
                  >
                    {t("filter")}
                  </Button>
                  <Button
                    color="rose"
                    round
                    simple
                    className="remove"
                    onClick={() => {
                      handleRefresh();
                    }}
                  >
                    {t("clear-filters")}
                  </Button>
                </React.Fragment>
              )}
              <ReactTable
                data={handleReturnData(sharedItems, shareUserId)}
                sortable={true}
                multiSort={false}
                filterable={true}
                filtered={filters}
                columns={[
                  { Header: t("id"), accessor: "_id" },
                  {
                    Header: t("item-id"),
                    accessor: "itemId",
                  },
                  {
                    Header: t("parent-id"),
                    accessor: "parentId",
                  },
                  {
                    Header: t("access"),
                    accessor: "access",
                  },
                  {
                    Header: t("service"),
                    accessor: "sharingService",
                  },
                  {
                    Header: t("blockchain-store"),
                    accessor: "blockchainStoreFormatted",
                  },
                  {
                    Header: t("type"),
                    accessor: "dataType",
                  },
                  {
                    Header: t("status"),
                    accessor: "status",
                  },
                  {
                    Header: t("eol"),
                    accessor: "endOfLife",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {ApiService.formatDateTime(row.value)}
                      </div>
                    ),
                    filterable: false,
                  },
                  {
                    Header: t("hash"),
                    accessor: "hash",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("txid"),
                    accessor: "transactionId",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={ApiService.defaultPageSize}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                manual
                pages={totalPages}
                page={currentPage}
                pageSize={pageSize}
                onPageChange={(pageIndex) => handlePageChange(pageIndex)}
                onPageSizeChange={(pageSize, pageIndex) =>
                  handlePageSizeChange(pageSize, pageIndex)
                }
                onSortedChange={(newSorted, column, shiftKey) =>
                  handleSortChange(newSorted, column, shiftKey)
                }
                onFilteredChange={(filtered, column) =>
                  handleFilterColumn(filtered, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

ShareItems.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default ShareItems;
