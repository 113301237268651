import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import LanguageIcon from "@material-ui/icons/Language";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import AppsIcon from "@material-ui/icons/Apps";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

import ApiService from "api/ApiService.js";
import { useTranslation, withTranslation } from "react-i18next";
import {
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

const HeaderLinks = (props) => {
  const [openEndpoints, setOpenEndpoints] = React.useState(null);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openLanguage, setOpenLanguage] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [endpoints, setEndpoints] = React.useState([]);
  const [pendingPackages, setPendingPackages] = React.useState(0);
  const [showNotification, setShowNotification] = React.useState(true);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleClickEndpoints = (event) => {
    if (openEndpoints && openEndpoints.contains(event.target)) {
      setOpenEndpoints(null);
    } else {
      setOpenEndpoints(event.currentTarget);
    }
  };
  const handleCloseEndpoints = () => {
    setOpenEndpoints(null);
  };
  const handleRedirectToEndpoint = (endpoint) => {
    handleCloseEndpoints();
    const redirectUri = ApiService.getRedirectUri(
      endpoint.uri,
      endpoint.type,
      endpoint.name
    );
    window.location.replace(redirectUri);
  };
  const handleClickLanguage = (event) => {
    if (openLanguage && openLanguage.contains(event.target)) {
      setOpenLanguage(null);
    } else {
      setOpenLanguage(event.currentTarget);
    }
  };
  const handleCloseLanguage = () => {
    setOpenLanguage(null);
  };
  const handleSelectLanguage = (lang) => {
    handleCloseLanguage();
    changeLanguage(lang);
  };

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
    setShowNotification(false);
  };
  const handleRedirectToPending = () => {
    handleCloseNotification();
    props.history.push("/admin/pending");
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleRedirectToProfile = () => {
    handleCloseProfile();
    window.location.replace(ApiService.getUserProfileViewPath());
  };
  const handleLock = () => {
    handleCloseProfile();
    window.location.replace(ApiService.getLockProfileViewPath());
  };

  const handleLogout = async () => {
    handleCloseProfile();
    try {
      const params = {};
      await ApiService.logoutUser(params, props.history, true);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        const receivedServices = await ApiService.getUserServices({}, signal);
        const receivedEndpoints = [
          {
            uri: ApiService.idFrontendURL,
            type: "ID",
            name: "ID",
          },
        ];
        // eslint-disable-next-line no-unused-vars
        for (const service of receivedServices) {
          // eslint-disable-next-line no-unused-vars
          for (const endpoint of service.endpoints) {
            if (
              endpoint.deploymentId !== ApiService.instanceId &&
              !endpoint.blocked
            ) {
              receivedEndpoints.push({
                uri: endpoint.uri,
                type: endpoint.service,
                name: endpoint.name,
              });
            }
          }
        }
        const { address } = await ApiService.getPublicKey({}, signal);
        const { count: receivedDataCount } =
          await ApiService.getReceivedDataCount(
            {
              excludeSenders: JSON.stringify([address]),
              state: ApiService.packageState.pending,
            },
            signal
          );
        setEndpoints(receivedEndpoints);
        setPendingPackages(receivedDataCount);
      } catch (e) {
        console.error(e);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, []);

  const classes = useStyles();
  const { rtlActive } = props;
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Notifications
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          {pendingPackages > 0 && showNotification && (
            <span className={classes.notifications}>{pendingPackages}</span>
          )}
          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {rtlActive ? "إعلام" : t("notification")}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {pendingPackages > 0 && (
                      <MenuItem
                        onClick={handleRedirectToPending}
                        className={dropdownItem}
                      >
                        {t("pedingPackage", {
                          count: pendingPackages,
                        })}
                      </MenuItem>
                    )}
                    {pendingPackages === 0 && (
                      <MenuItem className={dropdownItem}>
                        {t("no-new-notifications")}
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Endpoints"
          justIcon
          aria-owns={openEndpoints ? "endpoints-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickEndpoints}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <AppsIcon
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickEndpoints} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : t("endpoints")}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openEndpoints)}
          anchorEl={openEndpoints}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openEndpoints,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="endpoints-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseEndpoints}>
                  <MenuList role="menu">
                    {endpoints.map((endpoint, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            handleRedirectToEndpoint(endpoint);
                          }}
                          className={dropdownItem}
                        >
                          {endpoint.name}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Change language"
          aria-owns={openLanguage ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickLanguage}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <LanguageIcon
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />

          <Hidden mdUp implementation="css">
            <span onClick={handleClickLanguage} className={classes.linkText}>
              {rtlActive ? "إعلام" : t("change-language")}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openLanguage)}
          anchorEl={openLanguage}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openLanguage,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseLanguage}>
                  <MenuList role="menu">
                    {ApiService.languageTranslations.map((entry) => {
                      return (
                        <MenuItem
                          onClick={() => handleSelectLanguage(entry.id)}
                          key={entry.id}
                          className={dropdownItem}
                          style={
                            currentLanguage === entry.id
                              ? {
                                  backgroundColor: primaryColor[0],
                                  color: whiteColor,
                                }
                              : {}
                          }
                        >
                          {t(entry.token)}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : t("profile")}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleRedirectToProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : t("profile")}
                    </MenuItem>
                    <Divider light />
                    <MenuItem onClick={handleLock} className={dropdownItem}>
                      {rtlActive ? "الخروج" : t("lock")}
                    </MenuItem>
                    <MenuItem onClick={handleLogout} className={dropdownItem}>
                      {rtlActive ? "الخروج" : t("logout")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default withTranslation()(HeaderLinks);
