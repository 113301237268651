import React from "react";
import PropTypes from "prop-types";

// react component for creating dynamic tables
import ReactTable from "react-table";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudIcon from "@material-ui/icons/Cloud";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

/*################
  # Form classes #
  ################*/

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Muted from "components/Typography/Muted.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const PublishedPackages = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);
  const [packages, setPackages] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [refreshClicked, setRefreshClicked] = React.useState(false);
  const [userId, setUserId] = React.useState("");

  const [count, setCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(ApiService.defaultPageSize);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);

  const [sortBy, setSortBy] = React.useState("createdAt:desc");

  const [filters, setFilters] = React.useState([]);
  const [filterClicked, setFilterClicked] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState([]);

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId = userProfileData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const handleRefresh = () => {
    setFilters([]);
    setFilterParams([]);
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  const handleReturnData = (data, userId) => {
    return data.map((elem) => {
      if (!elem.endOfLife) {
        elem.endOfLife = t("forever");
      }
      elem.blockchainStoreFormatted = elem.blockchainStore
        ? elem.blockchainStore.toString()
        : "false";
      elem.shareableFormatted = elem.shareable.toString();
      const queryParameters = new URLSearchParams({
        userId,
        data_id: elem.data_id,
        sender: elem.sender,
        receiver: elem.receiver,
        hash: elem.hash,
      }).toString();
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("package-details-0")}>
            <Link to={`/admin/package?${queryParameters}`}>
              <Button justIcon round simple color="rose" className="like">
                <MoreVertIcon />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip title={t("download-package")}>
            <Button
              justIcon
              round
              simple
              onClick={() => {
                showDownloadPackage(elem);
              }}
              color="rose"
              className="remove"
            >
              <CloudDownloadIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("delete-package")}>
            <Button
              justIcon
              round
              simple
              color="rose"
              className="remove"
              onClick={() => {
                showDeletePackage(elem);
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getSendData = async (signal = undefined, userId) => {
      try {
        const { address: publicAddress } = await ApiService.getPublicShareUser(
          signal
        );

        const params = {
          userId,
          sortBy: sortBy,
          fromEntry: currentPage * pageSize,
          numberOfResults: pageSize,
          receiver: publicAddress,
        };

        if (filterParams.length > 0) {
          const filteredObj = {};
          filterParams.map((filter) => {
            const filteredField = filter.id.replace(/Formatted$/, "");
            if (ApiService.exactMatch.includes(filter.id)) {
              return (filteredObj[filteredField] =
                filter.id === "fileSize"
                  ? parseInt(filter.value)
                  : filter.value);
            } else {
              return (filteredObj[filteredField] = {
                $regex: `^${filter.value}`,
              });
            }
          });

          params.filters = JSON.stringify(filteredObj);
        }

        const returnData = await ApiService.getSendData(params, signal);
        const responseData = await ApiService.getAllUsers(
          {},
          signal,
          true,
          false,
          false
        );
        const users = {};
        // eslint-disable-next-line no-unused-vars
        for (const user of responseData) {
          users[user.address] = user;
        }
        // eslint-disable-next-line no-unused-vars
        for (const entry of returnData) {
          const queryParameters = new URLSearchParams({
            packageId: entry._id,
            publicShareSecret: entry.publicShareSecret,
          }).toString();
          entry.publishLink = `${ApiService.serviceFrontendURL}/auth/public-shared-package/?${queryParameters}`;
          entry.receiverFormatted = `${users[entry.receiver].user.email} (${
            entry.receiver
          })`;
        }
        setPackages(returnData);

        delete params.sortBy;
        delete params.fromEntry;
        delete params.numberOfResults;
        const { count: sendDataCount } = await ApiService.getSendDataCount(
          params,
          signal
        );
        setCount(sendDataCount);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterClicked(false);
      }
    };

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      setUserId(params.userId);
      return params;
    };

    const handleRefresh = async (signal = undefined) => {
      const queryParams = await getQueryParams(signal);
      await getSendData(signal, queryParams.userId);
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh(signal, false);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [
    props.location.search,
    refreshClicked,
    currentPage,
    pageSize,
    sortBy,
    filterParams,
  ]);

  React.useEffect(() => {
    if (filterClicked && filters.length > 0) {
      setFilterParams(filters);
    }
  }, [filters, filterClicked]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && count > 0) {
      setTotalPages(Math.ceil(count / pageSize));
    }
    return () => {
      isMounted = false;
    };
  }, [count, pageSize]);

  const handlePageChange = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = async (newPageSize, pageIndex) => {
    const total = Math.ceil(count / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(pageIndex / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * pageIndex);
    }
    setCurrentPage(currentIndex);

    setPageSize(newPageSize);
  };

  const handleSortChange = (newSorted, column, shiftKey) => {
    setSortBy(
      `${newSorted[0].id.replace(/Formatted$/, "")}:${
        newSorted[0].desc === true ? "desc" : "asc"
      }`
    );
  };

  const handleFilterColumn = (filtered, column) => {
    setFilters(filtered);
  };

  const deletePackageResult = (
    result,
    resultMessage,
    responseData = undefined
  ) => {
    const handleDeletionResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleDeletionResult}
          onCancel={handleDeletionResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {responseData && (
            <React.Fragment>
              <CustomLinearProgress
                variant="determinate"
                color="primary"
                value={responseData.progress}
              />
              <Muted>
                {responseData.jobId} - {responseData.progress}% (
                {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
              </Muted>
              <br />
            </React.Fragment>
          )}
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleDeletionResult}
          onCancel={handleDeletionResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {responseData && (
            <React.Fragment>
              <CustomLinearProgress
                variant="determinate"
                color="primary"
                value={responseData.progress}
              />
              <Muted>
                {responseData.jobId} - {responseData.progress}% (
                {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
              </Muted>
              <br />
            </React.Fragment>
          )}
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const downloadPackageError = (resultMessage, responseData = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("error")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {responseData && (
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={responseData.progress}
            />
            <Muted>
              {responseData.jobId} - {responseData.progress}% (
              {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
            </Muted>
            <br />
          </React.Fragment>
        )}
        <Danger>{resultMessage}</Danger>
      </SweetAlert>
    );
  };

  const deletePackageAsync = (data) => {
    const handlePackageDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        sender: data.sender,
        receiver: data.receiver,
        data_id: data.data_id,
        hash: data.hash,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.deleteFile(params, signal);
      } catch (e) {
        return deletePackageResult(false, t("error-deleting-package"));
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId, userId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            return deletePackageResult(
              false,
              t("error-deleting-package"),
              responseData
            );
          }
          if (
            responseData.status === ApiService.jobStatus.failedBlockchain ||
            responseData.status === ApiService.jobStatus.done
          ) {
            return deletePackageResult(
              true,
              t("package-successfully-deleted"),
              responseData
            );
          }
        }
        if (signal.aborted) {
          return deletePackageResult(
            false,
            t("operation-aborted"),
            responseData
          );
        }
      } catch (e) {
        return deletePackageResult(
          false,
          t("error-deleting-package"),
          responseData
        );
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-package")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePackageDeletion();
  };

  const downloadPackageAsync = (data) => {
    const handleDownloadPackage = async (userId, parameters, signal) => {
      const params = {
        userId,
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadFile(params, signal);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      setAlert(null);
      a.click();
    };
    const handlePreparePackageDownload = async () => {
      // Download parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        sender: data.sender,
        receiver: data.receiver,
        data_id: data.data_id,
        hash: data.hash,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.prepareDownloadFile(params, signal);
      } catch (e) {
        return downloadPackageError(t("error-downloading-package"));
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId, userId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            return downloadPackageError(
              t("error-downloading-package"),
              responseData
            );
          }
          if (
            responseData.status === ApiService.jobStatus.failedBlockchain ||
            responseData.status === ApiService.jobStatus.done
          ) {
            await handleDownloadPackage(userId, responseData, signal);
          }
        }
        if (signal.aborted) {
          return downloadPackageError(t("operation-aborted"), responseData);
        }
      } catch (e) {
        return downloadPackageError(
          t("error-downloading-package"),
          responseData
        );
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("preparing-package")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePreparePackageDownload();
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const showDeletePackage = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-package")}?`}
        onConfirm={() => {
          deletePackageAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.title}</b>
        </p>
      </SweetAlert>
    );
  };

  const showDownloadPackage = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-package")}?`}
        onConfirm={() => {
          downloadPackageAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.title}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleFilterClicked = () => {
    setFilterClicked(true);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
            <CardIcon color="turq">
              <CloudIcon style={{ color: roseColor[0] }} />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {t("published-packages")} {formattedUserName}
            </h4>
            <div style={{ marginLeft: "auto" }}>
              <Button
                color="rose"
                round
                simple
                className="remove"
                onClick={() => {
                  handleRefresh();
                }}
                style={{ marginTop: "28px" }}
              >
                {t("refresh")}
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {filters.length > 0 && (
              <React.Fragment>
                <Button
                  color="primary"
                  round
                  className="remove"
                  onClick={() => {
                    handleFilterClicked();
                  }}
                >
                  {t("filter")}
                </Button>
                <Button
                  color="rose"
                  round
                  simple
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                >
                  {t("clear-filters")}
                </Button>
              </React.Fragment>
            )}
            <ReactTable
              data={handleReturnData(packages, userId)}
              sortable={true}
              multiSort={false}
              filterable={true}
              filtered={filters}
              columns={[
                {
                  Header: t("title"),
                  accessor: "title",
                },
                {
                  Header: t("file-name"),
                  accessor: "fileName",
                },
                {
                  Header: t("service"),
                  accessor: "storageService",
                },
                {
                  Header: t("blockchain-store"),
                  accessor: "blockchainStoreFormatted",
                },
                {
                  Header: t("eol"),
                  accessor: "endOfLife",
                  headerStyle: { textAlign: "right" },
                  Cell: (row) => (
                    <div style={{ textAlign: "right" }}>
                      {ApiService.formatDateTime(row.value)}
                    </div>
                  ),
                  filterable: false,
                },
                {
                  Header: t("publish-link-0"),
                  accessor: "publishLink",
                  headerStyle: { textAlign: "right" },
                  filterable: false,
                  Cell: (row) => (
                    <a
                      href={row.value}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: roseColor[0] }}
                    >
                      {row.value}
                    </a>
                  ),
                },
                {
                  Header: t("actions"),
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={ApiService.defaultPageSize}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
              manual
              pages={totalPages}
              page={currentPage}
              pageSize={pageSize}
              onPageChange={(pageIndex) => handlePageChange(pageIndex)}
              onPageSizeChange={(pageSize, pageIndex) =>
                handlePageSizeChange(pageSize, pageIndex)
              }
              onSortedChange={(newSorted, column, shiftKey) =>
                handleSortChange(newSorted, column, shiftKey)
              }
              onFilteredChange={(filtered, column) =>
                handleFilterColumn(filtered, column)
              }
            />
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  );
};

PublishedPackages.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default PublishedPackages;
