import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";
import validator from "validator";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";

// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
import Snackbars from "components/Snackbar/Snackbar.js";
import GridContainer from "../../components/Grid/GridContainer";
import NavPills from "components/NavPills/NavPills.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Muted from "components/Typography/Muted.js";
import Loading from "components/Loading/Loading.js";

// material-ui components
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

// Icons
import ClearIcon from "@material-ui/icons/Clear";
import LockIcon from "@material-ui/icons/Lock";
import PeopleIcon from "@material-ui/icons/People";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EditIcon from "@material-ui/icons/Edit";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AddAlert from "@material-ui/icons/AddAlert";
import Check from "@material-ui/icons/Check";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import ReceiptIcon from "@material-ui/icons/Receipt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";

/*################
  # Form classes #
  ################*/

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import EmailIcon from "@material-ui/icons/Email";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import profileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { Tooltip } from "@material-ui/core";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import {
  roseColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useProfileStyles = makeStyles(profileStyles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ShareItemDetails = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const profileClasses = useProfileStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  // Retrieve share item search parameters
  const shareItemParams = {
    shareId: new URLSearchParams(props.location.search).get("shareId"),
  };
  const otherUser = t("other");

  // Share item details
  const [loading, setLoading] = React.useState(true);
  const [ownerActive, setOwnerActive] = React.useState(false);
  const [inviteActive, setInviteActive] = React.useState(false);
  const [shareItemDetails, setShareItemDetails] = React.useState({});
  const [showVerificationDialog, setShowVerificationDialog] =
    React.useState(false);
  const [shareItemParentId, setShareItemParentId] = React.useState("");
  const [updateShareItemTTL, setUpdateShareItemTTL] = React.useState([]);
  const [shareItemTTL, setShareItemTTL] = React.useState("-1");
  const [shareItemStoreForever, setShareItemStoreForever] = React.useState([]);
  const [showShareItemProgress, setShowShareItemProgress] =
    React.useState(false);
  const [shareItemAccess, setShareItemAccess] = React.useState(
    ApiService.shareItemAccess.private
  );
  const [shareItemProgress, setShareItemProgress] = React.useState(0);
  const [updateShareItemButtonText, setUpdateShareItemButtonText] =
    React.useState(t("update-share-item-0"));

  const [shareItemErrorMessage, setShareItemErrorMessage] = React.useState("");
  const [shareItemSuccessMessage, setShareItemSuccessMessage] =
    React.useState("");
  const [lookupUsers, setLookupUsers] = React.useState({});

  // Share item actions
  const [refreshDisabled, setRefreshDisabled] = React.useState(false);
  const [userAddress, setUserAddress] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [formattedUserName, setFormattedUserName] = React.useState("");
  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const [signatureVerification, setSignatureVerification] = React.useState({});
  const [signatureDetails, setSignatureDetails] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [modalUpdateAccess, setModalUpdateAccess] = React.useState(
    ApiService.signatureAccess.private
  );

  const [showSignatureModal, setShowSignatureModal] = React.useState(false);
  const [modalBlockchain, setModalBlockchain] = React.useState(
    ApiService.blockchainTypes.bsv
  );
  const [signatureAbortController, setSignatureAbortController] =
    React.useState(undefined);

  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("update")
  );

  const [shareItemQrCode, setShareItemQrCode] = React.useState(undefined);
  const [showShareItemDialog, setShowShareItemDialog] = React.useState(false);
  const [shareItemDialogData, setShareItemDialogData] = React.useState({});

  const [shareItemMetadataName, setShareItemMetadataName] = React.useState("");
  const [shareItemMetadataType, setShareItemMetadataType] = React.useState(
    ApiService.metadataTypes.string
  );
  const [shareItemMetadata, setShareItemMetadata] = React.useState("");

  const [shareItemAccessRightsEmail, setShareItemAccessRightsEmail] =
    React.useState("");
  const [shareItemAccessRightsEmailUser, setShareItemAccessRightsEmailUser] =
    React.useState("");
  const [shareItemAccessRightsTTL, setShareItemAccessRightsTTL] =
    React.useState("-1");
  const [shareItemAccessRightsForever, setShareItemAccessRightsForever] =
    React.useState([]);

  const [shareItemAccessRightsType, setShareItemAccessRightsType] =
    React.useState(ApiService.accessRightsTypes.sign);
  const [usersList, setUsersList] = React.useState([]);

  const clearModalState = () => {
    setShowModal(false);
    setShowSignatureModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("update"));
    setModalUpdateAccess(ApiService.signatureAccess.private);
    setModalBlockchain(ApiService.blockchainTypes.bsv);
    setSignatureDetails({});
    setShowShareItemDialog(false);
    setShareItemDialogData({});
    setSignatureAbortController(undefined);
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const downloadError = (resultMessage, responseData = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("error")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {responseData && (
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={responseData.progress}
            />
            <Muted>
              {responseData.jobId} - {responseData.progress}% (
              {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
            </Muted>
            <br />
          </React.Fragment>
        )}
        <Danger>{resultMessage}</Danger>
      </SweetAlert>
    );
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId = userProfileData._id;
      params.email = userProfileData.email;
    } else {
      params.userId = returnData._id;
      params.email = returnData.email;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const formatUserEntry = (address) => {
    if (lookupUsers.hasOwnProperty(address)) {
      return `${lookupUsers[address].user.email} (${address})`;
    }
    return address;
  };

  const showShareItemError = (messageContent) => {
    setShareItemErrorMessage(messageContent);
    setTimeout(() => {
      setShareItemErrorMessage("");
    }, ApiService.messageTimeout);
  };

  const showShareItemSuccess = (messageContent) => {
    setShareItemSuccessMessage(messageContent);
    setTimeout(() => {
      setShareItemSuccessMessage("");
    }, ApiService.messageTimeout);
  };

  const getFrontendUrl = (queryShareItemParams) => {
    const params = new URLSearchParams(queryShareItemParams).toString();
    const url = new URL(`${ApiService.idFrontendURL}/auth/login-page`);
    url.search = new URLSearchParams({
      deploymentId: ApiService.instanceId,
      redirectPath: `/admin/share-item?${params}`,
    });
    return url.toString();
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;
    let shareItemParams;

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      shareItemParams = { shareId: queryParams.get("shareId") };
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
        params.email = userProfileData.email;
      } else {
        params.userId = returnData._id;
        params.email = returnData.email;
      }
      params.superuser = returnData.superuser;
      return params;
    };

    const handleRefresh = async (
      returnData = undefined,
      signal = undefined
    ) => {
      setRefreshDisabled(true);
      let shareItemData, userId, userEmail;
      if (!returnData) {
        try {
          const queryParams = await getQueryParams(signal);
          userId = queryParams.userId;
          userEmail = queryParams.email;
          const queryShareItemParams = {
            ...shareItemParams,
            userId,
          };
          const response = await ApiService.getShareItem(
            queryShareItemParams,
            signal
          );
          shareItemData = response.shareItem;

          const qrCode = await ApiService.getShareItemQrCode(
            {
              userId,
              frontendUrl: getFrontendUrl(shareItemParams),
              shareId: shareItemData._id,
            },
            signal
          );
          setShareItemQrCode(qrCode.qrCode);
        } catch (e) {
          console.error(e);
          props.history.push("/");
        }
      } else {
        shareItemData = returnData;
      }
      const users = {};
      try {
        if (!userId) {
          const queryParams = await getQueryParams(signal);
          userId = queryParams.userId;
          userEmail = queryParams.email;
        }
        const { address } = await ApiService.getPublicKey({ userId }, signal);
        const responseData = await ApiService.getAllUsers({}, signal);
        // eslint-disable-next-line no-unused-vars
        for (const entry of responseData) {
          users[entry.address] = entry;
        }
        setUsersList(
          responseData.filter((entry) => {
            return entry.address !== address;
          })
        );
        setUserAddress(address);
        setLookupUsers(users);
        setInviteActive(
          !!shareItemData.accessRights.find(
            (entry) =>
              entry.status === ApiService.accessRightsStatus.active &&
              entry.email === userEmail &&
              entry.type === ApiService.accessRightsTypes.invite
          )
        );
        setOwnerActive(address === shareItemData.sender);
      } catch (e) {
        console.error(e);
      }
      setShowVerificationDialog(false);
      setSignatureVerification({});
      setShareItemDetails(shareItemData);
      setShareItemParentId(
        shareItemData.parentId ? shareItemData.parentId : ""
      );
      setShareItemAccess(shareItemData.access);
      setUpdateShareItemTTL([]);
      setShareItemStoreForever([]);
      setShareItemTTL("-1");
      setShowShareItemProgress(false);
      setShareItemProgress(0);
      setUpdateShareItemButtonText(t("update-share-item-0"));
      setShareItemMetadataName("");
      setShareItemMetadataType(ApiService.metadataTypes.string);
      setShareItemMetadata("");
      setShareItemAccessRightsType(ApiService.accessRightsTypes.sign);
      setShareItemAccessRightsEmail("");
      setShareItemAccessRightsEmailUser("");
      setShareItemAccessRightsTTL("-1");
      setShareItemAccessRightsForever([]);
      setRefreshDisabled(false);
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh(undefined, signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.location.search, props.history, refreshClicked, t]);

  const handleShareItemUpdate = async (event) => {
    event.preventDefault();
    const { userId } = await getQueryParams();

    const parentId = shareItemParentId.trim();
    const updateParentId =
      (parentId === "" && shareItemDetails.parentId) ||
      (parentId !== "" && parentId !== shareItemDetails.parentId);
    const ttl =
      shareItemTTL === "-1" ? "-1" : shareItemTTL.endOf("day").toISOString();
    const validTTL = !(ttl === "-1" && shareItemStoreForever.length === 0);
    const updateTTL =
      updateShareItemTTL.length > 0 &&
      !(ttl === "-1" && !shareItemDetails.endOfLife);

    const checksPassed = !(updateShareItemTTL.length > 0 && !validTTL);
    if (!checksPassed) {
      const messageContent = [];
      if (!validTTL) {
        messageContent.push(t("invalid-eol-defined"));
      }
      return showShareItemError(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`
      );
    }
    const updateAccess = shareItemAccess !== shareItemDetails.access;

    if (!updateParentId && !updateTTL && !updateAccess) {
      return;
    }

    setRefreshDisabled(true);
    setUpdateShareItemButtonText(t("updating-share-item"));
    const abortController = new AbortController();
    const signal = abortController.signal;
    let error = false;
    // Set progress bar
    setShowShareItemProgress(true);
    setShareItemProgress(20);
    try {
      const params = {
        userId,
        shareId: shareItemDetails._id,
      };
      if (updateParentId) {
        params.parentId = parentId;
      }
      if (updateTTL) {
        params.endOfLife = ttl;
      }
      if (updateAccess) {
        params.access = shareItemAccess;
      }
      await ApiService.updateShareItem(params, signal);
      setShareItemProgress(100);
    } catch (e) {
      error = true;
      showShareItemError(t("error-updating-share-item"));
    }
    handleRefresh();
    if (!error) {
      return showShareItemSuccess(t("share-item-successfully-updated"));
    }
  };

  const deleteFileResult = (result, resultMessage, refresh = true) => {
    const handleDeletionResult = async () => {
      setAlert(null);
      if (refresh) {
        props.history.push("/");
      }
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleDeletionResult}
          onCancel={handleDeletionResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleDeletionResult}
          onCancel={handleDeletionResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const downloadFileError = (resultMessage) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("error")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        <Danger>{resultMessage}</Danger>
      </SweetAlert>
    );
  };

  const deleteFileAsync = (data) => {
    const handleFileDeletion = async () => {
      setAlert(null);
      try {
        const { userId } = await getQueryParams();
        await ApiService.deleteShareItem({
          userId,
          shareId: data._id,
        });
        deleteFileResult(true, t("share-item-successfully-deleted"));
      } catch (e) {
        deleteFileResult(false, e.message);
      }
    };
    handleFileDeletion();
  };

  const downloadFileAsync = (data) => {
    const handleDownloadFile = async () => {
      setAlert(null);
      try {
        const queryParams = await getQueryParams();
        const responseData = await ApiService.downloadShareItem({
          userId: queryParams.userId,
          shareId: data._id,
        });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(responseData);
        let extension;
        if (data.dataType === ApiService.shareItemDataType.json) {
          extension = "json";
        } else if (data.dataType === ApiService.shareItemDataType.xml) {
          extension = "xml";
        } else {
          extension = "txt";
        }
        a.download = `${data._id}.${extension}`;
        a.click();
      } catch (e) {
        downloadFileError(t("error-downloading-file"));
      }
    };
    handleDownloadFile();
  };

  const downloadReportAsync = (data) => {
    const handleDownloadReport = async () => {
      setAlert(null);
      const params = {
        shareId: data._id,
        shareSecret: data.shareSecret,
        creatorName: "SDC",
        documentTitle: "Certificate",
        reportTitle: "Certificate",
      };
      const responseData = await ApiService.downloadShareItemCertificate(
        params
      );
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = "certificate.pdf";
      a.click();
    };
    handleDownloadReport();
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const showDeleteFile = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-share-item")}?`}
        onConfirm={() => {
          deleteFileAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const showDownloadFile = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-share-item")}?`}
        onConfirm={() => {
          downloadFileAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const showDownloadReport = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-report")}?`}
        onConfirm={() => {
          downloadReportAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const shareItemResult = (result, resultMessage, redirect = true) => {
    const handleResult = async () => {
      setAlert(null);
      if (redirect) {
        props.history.push("/");
      }
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleResult}
          onCancel={handleResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={100}
            />
            <Muted>100%</Muted>
            <br />
          </React.Fragment>
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleResult}
          onCancel={handleResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={100}
            />
            <Muted>100%</Muted>
            <br />
          </React.Fragment>
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const deleteShareItemSignatureAsync = (data) => {
    const handleShareItemSignatureDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: data.parentId,
        parentType: data.type,
        signatureId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteSignature(params, signal);
        shareItemResult(
          true,
          t("share-item-signature-successfully-deleted"),
          false
        );
      } catch (e) {
        shareItemResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-share-item-signature")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleShareItemSignatureDeletion();
  };

  const showDeleteShareItemSignature = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-signature")}?`}
        onConfirm={() => {
          deleteShareItemSignatureAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const showVerifySignatureDialog = async (elem) => {
    try {
      const { userId } = await getQueryParams();
      const data = await ApiService.verifySignature({
        userId,
        signatureId: elem._id,
        parentId: elem.parentId,
        parentType: elem.type,
      });
      setSignatureVerification({
        _id: elem._id,
        verificationSteps: [
          {
            stage: t("input-data"),
            details: [
              ["parentId", elem.parentId],
              ["hash", elem.signedHash],
              ["metadataHash", elem.metadataHash],
              ["metadata", JSON.parse(elem.metadata)],
            ],
          },
          ...data.verificationSteps,
        ],
      });
      setShowVerificationDialog(true);
    } catch {
      showShareItemError(t("failed-to-fetch-share-item-signature"));
    }
  };

  const handleUpdateSignature = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const updateAccess = modalUpdateAccess !== signatureDetails.access;
    if (!updateAccess) {
      return clearModalState();
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      signatureId: signatureDetails._id,
      parentId: signatureDetails.parentId,
      parentType: signatureDetails.type,
      access: modalUpdateAccess,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("updating"));

    try {
      await ApiService.updateSignature(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("signature-successfully-updated"));
  };

  const handleShareItemSigning = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      parentId: shareItemDetails._id,
      parentType: ApiService.parentTypes.shareItem,
      access: shareItemDetails.access,
      blockchain: modalBlockchain,
      frontendUrl: getFrontendUrl(shareItemParams),
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    setSignatureAbortController(abortController);

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("processing"));

    // Sign share item
    let responseData;
    try {
      responseData = await ApiService.addSignature(params, signal);
    } catch (e) {
      return showErrorModalMessage(t("error-signing-share-item"));
    }
    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId, userId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        setModalProgress(responseData.progress);
        if (responseData.status === ApiService.jobStatus.failed) {
          return showErrorModalMessage(t("error-signing-share-item"));
        }
        if (
          responseData.status === ApiService.jobStatus.failedBlockchain ||
          responseData.status === ApiService.jobStatus.done
        ) {
          return showSuccessModalMessage(t("share-item-successfully-signed"));
        }
      }
      if (signal.aborted) {
        return showErrorModalMessage(t("operation-aborted"));
      }
    } catch (e) {
      return showErrorModalMessage(t("error-signing-share-item"));
    }
  };

  const handleDownloadMetadata = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-metadata")}?`}
        onConfirm={() => {
          downloadMetadata(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const downloadMetadata = async (elem) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const responseData = await ApiService.downloadMetadata({
        userId: queryParams.userId,
        metadataId: elem._id,
      });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      let extension;
      if (elem.type === ApiService.metadataTypes.json) {
        extension = "json";
      } else if (elem.type === ApiService.metadataTypes.xml) {
        extension = "xml";
      } else {
        extension = "txt";
      }
      a.download = `${elem._id}.${extension}`;
      a.click();
    } catch (e) {
      downloadError(e.message);
    }
  };

  const handleAddShareItemMetadata = async (event) => {
    event.preventDefault();
    const shareItemMetadataNameParsed = shareItemMetadataName.trim();
    if (shareItemMetadataNameParsed.length === 0) {
      return showShareItemError(t("share-item-metadata-name-field-empty"));
    }
    const shareItemMetadataParsed = shareItemMetadata.trim();
    if (shareItemMetadataParsed.length === 0) {
      return showShareItemError(t("share-item-metadata-field-empty"));
    }
    let metadata;
    try {
      metadata =
        shareItemMetadataType === ApiService.metadataTypes.json
          ? JSON.parse(shareItemMetadataParsed)
          : shareItemMetadataParsed;
    } catch (e) {
      return showShareItemError(t("share-item-invalid-metadata"));
    }
    setRefreshDisabled(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData = undefined;
    let error = false;
    try {
      const { userId } = await getQueryParams(signal);
      returnData = await ApiService.addMetadata(
        {
          userId,
          parentId: shareItemDetails._id,
          parentType: ApiService.parentTypes.shareItem,
          name: shareItemMetadataNameParsed,
          type: shareItemMetadataType,
          metadata,
        },
        signal
      );
    } catch (e) {
      error = true;
      showShareItemError(t("failed-to-add-share-item-metadata"));
    }
    handleRefresh(returnData, signal);
    if (!error) {
      return showShareItemSuccess(t("share-item-metadata-added-successfully"));
    }
  };

  const deleteShareItemMetadataAsync = (data) => {
    const handleShareItemMetadataDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: shareItemDetails._id,
        parentType: ApiService.parentTypes.shareItem,
        metadataId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteMetadata(params, signal);
        shareItemResult(
          true,
          t("share-item-metadata-successfully-deleted"),
          false
        );
      } catch (e) {
        shareItemResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-share-item-metadata")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleShareItemMetadataDeletion();
  };

  const showDeleteShareItemMetadata = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-metadata")}?`}
        onConfirm={() => {
          deleteShareItemMetadataAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const sendInviteAccessRightsAsync = (data) => {
    const handleSendInviteAccessRights = async () => {
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: shareItemDetails._id,
        parentType: ApiService.parentTypes.shareItem,
        accessRightsId: data._id,
        frontendUrl: getFrontendUrl(shareItemParams),
        registerUrl: ApiService.idFrontendURL,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.inviteAccessRights(params, signal);
        shareItemResult(true, t("invite-email-successfully-sent"), false);
      } catch (e) {
        shareItemResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("sending-email-invite")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleSendInviteAccessRights();
  };

  const showSendInviteAccessRights = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("send-an-email-invite")}?`}
        onConfirm={() => {
          sendInviteAccessRightsAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.email}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteShareItemAccessRightsAsync = (data) => {
    const handleShareItemAccessRightsDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: shareItemDetails._id,
        parentType: ApiService.parentTypes.shareItem,
        accessRightsId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteAccessRights(params, signal);
        shareItemResult(
          true,
          t("share-item-access-rights-successfully-deleted"),
          false
        );
      } catch (e) {
        shareItemResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-share-item-access-rights")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleShareItemAccessRightsDeletion();
  };

  const handleAddShareItemAccessRights = async (event) => {
    event.preventDefault();
    const email = shareItemAccessRightsEmail.trim();
    if (!validator.isEmail(email)) {
      return showShareItemError(t("share-item-access-rights-invalid-email"));
    }
    const ttl =
      shareItemAccessRightsTTL !== "-1"
        ? shareItemAccessRightsTTL.endOf("day").toISOString()
        : "-1";
    const validTTL = !(
      ttl === "-1" && shareItemAccessRightsForever.length === 0
    );
    if (!validTTL) {
      return showShareItemError(t("share-item-access-rights-invalid-eol"));
    }
    setRefreshDisabled(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData = undefined;
    let error = false;
    try {
      const { userId } = await getQueryParams(signal);
      returnData = await ApiService.addAccessRights(
        {
          userId,
          parentId: shareItemDetails._id,
          parentType: ApiService.parentTypes.shareItem,
          type: shareItemAccessRightsType,
          email,
          endOfLife: ttl,
        },
        signal
      );
    } catch (e) {
      error = true;
      showShareItemError(t("failed-to-add-share-item-access-rights"));
    }
    handleRefresh(returnData, signal);
    if (!error) {
      return showShareItemSuccess(
        t("share-item-access-rights-added-successfully")
      );
    }
  };

  const showDeleteShareItemAccessRights = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`$(t("delete-access-rights"))?`}
        onConfirm={() => {
          deleteShareItemAccessRightsAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.email}</b>
        </p>
      </SweetAlert>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={7}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <ListAltIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {`${t("share-item")}: ${
                  shareItemDetails._id
                } ${formattedUserName}`}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  color="rose"
                  round
                  simple
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                  disabled={refreshDisabled}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button>
                  <ListItemIcon>
                    <PeopleIcon style={{ color: roseColor[0] }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t("details")} />
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("id")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {shareItemDetails._id}
                    </GridItem>
                  </GridContainer>
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("sender")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {formatUserEntry(shareItemDetails.sender)}
                    </GridItem>
                  </GridContainer>
                </ListItem>
                <Divider />

                <ListItem button>
                  <ListItemIcon>
                    <InfoIcon style={{ color: roseColor[0] }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t("file-info")} />
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("data-type")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {shareItemDetails.dataType}
                    </GridItem>
                  </GridContainer>
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("resolving-type")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {shareItemDetails.resolvingType
                        ? shareItemDetails.resolvingType
                        : "-"}
                    </GridItem>
                  </GridContainer>
                </ListItem>
                {shareItemDetails.resolvingTags && (
                  <ListItem>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={4}
                        className={profileClasses.alignLeft}
                      >
                        {t("resolving-tags")}
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={8}
                        className={profileClasses.alignRight}
                      >
                        {shareItemDetails.resolvingTags.join(", ")}
                      </GridItem>
                    </GridContainer>
                  </ListItem>
                )}
              </List>
              <Divider />
              <ListItem button>
                <ListItemIcon>
                  <CheckCircleOutlineIcon style={{ color: roseColor[0] }} />
                </ListItemIcon>
                <ListItemText disableTypography primary={t("process")} />
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("parent-id")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {shareItemDetails.parentId
                      ? shareItemDetails.parentId
                      : "-"}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("item-id")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {shareItemDetails.itemId}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("status")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {shareItemDetails.status}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("sharing-service")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {shareItemDetails.sharingService}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("blockchain-store")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {shareItemDetails.blockchainStore
                      ? shareItemDetails.blockchainStore.toString()
                      : "false"}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("access")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {shareItemDetails.access}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("share-secret")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {shareItemDetails.shareSecret}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("data-link")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    <a
                      href={shareItemDetails.dataLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {shareItemDetails.dataLink}
                    </a>
                  </GridItem>
                </GridContainer>
              </ListItem>
              {shareItemDetails.rawLink && (
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("raw-link")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      <a
                        href={shareItemDetails.rawLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {shareItemDetails.rawLink}
                      </a>
                    </GridItem>
                  </GridContainer>
                </ListItem>
              )}
              <Divider />
              <ListItem button>
                <ListItemIcon>
                  <DateRangeIcon style={{ color: roseColor[0] }} />
                </ListItemIcon>
                <ListItemText disableTypography primary={t("date-and-time")} />
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("eol")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {ApiService.formatDateTime(shareItemDetails.endOfLife)}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("created-at")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {ApiService.formatDateTime(shareItemDetails.createdAt)}
                  </GridItem>
                </GridContainer>
              </ListItem>

              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("updated-at")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {ApiService.formatDateTime(shareItemDetails.updatedAt)}
                  </GridItem>
                </GridContainer>
              </ListItem>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={8}>
                  <img
                    height="155px"
                    src={shareItemQrCode}
                    alt={t("share-item-qrcode")}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {ownerActive && (
            <Card>
              <CardHeader color="turq" icon>
                <CardIcon color="turq">
                  <EditIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("edit-share-item")}
                </h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleShareItemUpdate}>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel
                        className={formClasses.labelHorizontal}
                        style={{ float: "left" }}
                      >
                        {t("parent-id")}
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        id="share-item-parent-id"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          value: shareItemParentId,
                          disabled: userAddress !== shareItemDetails.sender,
                          onChange: (e) => {
                            setShareItemParentId(e.target.value);
                          },
                        }}
                        helpText={t("change-parent-id")}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel
                        className={
                          formClasses.labelHorizontal +
                          " " +
                          formClasses.labelHorizontalRadioCheckbox
                        }
                        style={{ float: "left" }}
                      >
                        {t("change-eol")}
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <div className={formClasses.checkboxAndRadio}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={() => {
                                const currentIndex =
                                  updateShareItemTTL.indexOf(1);
                                const newChecked = [...updateShareItemTTL];
                                if (currentIndex === -1) {
                                  newChecked.push(1);
                                } else {
                                  newChecked.splice(currentIndex, 1);
                                }
                                setUpdateShareItemTTL(newChecked);
                              }}
                              checkedIcon={
                                <Check className={formClasses.checkedIcon} />
                              }
                              icon={
                                <Check className={formClasses.uncheckedIcon} />
                              }
                              classes={{
                                checked: formClasses.checked,
                                root: formClasses.checkRoot,
                              }}
                              checked={updateShareItemTTL.length > 0}
                            />
                          }
                          classes={{
                            label: formClasses.label,
                            root: formClasses.labelRoot,
                          }}
                          label={t("eol")}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {updateShareItemTTL.length > 0 && (
                      <React.Fragment>
                        <GridItem
                          xs={12}
                          sm={3}
                          style={{ float: "left" }}
                        ></GridItem>
                        <GridItem xs={12} sm={4} style={{ float: "left" }}>
                          <div className={formClasses.checkboxAndRadio}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => {
                                    if (updateShareItemTTL.length === 0) {
                                      return;
                                    }
                                    const currentIndex =
                                      shareItemStoreForever.indexOf(1);
                                    const newChecked = [
                                      ...shareItemStoreForever,
                                    ];
                                    if (currentIndex === -1) {
                                      newChecked.push(1);
                                    } else {
                                      newChecked.splice(currentIndex, 1);
                                    }
                                    setShareItemStoreForever(newChecked);
                                    if (newChecked.length > 0) {
                                      setShareItemTTL("-1");
                                    }
                                  }}
                                  checkedIcon={
                                    <Check
                                      className={formClasses.checkedIcon}
                                    />
                                  }
                                  icon={
                                    <Check
                                      className={formClasses.uncheckedIcon}
                                    />
                                  }
                                  classes={{
                                    checked: formClasses.checked,
                                    root: formClasses.checkRoot,
                                  }}
                                  checked={
                                    updateShareItemTTL.length > 0 &&
                                    shareItemStoreForever.length > 0
                                  }
                                />
                              }
                              classes={{
                                label: formClasses.label,
                                root: formClasses.labelRoot,
                              }}
                              label={t("store-forever")}
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={5} style={{ marginTop: "10px" }}>
                          <FormControl fullWidth>
                            <Datetime
                              dateFormat={"YYYY-MM-DD"}
                              timeFormat={false}
                              inputProps={{
                                placeholder: t("select-date"),
                                disabled:
                                  updateShareItemTTL.length === 0 ||
                                  shareItemStoreForever.length > 0,
                              }}
                              value={
                                shareItemTTL === "-1" ? undefined : shareItemTTL
                              }
                              onChange={(e) => {
                                setShareItemTTL(e);
                              }}
                              closeOnSelect
                            />
                          </FormControl>
                        </GridItem>
                      </React.Fragment>
                    )}
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel
                        className={
                          formClasses.labelHorizontal +
                          " " +
                          formClasses.labelHorizontalRadioCheckbox
                        }
                        style={{ float: "left", paddingTop: "40px" }}
                      >
                        {t("access")}
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9} className={formClasses.alignLeft}>
                      <FormControl
                        fullWidth
                        className={formClasses.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="share-time-access"
                          className={formClasses.selectLabel}
                        ></InputLabel>
                        <Select
                          MenuProps={{
                            className: formClasses.selectMenu,
                          }}
                          classes={{
                            select: formClasses.select,
                          }}
                          value={shareItemAccess}
                          onChange={(e) => setShareItemAccess(e.target.value)}
                          inputProps={{
                            name: "shareItemAccess",
                            id: "share-item-access",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: formClasses.selectMenuItem,
                            }}
                          >
                            {t("access")}
                          </MenuItem>
                          {Object.values(ApiService.shareItemAccess).map(
                            (accessType) => {
                              return (
                                <MenuItem
                                  key={accessType}
                                  classes={{
                                    root: formClasses.selectMenuItem,
                                    selected:
                                      formClasses.selectMenuItemSelectedMultiple,
                                  }}
                                  value={accessType}
                                >
                                  {accessType}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  {showShareItemProgress && (
                    <React.Fragment>
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={shareItemProgress}
                      />
                      <Muted>{shareItemProgress}%</Muted>
                      <br />
                    </React.Fragment>
                  )}
                  <Button
                    color="primary"
                    style={{ float: "right" }}
                    type="submit"
                    disabled={refreshDisabled}
                  >
                    {updateShareItemButtonText}
                  </Button>
                </form>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={8} md={8}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <LockIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("blockchain-info")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                className={`${profileClasses.description} ${profileClasses.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                  {t("txid")}
                </GridItem>
                <GridItem xs={12} sm={8} className={profileClasses.alignRight}>
                  {shareItemDetails.transactionStatus ===
                  ApiService.jobTransactionStatus.written ? (
                    <a
                      href={
                        ApiService.populatePath(ApiService.blockchainInfoURL, {
                          transactionId: shareItemDetails.transactionId,
                        }).populatedPath
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {shareItemDetails.transactionId}
                    </a>
                  ) : (
                    <React.Fragment>
                      {shareItemDetails.transactionId}
                    </React.Fragment>
                  )}
                </GridItem>
              </GridContainer>
              <Divider />

              <GridContainer
                className={`${profileClasses.description} ${profileClasses.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                  {t("status")}
                </GridItem>
                <GridItem xs={12} sm={8} className={profileClasses.alignRight}>
                  {shareItemDetails.transactionStatus}
                </GridItem>
              </GridContainer>
              <Divider />

              <GridContainer
                className={`${profileClasses.description} ${profileClasses.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                  {t("hash")}
                </GridItem>
                <GridItem xs={12} sm={8} className={profileClasses.alignRight}>
                  {shareItemDetails.hash}
                </GridItem>
              </GridContainer>
              <Divider />

              <GridContainer
                className={`${profileClasses.description} ${profileClasses.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                  {t("data-id")}
                </GridItem>
                <GridItem
                  xs={12}
                  sm={8}
                  className={`${profileClasses.alignRight}`}
                >
                  {shareItemDetails.data_id}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <SettingsIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("share-item-actions")}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                className={`${profileClasses.description}`}
                style={{ textAlign: "center" }}
                justifyContent="center"
              >
                <GridItem xs={2}>
                  <Tooltip title={t("sign-share-item-hash-0")}>
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        setConfirmModalButtonText(t("sign"));
                        setShowSignatureModal(true);
                      }}
                      color="rose"
                      className="edit"
                    >
                      <BorderColorIcon />
                    </Button>
                  </Tooltip>
                </GridItem>
                <GridItem xs={2}>
                  <Tooltip title={t("download-report")}>
                    <Button
                      justIcon
                      round
                      simple
                      disabled={
                        refreshDisabled ||
                        shareItemDetails.resolvingType !==
                          ApiService.shareItemResolvingType.item
                      }
                      onClick={() => {
                        showDownloadReport(shareItemDetails);
                      }}
                      color="rose"
                      className="remove"
                    >
                      <AssessmentIcon />
                    </Button>
                  </Tooltip>
                </GridItem>
                <GridItem xs={2}>
                  <Tooltip title={t("view-details")}>
                    <Button
                      justIcon
                      round
                      simple
                      disabled={refreshDisabled}
                      color="rose"
                      className="remove"
                      onClick={async () => {
                        try {
                          const queryParams = await getQueryParams();
                          const params = {
                            shareId: shareItemDetails._id,
                            userId: queryParams.userId,
                          };
                          const responseData = await ApiService.getShareItem(
                            params
                          );
                          setShareItemDialogData(responseData);
                          setShowShareItemDialog(true);
                        } catch (e) {
                          console.error(e);
                        }
                      }}
                    >
                      <VisibilityIcon />
                    </Button>
                  </Tooltip>
                </GridItem>
                <GridItem xs={2}>
                  <Tooltip title={t("download-share-item")}>
                    <Button
                      justIcon
                      round
                      simple
                      disabled={refreshDisabled}
                      onClick={() => {
                        showDownloadFile(shareItemDetails);
                      }}
                      color="rose"
                      className="remove"
                    >
                      <CloudDownloadIcon />
                    </Button>
                  </Tooltip>
                </GridItem>
                {ownerActive && (
                  <GridItem xs={2}>
                    <Tooltip title={t("delete-share-item")}>
                      <Button
                        justIcon
                        round
                        simple
                        color="rose"
                        className="remove"
                        disabled={refreshDisabled}
                        onClick={() => {
                          showDeleteFile(shareItemDetails);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <NavPills
            color="info"
            alignCenter
            tabs={[
              {
                tabButton: t("signatures"),
                tabIcon: ReceiptIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <ReceiptIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("signatures")}
                      </h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          round
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => {
                            handleRefresh();
                          }}
                          disabled={refreshDisabled}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={
                          "signatures" in shareItemDetails
                            ? shareItemDetails.signatures.map((elem) => {
                                return {
                                  _id: elem._id,
                                  access: elem.access,
                                  parentId: elem.parentId,
                                  sender: formatUserEntry(elem.sender),
                                  type: elem.type,
                                  signedHash: elem.signedHash,
                                  metadataHash: elem.metadataHash,
                                  transactionId: elem.transactionId,
                                  transactionStatus: elem.transactionStatus,
                                  actions: (
                                    <div className="actions-right">
                                      <Tooltip
                                        title={t("signature-verification")}
                                      >
                                        <Button
                                          justIcon
                                          round
                                          simple
                                          onClick={() => {
                                            showVerifySignatureDialog(elem);
                                          }}
                                          color="rose"
                                          className="remove"
                                        >
                                          <ReceiptIcon />
                                        </Button>
                                      </Tooltip>
                                      {elem.transactionStatus ===
                                      ApiService.jobTransactionStatus
                                        .written ? (
                                        <a
                                          href={
                                            ApiService.populatePath(
                                              ApiService.blockchainInfoURL,
                                              {
                                                transactionId:
                                                  elem.transactionId,
                                              }
                                            ).populatedPath
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Tooltip
                                            title={t("blockchain-info-url")}
                                          >
                                            <Button
                                              justIcon
                                              round
                                              simple
                                              color="rose"
                                              className="remove"
                                            >
                                              <InfoIcon />
                                            </Button>
                                          </Tooltip>
                                        </a>
                                      ) : (
                                        <Tooltip
                                          title={t("blockchain-info-url")}
                                        >
                                          <Button
                                            justIcon
                                            round
                                            simple
                                            color="rose"
                                            className="remove"
                                            disabled
                                          >
                                            <InfoIcon />
                                          </Button>
                                        </Tooltip>
                                      )}
                                      {ownerActive && (
                                        <React.Fragment>
                                          <Tooltip
                                            title={t("update-signature")}
                                          >
                                            <Button
                                              justIcon
                                              round
                                              simple
                                              color="rose"
                                              className="edit"
                                              onClick={() => {
                                                setSignatureDetails(elem);
                                                setModalUpdateAccess(
                                                  elem.access
                                                );
                                                setShowModal(true);
                                              }}
                                            >
                                              <EditIcon />
                                            </Button>
                                          </Tooltip>
                                          <Tooltip
                                            title={t("delete-signature")}
                                          >
                                            <Button
                                              justIcon
                                              round
                                              simple
                                              className="remove"
                                              onClick={() => {
                                                showDeleteShareItemSignature(
                                                  elem
                                                );
                                              }}
                                            >
                                              <ClearIcon
                                                style={{ color: grayColor[17] }}
                                              />
                                            </Button>
                                          </Tooltip>
                                        </React.Fragment>
                                      )}
                                    </div>
                                  ),
                                };
                              })
                            : []
                        }
                        filterable
                        columns={[
                          {
                            Header: t("id"),
                            accessor: "_id",
                          },
                          {
                            Header: t("access"),
                            accessor: "access",
                          },
                          {
                            Header: t("user"),
                            accessor: "sender",
                          },
                          {
                            Header: t("type"),
                            accessor: "type",
                          },
                          {
                            Header: t("signed-hash"),
                            accessor: "signedHash",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("metadata-hash"),
                            accessor: "metadataHash",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("txid"),
                            accessor: "transactionId",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("status"),
                            accessor: "transactionStatus",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={6}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("metadata"),
                tabIcon: LibraryBooksIcon,
                tabContent: (
                  <Card>
                    <CardBody>
                      <NavPills
                        color="rose"
                        horizontal={{
                          tabsGrid: { xs: 12, sm: 12, md: 2 },
                          contentGrid: { xs: 12, sm: 12, md: 10 },
                        }}
                        tabs={[
                          {
                            tabIcon: LibraryBooksIcon,
                            tabContent: (
                              <React.Fragment>
                                <ReactTable
                                  data={
                                    "metadata" in shareItemDetails
                                      ? shareItemDetails.metadata.map(
                                          (elem) => {
                                            return {
                                              id: elem._id,
                                              user: formatUserEntry(elem.user),
                                              date: elem.date,
                                              type: elem.type,
                                              name: elem.name,
                                              metadata: elem.metadata,
                                              actions: (
                                                <div className="actions-right">
                                                  <Tooltip
                                                    title={t(
                                                      "download-metadata"
                                                    )}
                                                  >
                                                    <Button
                                                      justIcon
                                                      round
                                                      simple
                                                      color="rose"
                                                      className="edit"
                                                      onClick={() => {
                                                        handleDownloadMetadata(
                                                          elem
                                                        );
                                                      }}
                                                    >
                                                      <CloudDownloadIcon />
                                                    </Button>
                                                  </Tooltip>
                                                  {ownerActive && (
                                                    <Tooltip
                                                      title={t(
                                                        "delete-metadata"
                                                      )}
                                                    >
                                                      <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        className="remove"
                                                        onClick={() => {
                                                          showDeleteShareItemMetadata(
                                                            elem
                                                          );
                                                        }}
                                                      >
                                                        <ClearIcon
                                                          style={{
                                                            color:
                                                              grayColor[17],
                                                          }}
                                                        />
                                                      </Button>
                                                    </Tooltip>
                                                  )}
                                                </div>
                                              ),
                                            };
                                          }
                                        )
                                      : []
                                  }
                                  columns={[
                                    {
                                      Header: t("user"),
                                      accessor: "user",
                                    },
                                    {
                                      Header: t("date"),
                                      accessor: "date",
                                      headerStyle: { textAlign: "right" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "right" }}>
                                          {ApiService.formatDateTime(row.value)}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("type"),
                                      accessor: "type",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("name"),
                                      accessor: "name",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("metadata"),
                                      accessor: "metadata",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("actions"),
                                      accessor: "actions",
                                      sortable: false,
                                      filterable: false,
                                    },
                                  ]}
                                  filterable
                                  defaultPageSize={8}
                                  showPaginationTop={false}
                                  showPaginationBottom
                                  className="-striped -highlight"
                                />
                              </React.Fragment>
                            ),
                          },
                          ownerActive
                            ? {
                                tabIcon: LibraryAddIcon,
                                tabContent: (
                                  <form onSubmit={handleAddShareItemMetadata}>
                                    <h4 style={{ marginBottom: "15px" }}>
                                      {t("enter-your-metadata")}
                                    </h4>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {t("name")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <CustomInput
                                          id="doc-container-1-title"
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            type: "text",
                                            value: shareItemMetadataName,
                                            onChange: (e) => {
                                              setShareItemMetadataName(
                                                e.target.value
                                              );
                                            },
                                          }}
                                          helpText={t("metadata-name")}
                                        />
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("type")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <FormControl
                                          fullWidth
                                          className={
                                            formClasses.selectFormControl
                                          }
                                        >
                                          <InputLabel
                                            htmlFor="data-type"
                                            className={formClasses.selectLabel}
                                          ></InputLabel>
                                          <Select
                                            value={shareItemMetadataType}
                                            onChange={(e) => {
                                              setShareItemMetadataType(
                                                e.target.value
                                              );
                                            }}
                                            MenuProps={{
                                              className: formClasses.selectMenu,
                                            }}
                                            classes={{
                                              select: formClasses.select,
                                            }}
                                            inputProps={{
                                              name: "metadataType",
                                              id: "metadata-type",
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                              }}
                                            >
                                              {t("data-type")}
                                            </MenuItem>
                                            {Object.values(
                                              ApiService.metadataTypes
                                            ).map((metadataType) => {
                                              return (
                                                <MenuItem
                                                  key={metadataType}
                                                  classes={{
                                                    root: formClasses.selectMenuItem,
                                                    selected:
                                                      formClasses.selectMenuItemSelectedMultiple,
                                                  }}
                                                  value={metadataType}
                                                >
                                                  {metadataType}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("metadata")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <TextField
                                          required
                                          id="outlined-required"
                                          variant="outlined"
                                          fullWidth
                                          value={shareItemMetadata}
                                          multiline
                                          rows={3}
                                          onChange={(e) =>
                                            setShareItemMetadata(e.target.value)
                                          }
                                        />
                                      </GridItem>
                                    </GridContainer>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      disabled={refreshDisabled}
                                      style={{
                                        float: "right",
                                        marginTop: "15px",
                                      }}
                                    >
                                      {t("add-metadata")}
                                    </Button>
                                  </form>
                                ),
                              }
                            : undefined,
                        ].filter((x) => x !== undefined)}
                      />
                    </CardBody>
                  </Card>
                ),
              },
              ownerActive || inviteActive
                ? {
                    tabButton: t("access-rights"),
                    tabIcon: LockIcon,
                    tabContent: (
                      <Card>
                        <CardBody>
                          <NavPills
                            color="rose"
                            horizontal={{
                              tabsGrid: { xs: 12, sm: 12, md: 2 },
                              contentGrid: { xs: 12, sm: 12, md: 10 },
                            }}
                            tabs={[
                              {
                                tabIcon: LockIcon,
                                tabContent: (
                                  <React.Fragment>
                                    <ReactTable
                                      data={
                                        "accessRights" in shareItemDetails
                                          ? shareItemDetails.accessRights.map(
                                              (elem) => {
                                                return {
                                                  id: elem._id,
                                                  type: elem.type,
                                                  email: elem.email,
                                                  invited:
                                                    elem.invited.toString(),
                                                  endOfLife: elem.endOfLife,
                                                  actions: (
                                                    <div className="actions-right">
                                                      <Tooltip
                                                        title={t(
                                                          "send-an-email-invite"
                                                        )}
                                                      >
                                                        <Button
                                                          justIcon
                                                          round
                                                          simple
                                                          color="rose"
                                                          className="edit"
                                                          onClick={() => {
                                                            showSendInviteAccessRights(
                                                              elem
                                                            );
                                                          }}
                                                        >
                                                          <EmailIcon />
                                                        </Button>
                                                      </Tooltip>
                                                      <Tooltip
                                                        title={t(
                                                          "delete-access-rights"
                                                        )}
                                                      >
                                                        <Button
                                                          justIcon
                                                          round
                                                          simple
                                                          className="remove"
                                                          onClick={() => {
                                                            showDeleteShareItemAccessRights(
                                                              elem
                                                            );
                                                          }}
                                                        >
                                                          <ClearIcon
                                                            style={{
                                                              color:
                                                                grayColor[17],
                                                            }}
                                                          />
                                                        </Button>
                                                      </Tooltip>
                                                    </div>
                                                  ),
                                                };
                                              }
                                            )
                                          : []
                                      }
                                      columns={[
                                        {
                                          Header: t("email"),
                                          accessor: "email",
                                        },
                                        {
                                          Header: t("type"),
                                          accessor: "type",
                                        },
                                        {
                                          Header: t("invited"),
                                          accessor: "invited",
                                        },
                                        {
                                          Header: t("eol"),
                                          accessor: "endOfLife",
                                          headerStyle: { textAlign: "right" },
                                          Cell: (row) => (
                                            <div style={{ textAlign: "right" }}>
                                              {ApiService.formatDateTime(
                                                row.value
                                              )}
                                            </div>
                                          ),
                                        },
                                        {
                                          Header: t("actions"),
                                          accessor: "actions",
                                          sortable: false,
                                          filterable: false,
                                        },
                                      ]}
                                      filterable
                                      defaultPageSize={8}
                                      showPaginationTop={false}
                                      showPaginationBottom
                                      className="-striped -highlight"
                                    />
                                  </React.Fragment>
                                ),
                              },
                              {
                                tabIcon: EnhancedEncryptionIcon,
                                tabContent: (
                                  <form
                                    onSubmit={handleAddShareItemAccessRights}
                                  >
                                    <h4 style={{ marginBottom: "15px" }}>
                                      {t("add-access-rights")}
                                    </h4>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {t("eol")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem
                                        xs={12}
                                        sm={2}
                                        style={{ float: "left" }}
                                      >
                                        <div
                                          className={
                                            formClasses.checkboxAndRadio
                                          }
                                        >
                                          <FormControlLabel
                                            style={{ float: "left" }}
                                            control={
                                              <Checkbox
                                                tabIndex={-1}
                                                onClick={() => {
                                                  const currentIndex =
                                                    shareItemAccessRightsForever.indexOf(
                                                      1
                                                    );
                                                  const newChecked = [
                                                    ...shareItemAccessRightsForever,
                                                  ];
                                                  if (currentIndex === -1) {
                                                    newChecked.push(1);
                                                  } else {
                                                    newChecked.splice(
                                                      currentIndex,
                                                      1
                                                    );
                                                  }
                                                  setShareItemAccessRightsForever(
                                                    newChecked
                                                  );
                                                  if (newChecked.length > 0) {
                                                    setShareItemAccessRightsTTL(
                                                      "-1"
                                                    );
                                                  }
                                                }}
                                                checkedIcon={
                                                  <Check
                                                    className={
                                                      formClasses.checkedIcon
                                                    }
                                                  />
                                                }
                                                icon={
                                                  <Check
                                                    className={
                                                      formClasses.uncheckedIcon
                                                    }
                                                  />
                                                }
                                                classes={{
                                                  checked: formClasses.checked,
                                                  root: formClasses.checkRoot,
                                                }}
                                                checked={
                                                  shareItemAccessRightsForever.length >
                                                  0
                                                }
                                              />
                                            }
                                            classes={{
                                              label: formClasses.label,
                                              root: formClasses.labelRoot,
                                            }}
                                            label={t("forever")}
                                          />
                                        </div>
                                      </GridItem>
                                      <GridItem xs={12} sm={4}>
                                        <div
                                          className={
                                            formClasses.checkboxAndRadio
                                          }
                                        >
                                          <FormControl fullWidth>
                                            <Datetime
                                              dateFormat={"YYYY-MM-DD"}
                                              timeFormat={false}
                                              inputProps={{
                                                placeholder: t("select-date"),
                                                disabled:
                                                  shareItemAccessRightsForever.length >
                                                  0,
                                              }}
                                              value={
                                                shareItemAccessRightsTTL ===
                                                "-1"
                                                  ? undefined
                                                  : shareItemAccessRightsTTL
                                              }
                                              onChange={(e) => {
                                                setShareItemAccessRightsTTL(e);
                                              }}
                                              closeOnSelect
                                            />
                                          </FormControl>
                                        </div>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("type")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={6}>
                                        <FormControl
                                          fullWidth
                                          className={
                                            formClasses.selectFormControl
                                          }
                                        >
                                          <InputLabel
                                            htmlFor="access-rights-type"
                                            className={formClasses.selectLabel}
                                          ></InputLabel>
                                          <Select
                                            value={shareItemAccessRightsType}
                                            onChange={(e) => {
                                              setShareItemAccessRightsType(
                                                e.target.value
                                              );
                                            }}
                                            MenuProps={{
                                              className: formClasses.selectMenu,
                                            }}
                                            classes={{
                                              select: formClasses.select,
                                            }}
                                            inputProps={{
                                              name: "accessRightsType",
                                              id: "access-rights-type",
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                              }}
                                            >
                                              {t("type")}
                                            </MenuItem>
                                            {Object.values(
                                              ApiService.accessRightsTypes
                                            ).map((accessRightsType) => {
                                              return (
                                                <MenuItem
                                                  key={accessRightsType}
                                                  classes={{
                                                    root: formClasses.selectMenuItem,
                                                    selected:
                                                      formClasses.selectMenuItemSelectedMultiple,
                                                  }}
                                                  value={accessRightsType}
                                                >
                                                  {accessRightsType}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {t("email")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={6}>
                                        <FormControl
                                          fullWidth
                                          className={
                                            formClasses.selectFormControl
                                          }
                                        >
                                          <InputLabel
                                            htmlFor="access-rights-email"
                                            className={formClasses.selectLabel}
                                          ></InputLabel>
                                          <Select
                                            value={
                                              shareItemAccessRightsEmailUser
                                            }
                                            onChange={(e) => {
                                              const selected = e.target.value;
                                              if (selected === otherUser) {
                                                setShareItemAccessRightsEmail(
                                                  ""
                                                );
                                              } else {
                                                setShareItemAccessRightsEmail(
                                                  selected
                                                );
                                              }
                                              setShareItemAccessRightsEmailUser(
                                                selected
                                              );
                                            }}
                                            MenuProps={{
                                              className: formClasses.selectMenu,
                                            }}
                                            classes={{
                                              select: formClasses.select,
                                            }}
                                            inputProps={{
                                              name: "accessRightsEmail",
                                              id: "access-rights-email",
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                              }}
                                            >
                                              {t("email")}
                                            </MenuItem>
                                            {usersList.map((entry) => {
                                              return (
                                                <MenuItem
                                                  key={entry.user._id}
                                                  classes={{
                                                    root: formClasses.selectMenuItem,
                                                    selected:
                                                      formClasses.selectMenuItemSelectedMultiple,
                                                  }}
                                                  value={entry.user.email}
                                                >
                                                  {formatUserEntry(
                                                    entry.address
                                                  )}
                                                </MenuItem>
                                              );
                                            })}
                                            <MenuItem
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                                selected:
                                                  formClasses.selectMenuItemSelectedMultiple,
                                              }}
                                              value={otherUser}
                                            >
                                              {t("other")}
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    </GridContainer>
                                    {shareItemAccessRightsEmailUser ===
                                      otherUser && (
                                      <GridContainer>
                                        <GridItem xs={12} sm={1}>
                                          <FormLabel
                                            className={
                                              formClasses.labelHorizontal
                                            }
                                            style={{ float: "left" }}
                                          ></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={3}>
                                          <CustomInput
                                            id="email"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              type: "text",
                                              value: shareItemAccessRightsEmail,
                                              onChange: (e) => {
                                                setShareItemAccessRightsEmail(
                                                  e.target.value
                                                );
                                              },
                                            }}
                                            helpText={t("email")}
                                          />
                                        </GridItem>
                                      </GridContainer>
                                    )}
                                    <GridContainer>
                                      <GridItem xs={12} sm={7}>
                                        <Button
                                          color="primary"
                                          type="submit"
                                          disabled={refreshDisabled}
                                          style={{
                                            float: "right",
                                            marginTop: "15px",
                                          }}
                                        >
                                          {t("add-access")}
                                        </Button>
                                      </GridItem>
                                    </GridContainer>
                                  </form>
                                ),
                              },
                            ]}
                          />
                        </CardBody>
                      </Card>
                    ),
                  }
                : undefined,
            ].filter((x) => x !== undefined)}
          />
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={shareItemSuccessMessage}
        open={shareItemSuccessMessage.length > 0}
        closeNotification={() => showShareItemSuccess("")}
        close
      />
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={shareItemErrorMessage}
        open={shareItemErrorMessage.length > 0}
        closeNotification={() => showShareItemError("")}
        close
      />
      <Dialog
        classes={{
          root: dialogClasses.center,
          paper: dialogClasses.modal,
        }}
        open={showVerificationDialog}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth={true}
        maxWidth="md"
        onClose={() => setShowVerificationDialog(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <Button
            justIcon
            className={dialogClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowVerificationDialog(false)}
          >
            <Close className={dialogClasses.modalClose} />
          </Button>
          <h4 className={dialogClasses.modalTitle}>
            {t("signature-verification-0")}: <b>{signatureVerification._id}</b>
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={dialogClasses.modalBody}
        >
          {signatureVerification.verificationSteps &&
            signatureVerification.verificationSteps.map((element) => {
              return (
                <React.Fragment key={uuidv4()}>
                  <GridContainer
                    className={dialogClasses.cardMargin}
                    key={uuidv4()}
                    style={{ fontWeight: "600" }}
                  >
                    <GridItem xs={12} sm={6} md={4}>
                      {t("stage")}
                    </GridItem>
                    <GridItem xs={12} sm={6} md={8}>
                      {element.stage.toUpperCase()}
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    style={{
                      borderBottom: "1px solid #c4c4c4",
                      paddingBottom: "15px",
                    }}
                  >
                    {element.details.map(([fieldName, fieldValue]) => {
                      let data;
                      if (typeof fieldValue === "object") {
                        data = (
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            {JSON.stringify(fieldValue, undefined, 2)}
                          </div>
                        );
                      } else {
                        data = (
                          <React.Fragment>
                            {fieldValue.toString()}
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment key={uuidv4()}>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={4}
                            style={{ fontWeight: "400" }}
                          >
                            {fieldName}
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={8}
                            className={dialogClasses.wordBreak}
                          >
                            {ApiService.checkValidLink(fieldValue) ? (
                              <a
                                href={fieldValue}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {fieldValue}
                              </a>
                            ) : (
                              data
                            )}
                          </GridItem>
                        </React.Fragment>
                      );
                    })}
                  </GridContainer>
                </React.Fragment>
              );
            })}
        </DialogContent>
        <DialogActions
          className={
            dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
          }
        >
          <Button
            onClick={() => setShowVerificationDialog(false)}
            color="rose"
            simple
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("update-signature-0")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleUpdateSignature}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("access")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="access-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalUpdateAccess}
                    onChange={(e) => {
                      setModalUpdateAccess(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "accessType",
                      id: "access-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("access")}
                    </MenuItem>
                    {Object.values(ApiService.signatureAccess).map(
                      (accessType) => {
                        return (
                          <MenuItem
                            key={accessType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={accessType}
                          >
                            {accessType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: dialogClasses.center,
          paper: dialogClasses.modal,
        }}
        open={showShareItemDialog}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth={true}
        maxWidth="md"
        onClose={() => setShowShareItemDialog(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <Button
            justIcon
            className={dialogClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowShareItemDialog(false)}
          >
            <Close className={dialogClasses.modalClose} />
          </Button>
          <h4 className={dialogClasses.modalTitle}>
            {t("share-item")}:
            <b>
              {shareItemDialogData.shareItem
                ? shareItemDialogData.shareItem._id
                : ""}
            </b>
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={dialogClasses.modalBody}
        >
          <GridContainer>
            <GridItem className={dialogClasses.wordBreak}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(shareItemDialogData, undefined, 2)}
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={
            dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
          }
        >
          <Button
            onClick={() => setShowShareItemDialog(false)}
            color="rose"
            simple
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showSignatureModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("sign-share-item-hash-0")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleShareItemSigning}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("blockchain")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="blockchain-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalBlockchain}
                    onChange={(e) => {
                      setModalBlockchain(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "blockchainType",
                      id: "blockchain-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("blockchain")}
                    </MenuItem>
                    {Object.values(ApiService.blockchainTypes).map(
                      (blockchainType) => {
                        return (
                          <MenuItem
                            key={blockchainType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={blockchainType}
                          >
                            {blockchainType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                if (signatureAbortController) {
                  signatureAbortController.abort();
                }
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {alert}
    </div>
  );
};

ShareItemDetails.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default ShareItemDetails;
