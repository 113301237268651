/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
// material-ui icons
import Close from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
// core components
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/publicSharedPackageStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Danger from "components/Typography/Danger.js";
import Muted from "components/Typography/Muted.js";
import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "components/Table/Table.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import AppsIcon from "@material-ui/icons/Apps";
import SendIcon from "@material-ui/icons/Send";
import LockIcon from "@material-ui/icons/Lock";
import DataUsage from "@material-ui/icons/DataUsage";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import NavPills from "components/NavPills/NavPills.js";
import PDFViewer from "../Components/PDFViewer";
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";

const useStylesDialog = makeStyles(modalStyles);
const cardStyles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
  },
  wordBreak: {
    wordBreak: "break-all",
  },
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

const useAlertStyles = makeStyles(sweetAlertStyles);

const PublicSharedPackage = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cardClasses = useCardStyles();
  const alertClasses = useAlertStyles();
  const classesDialog = useStylesDialog();

  const [modal, setModal] = useState(false);
  const [packageDetails, setPackageDetails] = useState({});
  const [packageSignature, setPackageSignature] = useState({});
  const [renderingData, setRenderingData] = useState({});
  const [alert, setAlert] = useState(null);

  const [pdfFile, setPdfFile] = useState({ data: new Uint8Array(0) });
  const [navPillsIndex, setNavPillsIndex] = useState(0);
  const [preview, setPreview] = useState("");

  React.useEffect(() => {
    let isMounted = true;
    const query = new URLSearchParams(props.location.search);
    const params = {
      packageId: query.get("packageId"),
      publicShareSecret: query.get("publicShareSecret"),
    };
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        const packageDetailsData = await ApiService.getPublicPackageDetails(
          params,
          signal
        );
        const packageSignature = await ApiService.getPublicPackageSignature(
          params,
          signal
        );
        if (packageDetailsData.package.blockchainStore) {
          const decryptionKey = await ApiService.getPublicPackageDecryptionKey(
            params,
            signal
          );
          const transactionChunks =
            await ApiService.getPublicPackageTransactionChunks(params, signal);
          packageDetailsData.decryptionKey = decryptionKey;
          packageDetailsData.transactionChunks = transactionChunks;
        }
        if (
          packageDetailsData.package.transactionStatus ===
          ApiService.jobTransactionStatus.written
        ) {
          packageDetailsData.package.transactionInfo = ApiService.populatePath(
            ApiService.blockchainInfoURL,
            {
              transactionId: packageDetailsData.package.transactionId,
            }
          ).populatedPath;
        } else {
          packageDetailsData.package.transactionInfo =
            packageDetailsData.package.transactionStatus;
        }
        setPackageDetails(packageDetailsData.package);
        setPackageSignature(packageSignature);
        setRenderingData(packageDetailsData);
      } catch (e) {
        props.history.push("/");
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.history, props.location.search]);

  const downloadPackageError = (resultMessage, responseData = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("error")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {responseData && (
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={responseData.progress}
            />
            <Muted>
              {responseData.jobId} - {responseData.progress}% (
              {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
            </Muted>
            <br />
          </React.Fragment>
        )}
        <Danger>{resultMessage}</Danger>
      </SweetAlert>
    );
  };

  const downloadPackageAsync = (data) => {
    const handleDownloadPackage = async (parameters, signal) => {
      const params = {
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadPublicFile(params, signal);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      setAlert(null);
      a.click();
    };
    const handlePreparePackageDownload = async () => {
      // Deletion parameters
      const params = {
        packageId: data.package._id,
        publicShareSecret: data.package.publicShareSecret,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.preparePublicDownloadPackageFile(
          params,
          signal
        );
      } catch (e) {
        return downloadPackageError(t("error-downloading-package"));
      }
      const jobId = responseData.jobId;
      try {
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestPublicDownloadJobStatus(
            { jobId },
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            return downloadPackageError(
              t("error-downloading-package"),
              responseData
            );
          }
          if (
            responseData.status === ApiService.jobStatus.failedBlockchain ||
            responseData.status === ApiService.jobStatus.done
          ) {
            await handleDownloadPackage(responseData, signal);
          }
        }
        if (signal.aborted) {
          return downloadPackageError(t("operation-aborted"), responseData);
        }
      } catch (e) {
        return downloadPackageError(
          t("error-downloading-package"),
          responseData
        );
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("preparing-package")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePreparePackageDownload();
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const showDownloadPackage = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-package")}?`}
        onConfirm={() => {
          downloadPackageAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.title}</b>
        </p>
      </SweetAlert>
    );
  };

  const packageDetailsBlock = (
    <React.Fragment>
      {!ApiService.isObjectEmpty(renderingData) && (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
                <CardIcon color="turq">
                  <AppsIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={cardClasses.cardIconTitle}>
                  {t("package-info")}
                </h4>
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    round
                    onClick={() => {
                      setModal(true);
                    }}
                    color="primary"
                    className="remove"
                    style={{ marginTop: "20px" }}
                  >
                    {t("signature-verification")}
                  </Button>
                  <Button
                    round
                    onClick={() => {
                      showDownloadPackage(renderingData);
                    }}
                    color="rose"
                    className="remove"
                    style={{ marginTop: "20px", marginLeft: "10px" }}
                  >
                    {t("download")}
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  hover
                  tableData={[
                    [t("package-title"), renderingData.package.title],
                    [t("package-message"), renderingData.package.message],
                    [
                      t("package-comments"),
                      renderingData.package.comments
                        .map((comment) => {
                          return comment.comment;
                        })
                        .join(" "),
                    ],
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card>
              <CardHeader color="turq" icon>
                <CardIcon color="turq">
                  <VpnKeyIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={cardClasses.cardIconTitle}>
                  {t("signature-verification")}
                </h4>
              </CardHeader>
              <CardBody>
                <Table
                  hover
                  tableData={[
                    [t("signature-verification-0"), t("success-0")],
                    [
                      t("signature-verify-link"),
                      renderingData.package.verifyLink,
                    ],
                  ]}
                  customCellClasses={[classes.wordBreak]}
                  customClassesForCells={[1]}
                />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card>
              <CardHeader color="turq" icon>
                <CardIcon color="turq">
                  <SendIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={cardClasses.cardIconTitle}>
                  {t("sender-info")}
                </h4>
              </CardHeader>
              <CardBody>
                <Table
                  hover
                  tableData={[
                    [t("sender-name"), renderingData.sender.name],
                    [t("sender-email-address"), renderingData.sender.email],
                    [t("sender-bsv-address"), renderingData.sender.address],
                    [
                      t("sender-public-key-hex"),
                      renderingData.sender.publicKey,
                    ],
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card>
              <CardHeader color="turq" icon>
                <CardIcon color="turq">
                  <FileCopyIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={cardClasses.cardIconTitle}>
                  {t("file-info-0")}
                </h4>
              </CardHeader>
              <CardBody>
                <Table
                  hover
                  tableData={[
                    [t("file-name"), renderingData.package.fileName],
                    [t("data-type"), renderingData.package.dataType],
                    [t("file-size"), renderingData.package.fileSize],
                    [t("data-id"), renderingData.package.data_id],
                    [t("hash-sha256-base64"), renderingData.package.hash],
                    [
                      t("eol"),
                      ApiService.formatDateTime(
                        renderingData.package.endOfLife
                      ),
                    ],
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card>
              <CardHeader color="turq" icon>
                <CardIcon color="turq">
                  <LockIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={cardClasses.cardIconTitle}>
                  {t("blockchain-info-0")}
                </h4>
              </CardHeader>
              <CardBody>
                <Table
                  hover
                  tableData={[
                    [
                      `${ApiService.productName} ${t("bsv-address")}`,
                      renderingData.publicShareUser.storeAddress,
                    ],
                    [
                      `${ApiService.productName} ${t("public-key-hex")}`,
                      renderingData.publicShareUser.storePublicKey,
                    ],
                    [
                      t("blockchain-transaction-decryption-key-wif"),
                      renderingData.publicShareUser.decryptionKey,
                    ],
                    [
                      t("blockchain-transaction-info"),
                      renderingData.package.transactionInfo,
                    ],
                    [
                      t("blockchain-transaction-id"),
                      renderingData.package.transactionId,
                    ],
                    [
                      t("blockchain-transaction"),
                      renderingData.package.transaction,
                    ],
                  ]}
                  customCellClasses={[classes.wordBreak]}
                  customClassesForCells={[1]}
                />
              </CardBody>
            </Card>
          </GridItem>

          {renderingData.package.blockchainStore && (
            <GridItem xs={12}>
              <Card>
                <CardHeader color="turq" icon>
                  <CardIcon color="turq">
                    <DataUsage style={{ color: roseColor[0] }} />
                  </CardIcon>
                  <h4 className={cardClasses.cardIconTitle}>
                    {t("blockchain-data-transactions")}
                  </h4>
                </CardHeader>
                <CardBody>
                  <Table
                    hover
                    tableData={[
                      [
                        t("aes-256-cbc-symmetric-key"),
                        renderingData.decryptionKey.symmetricKey,
                      ],
                      [
                        t("aes-256-cbc-initialization-vector"),
                        renderingData.decryptionKey.initializationVector,
                      ],
                      [
                        t("number-of-transaction-chunks"),
                        renderingData.transactionChunks.length.toString(),
                      ],
                      ...renderingData.transactionChunks.map((tx) => {
                        return [
                          `${t("chunk")} ${tx.chunkId}`,
                          JSON.stringify({
                            chunkId: tx.chunkId,
                            encoding: tx.encoding,
                            encrypted: tx.encrypted,
                            transactionId: tx.transactionId,
                            status: tx.transactionStatus,
                          }),
                        ];
                      }),
                    ]}
                    customCellClasses={[classes.wordBreak]}
                    customClassesForCells={[1]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          )}
          <Dialog
            classes={{
              root: classesDialog.center,
              paper: classesDialog.modal,
            }}
            open={modal}
            transition={Transition}
            keepMounted
            scroll="body"
            fullWidth={true}
            maxWidth="md"
            onClose={() => setModal(false)}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classesDialog.modalHeader}
            >
              <Button
                justIcon
                className={classesDialog.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setModal(false)}
              >
                <Close className={classesDialog.modalClose} />
              </Button>
              <h4 className={classesDialog.modalTitle}>
                {t("signature-verification-0")}: <b>{packageDetails._id}</b>
              </h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classesDialog.modalBody}
            >
              {packageSignature.verificationSteps &&
                packageSignature.verificationSteps.map((element) => {
                  return (
                    <React.Fragment key={uuidv4()}>
                      <GridContainer
                        className={classesDialog.cardMargin}
                        key={uuidv4()}
                        style={{ fontWeight: "600" }}
                      >
                        <GridItem xs={12} sm={6} md={4}>
                          STAGE
                        </GridItem>
                        <GridItem xs={12} sm={6} md={8}>
                          {element.stage.toUpperCase()}
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        style={{
                          borderBottom: "1px solid #c4c4c4",
                          paddingBottom: "15px",
                        }}
                      >
                        {element.details.map(([fieldName, fieldValue]) => {
                          let data;
                          if (typeof fieldValue === "object") {
                            data = (
                              <div style={{ whiteSpace: "pre-wrap" }}>
                                {JSON.stringify(fieldValue, undefined, 2)}
                              </div>
                            );
                          } else {
                            data = (
                              <React.Fragment>
                                {fieldValue.toString()}
                              </React.Fragment>
                            );
                          }
                          return (
                            <React.Fragment key={uuidv4()}>
                              <GridItem
                                xs={12}
                                sm={6}
                                md={4}
                                style={{ fontWeight: "400" }}
                              >
                                {fieldName}
                              </GridItem>
                              <GridItem
                                xs={12}
                                sm={6}
                                md={8}
                                className={classesDialog.wordBreak}
                              >
                                {ApiService.checkValidLink(fieldValue) ? (
                                  <a
                                    href={fieldValue}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {fieldValue}
                                  </a>
                                ) : (
                                  data
                                )}
                              </GridItem>
                            </React.Fragment>
                          );
                        })}
                      </GridContainer>
                    </React.Fragment>
                  );
                })}
            </DialogContent>
            <DialogActions
              className={
                classesDialog.modalFooter +
                " " +
                classesDialog.modalFooterCenter
              }
            >
              <Button onClick={() => setModal(false)} color="rose" simple>
                {t("close")}
              </Button>
            </DialogActions>
          </Dialog>
          <span style={{ color: roseColor[0] }}>{alert}</span>
        </GridContainer>
      )}
    </React.Fragment>
  );

  const packagePreview = (
    <React.Fragment>
      {pdfFile.data.length > 0 && preview.length === 0 ? (
        <PDFViewer data={pdfFile} />
      ) : preview.length > 0 ? (
        <div>
          <h4>{preview}</h4>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );

  const handleSetIndex = (index) => {
    setNavPillsIndex(index);
  };

  React.useEffect(() => {
    const previewPackageAsync = (data) => {
      const handleDownloadPackage = async (parameters, signal) => {
        const params = {
          jobId: parameters.jobId,
          sender: parameters.details.senderAddress,
          receiver: parameters.details.receiverAddress,
          data_id: parameters.details.data_id,
          hash: parameters.details.rawHash,
        };
        const responseData = await ApiService.downloadPublicFile(
          params,
          signal
        );

        const fileBuffer = await responseData.arrayBuffer();
        const uint8View = new Uint8Array(fileBuffer);
        setPdfFile({ data: uint8View });
      };
      const handlePreparePackageDownload = async () => {
        // Deletion parameters
        const params = {
          packageId: data.package._id,
          publicShareSecret: data.package.publicShareSecret,
        };

        // Initialize abort controller
        const abortController = new AbortController();
        const signal = abortController.signal;

        let responseData;
        try {
          responseData = await ApiService.preparePublicDownloadPackageFile(
            params,
            signal
          );
        } catch (e) {
          console.error(t("error-downloading-package"));
          return;
        }
        const jobId = responseData.jobId;
        try {
          while (!signal.aborted && responseData.progress !== 100) {
            responseData = await ApiService.queryLatestPublicDownloadJobStatus(
              { jobId },
              signal
            );
            if (responseData.status === ApiService.jobStatus.failed) {
              console.error(t("error-downloading-package"));
              return;
            }
            if (
              responseData.status === ApiService.jobStatus.failedBlockchain ||
              responseData.status === ApiService.jobStatus.done
            ) {
              await handleDownloadPackage(responseData, signal);
            }
          }
          if (signal.aborted) {
            console.error(t("operation-aborted"));
            return;
          }
        } catch (e) {
          console.error(e);
          return;
        }
      };
      handlePreparePackageDownload();
    };
    if (navPillsIndex === 1 && pdfFile.data.length === 0) {
      if (renderingData.package.dataType === "application/pdf") {
        setPreview("");
        previewPackageAsync(renderingData);
      } else {
        setPreview(t("unsuported-file-type-select-a-pdf-for-preview"));
      }
    }
  }, [navPillsIndex, renderingData, pdfFile.data.length, t]);

  return (
    <React.Fragment>
      {!ApiService.isObjectEmpty(renderingData) && (
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>{t("public-shared-package")}</h2>
              <h5 className={classes.description}>{packageDetails._id}</h5>
            </GridItem>
          </GridContainer>
          <NavPills
            color="rose"
            tabs={[
              {
                tabButton: t("details-0"),
                tabContent: packageDetailsBlock,
              },
              {
                tabButton: t("preview"),
                tabContent: packagePreview,
              },
            ]}
            action={handleSetIndex}
          />
        </div>
      )}
    </React.Fragment>
  );
};

PublicSharedPackage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default PublicSharedPackage;
