import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
import Snackbars from "components/Snackbar/Snackbar.js";
import GridContainer from "../../components/Grid/GridContainer";
import NavPills from "components/NavPills/NavPills.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Muted from "components/Typography/Muted.js";
import Loading from "components/Loading/Loading.js";

// material-ui components
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

// Icons
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ReceiptIcon from "@material-ui/icons/Receipt";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PublicIcon from "@material-ui/icons/Public";
import InfoIcon from "@material-ui/icons/Info";
import ClearIcon from "@material-ui/icons/Clear";
import LockIcon from "@material-ui/icons/Lock";
import PeopleIcon from "@material-ui/icons/People";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AddCommentIcon from "@material-ui/icons/AddComment";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EditIcon from "@material-ui/icons/Edit";
import ListAltIcon from "@material-ui/icons/ListAlt";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import AddAlert from "@material-ui/icons/AddAlert";
import Check from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import EmailIcon from "@material-ui/icons/Email";
import DraftsIcon from "@material-ui/icons/Drafts";
import SettingsIcon from "@material-ui/icons/Settings";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import Close from "@material-ui/icons/Close";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";

/*################
  # Form classes #
  ################*/

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import profileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import notificationStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import validator from "validator";
import { Tooltip } from "@material-ui/core";
import {
  roseColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "35px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useProfileStyles = makeStyles(profileStyles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyles);
const useAlertStyles = makeStyles(sweetAlertStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DocumentContainerDetails = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const profileClasses = useProfileStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  // Retrieve document container search parameters
  const query = new URLSearchParams(props.location.search);
  const documentContainerParams = {
    documentContainerId: query.get("documentContainerId"),
  };
  const otherUser = t("other");

  // Document container details
  const [loading, setLoading] = React.useState(true);
  const [ownerActive, setOwnerActive] = React.useState(false);
  const [inviteActive, setInviteActive] = React.useState(false);
  const [showVerificationDialog, setShowVerificationDialog] =
    React.useState(false);
  const [signatureVerification, setSignatureVerification] = React.useState({});
  const [showShareItemDialog, setShowShareItemDialog] = React.useState(false);
  const [shareItemDialogData, setShareItemDialogData] = React.useState({});
  const [documentContainerDetails, setDocumentContainerDetails] =
    React.useState({});
  const [documentContainerQrCode, setDocumentContainerQrCode] =
    React.useState(undefined);
  const [documentContainerAccess, setDocumentContainerAccess] = React.useState(
    ApiService.documentContainerAccess.private
  );
  const [updateDocumentContainerTTL, setUpdateDocumentContainerTTL] =
    React.useState([]);
  const [documentContainerTTL, setDocumentContainerTTL] = React.useState("-1");
  const [documentContainerStoreForever, setDocumentContainerStoreForever] =
    React.useState([]);
  const [showDocumentContainerProgress, setShowDocumentContainerProgress] =
    React.useState(false);
  const [documentContainerProgress, setDocumentContainerProgress] =
    React.useState(0);
  const [
    updateDocumentContainerButtonText,
    setUpdateDocumentContainerButtonText,
  ] = React.useState(t("update-document-container"));

  const [documentContainerTitle, setDocumentContainerTitle] =
    React.useState("");
  const [documentContainerMessage, setDocumentContainerMessage] =
    React.useState("");
  const [documentContainerComment, setDocumentContainerComment] =
    React.useState("");
  const [documentContainerMetadataName, setDocumentContainerMetadataName] =
    React.useState("");
  const [documentContainerMetadataType, setDocumentContainerMetadataType] =
    React.useState(ApiService.metadataTypes.string);
  const [documentContainerMetadata, setDocumentContainerMetadata] =
    React.useState("");
  const [
    documentContainerAccessRightsType,
    setDocumentContainerAccessRightsType,
  ] = React.useState(ApiService.accessRightsTypes.sign);
  const [
    documentContainerAccessRightsEmail,
    setDocumentContainerAccessRightsEmail,
  ] = React.useState("");
  const [
    documentContainerAccessRightsEmailUser,
    setDocumentContainerAccessRightsEmailUser,
  ] = React.useState("");
  const [
    documentContainerAccessRightsTTL,
    setDocumentContainerAccessRightsTTL,
  ] = React.useState("-1");
  const [
    documentContainerAccessRightsForever,
    setDocumentContainerAccessRightsForever,
  ] = React.useState([]);
  const [documentContainerErrorMessage, setDocumentContainerErrorMessage] =
    React.useState("");
  const [documentContainerSuccessMessage, setDocumentContainerSuccessMessage] =
    React.useState("");
  const [usersList, setUsersList] = React.useState([]);
  const [lookupUsers, setLookupUsers] = React.useState({});

  // Document container actions
  const [refreshDisabled, setRefreshDisabled] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const [formattedUserName, setFormattedUserName] = React.useState("");

  const [showSignatureModal, setShowSignatureModal] = React.useState(false);
  const [modalBlockchain, setModalBlockchain] = React.useState(
    ApiService.blockchainTypes.bsv
  );
  const [signatureAbortController, setSignatureAbortController] =
    React.useState(undefined);

  // Modal states
  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("update")
  );
  const [modalUpdateAccess, setModalUpdateAccess] = React.useState(
    ApiService.signatureAccess.private
  );
  const [signatureDetails, setSignatureDetails] = React.useState({});
  const [refreshClicked, setRefreshClicked] = React.useState(false);
  const [userId, setUserId] = React.useState("");

  const clearModalState = () => {
    setShowModal(false);
    setShowSignatureModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("update"));
    setModalUpdateAccess(ApiService.signatureAccess.private);
    setModalBlockchain(ApiService.blockchainTypes.bsv);
    setSignatureDetails({});
    setSignatureAbortController(undefined);
  };

  // move outside
  const getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      setFormattedUserName(
        ApiService.getFormattedUserName(returnData._id, userProfileData)
      );
      params.userId = userProfileData._id;
      params.email = userProfileData.email;
    } else {
      params.userId = returnData._id;
      params.email = returnData.email;
    }
    params.superuser = returnData.superuser;
    return params;
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  const formatUserEntry = (address) => {
    if (lookupUsers.hasOwnProperty(address)) {
      return `${lookupUsers[address].user.email} (${address})`;
    }
    return address;
  };

  const formatRedirectUrl = async (baseUrl) => {
    const { userId } = await getQueryParams();
    const queryParameters = new URLSearchParams({
      userId,
      ...documentContainerParams,
    }).toString();
    return `${baseUrl}?${queryParameters}`;
  };

  // move outside
  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.idFrontendURL}/auth/login-page`);
    url.search = new URLSearchParams({
      deploymentId: ApiService.instanceId,
      redirectPath: `/admin/document-container?documentContainerId=${documentContainerParams.documentContainerId}`,
    });
    return url.toString();
  };

  const handleUpdateDocumentContainerTitleAndMessage = async (event) => {
    event.preventDefault();
    const documentContainerTitleParsed = documentContainerTitle.trim();
    if (documentContainerTitleParsed.length === 0) {
      return showDocumentContainerError(
        t("document-container-title-field-empty")
      );
    }
    const documentContainerMessageParsed = documentContainerMessage.trim();
    if (documentContainerMessageParsed.length === 0) {
      return showDocumentContainerError(
        t("document-container-message-field-empty")
      );
    }
    setRefreshDisabled(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData = undefined;
    let error = false;
    try {
      const { userId } = await getQueryParams(signal);
      returnData = await ApiService.updateDocumentContainer(
        {
          userId,
          title: documentContainerTitleParsed,
          message: documentContainerMessageParsed,
          ...documentContainerParams,
        },
        signal
      );
    } catch (e) {
      error = true;
      showDocumentContainerError(
        t("failed-to-update-document-container-title-and-message")
      );
    }
    handleRefresh(returnData, signal);
    if (!error) {
      return showDocumentContainerSuccess(
        t("document-container-title-and-message-updated-successfully")
      );
    }
  };

  const showDownloadPackage = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-package")}?"`}
        onConfirm={() => {
          downloadPackageAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.title}</b>
        </p>
      </SweetAlert>
    );
  };

  const showDownloadReport = () => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-report")}?"`}
        onConfirm={() => {
          downloadReportAsync();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{documentContainerParams.documentContainerId}</b>
        </p>
      </SweetAlert>
    );
  };

  const downloadError = (resultMessage, responseData = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("error")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {responseData && (
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={responseData.progress}
            />
            <Muted>
              {responseData.jobId} - {responseData.progress}% (
              {moment.utc(responseData.runtime).format("HH:mm:ss.SSS")})
            </Muted>
            <br />
          </React.Fragment>
        )}
        <Danger>{resultMessage}</Danger>
      </SweetAlert>
    );
  };

  const downloadPackageAsync = (data) => {
    const handleDownloadPackage = async (userId, parameters, signal) => {
      const params = {
        userId,
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadFile(params, signal);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      setAlert(null);
      a.click();
    };
    const handlePreparePackageDownload = async () => {
      // Download parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        sender: data.sender,
        receiver: data.receiver,
        data_id: data.data_id,
        hash: data.hash,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.prepareDownloadFile(params, signal);
      } catch (e) {
        return downloadError(t("error-downloading-package"));
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId, userId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            return downloadError(t("error-downloading-package"), responseData);
          }
          if (
            responseData.status === ApiService.jobStatus.failedBlockchain ||
            responseData.status === ApiService.jobStatus.done
          ) {
            await handleDownloadPackage(userId, responseData, signal);
          }
        }
        if (signal.aborted) {
          return downloadError(t("operation-aborted"), responseData);
        }
      } catch (e) {
        return downloadError(t("error-downloading-package"), responseData);
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("preparing-package")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePreparePackageDownload();
  };

  const downloadReportAsync = () => {
    const handleDownloadReport = async (userId, parameters, signal) => {
      const params = {
        userId,
        jobId: parameters.jobId,
      };
      const responseData = await ApiService.downloadReport(params, signal);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      setAlert(null);
      a.click();
    };
    const handlePrepareReportDownload = async () => {
      // Download parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: documentContainerParams.documentContainerId,
        parentType: ApiService.parentTypes.container,
        frontendUrl: getFrontendUrl(),
        creatorName: "UNISOT SDC",
        documentTitle: `UNISOT SDC ${t("report")}`,
        reportTitle: t("report"),
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.prepareDownloadReport(params, signal);
      } catch (e) {
        return downloadError(t("error-downloading-report"));
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId, userId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            return downloadError(t("error-downloading-report"), responseData);
          }
          if (
            responseData.status === ApiService.jobStatus.failedBlockchain ||
            responseData.status === ApiService.jobStatus.done
          ) {
            await handleDownloadReport(userId, responseData, signal);
          }
        }
        if (signal.aborted) {
          return downloadError(t("operation-aborted"), responseData);
        }
      } catch (e) {
        return downloadError(t("error-downloading-report"), responseData);
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("preparing-report")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handlePrepareReportDownload();
  };

  const handleDownloadShareItem = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-share-item")}?`}
        onConfirm={() => {
          downloadShareItem(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const downloadShareItem = async (elem) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const responseData = await ApiService.downloadShareItem({
        userId: queryParams.userId,
        shareId: elem._id,
      });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      let extension;
      if (elem.dataType === ApiService.shareItemDataType.json) {
        extension = "json";
      } else if (elem.dataType === ApiService.shareItemDataType.xml) {
        extension = "xml";
      } else {
        extension = "txt";
      }
      a.download = `${elem._id}.${extension}`;
      a.click();
    } catch (e) {
      downloadError(e.message);
    }
  };

  const handleDownloadMetadata = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("download-metadata")}?`}
        onConfirm={() => {
          downloadMetadata(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const downloadMetadata = async (elem) => {
    setAlert(null);
    try {
      const queryParams = await getQueryParams();
      const responseData = await ApiService.downloadMetadata({
        userId: queryParams.userId,
        metadataId: elem._id,
      });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      let extension;
      if (elem.type === ApiService.metadataTypes.json) {
        extension = "json";
      } else if (elem.type === ApiService.metadataTypes.xml) {
        extension = "xml";
      } else {
        extension = "txt";
      }
      a.download = `${elem._id}.${extension}`;
      a.click();
    } catch (e) {
      downloadError(e.message);
    }
  };

  const handleAddDocumentContainerComment = async (event) => {
    event.preventDefault();
    const documentContainerCommentParsed = documentContainerComment.trim();
    if (documentContainerCommentParsed.length === 0) {
      return showDocumentContainerError(
        t("document-container-comment-field-empty")
      );
    }
    setRefreshDisabled(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData = undefined;
    let error = false;
    try {
      const { userId } = await getQueryParams(signal);
      returnData = await ApiService.addDocumentContainerComment(
        {
          userId,
          comment: documentContainerCommentParsed,
          ...documentContainerParams,
        },
        signal
      );
    } catch (e) {
      error = true;
      showDocumentContainerError(t("failed-to-add-document-container-comment"));
    }
    handleRefresh(returnData, signal);
    if (!error) {
      return showDocumentContainerSuccess(
        t("document-container-comment-added-successfully")
      );
    }
  };

  const handleAddDocumentContainerMetadata = async (event) => {
    event.preventDefault();
    const documentContainerMetadataNameParsed =
      documentContainerMetadataName.trim();
    if (documentContainerMetadataNameParsed.length === 0) {
      return showDocumentContainerError(
        t("document-container-metadata-name-field-empty")
      );
    }
    const documentContainerMetadataParsed = documentContainerMetadata.trim();
    if (documentContainerMetadataParsed.length === 0) {
      return showDocumentContainerError(
        t("document-container-metadata-field-empty")
      );
    }
    let metadata;
    try {
      metadata =
        documentContainerMetadataType === ApiService.metadataTypes.json
          ? JSON.parse(documentContainerMetadataParsed)
          : documentContainerMetadataParsed;
    } catch (e) {
      return showDocumentContainerError(
        t("document-container-invalid-metadata")
      );
    }
    setRefreshDisabled(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData = undefined;
    let error = false;
    try {
      const { userId } = await getQueryParams(signal);
      returnData = await ApiService.addMetadata(
        {
          userId,
          parentId: documentContainerParams.documentContainerId,
          parentType: ApiService.parentTypes.container,
          name: documentContainerMetadataNameParsed,
          type: documentContainerMetadataType,
          metadata,
        },
        signal
      );
    } catch (e) {
      error = true;
      showDocumentContainerError(
        t("failed-to-add-document-container-metadata")
      );
    }
    handleRefresh(returnData, signal);
    if (!error) {
      return showDocumentContainerSuccess(
        t("document-container-metadata-added-successfully")
      );
    }
  };

  const handleAddDocumentContainerAccessRights = async (event) => {
    event.preventDefault();
    const email = documentContainerAccessRightsEmail.trim();
    if (!validator.isEmail(email)) {
      return showDocumentContainerError(
        t("document-container-access-rights-invalid-email")
      );
    }
    const ttl =
      documentContainerAccessRightsTTL !== "-1"
        ? documentContainerAccessRightsTTL.endOf("day").toISOString()
        : "-1";
    const validTTL = !(
      ttl === "-1" && documentContainerAccessRightsForever.length === 0
    );
    if (!validTTL) {
      return showDocumentContainerError(
        t("document-container-access-rights-invalid-eol")
      );
    }
    setRefreshDisabled(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData = undefined;
    let error = false;
    try {
      const { userId } = await getQueryParams(signal);
      returnData = await ApiService.addAccessRights(
        {
          userId,
          parentId: documentContainerParams.documentContainerId,
          parentType: ApiService.parentTypes.container,
          type: documentContainerAccessRightsType,
          email,
          endOfLife: ttl,
        },
        signal
      );
    } catch (e) {
      error = true;
      showDocumentContainerError(
        t("failed-to-add-document-container-access-rights")
      );
    }
    handleRefresh(returnData, signal);
    if (!error) {
      return showDocumentContainerSuccess(
        t("document-container-access-rights-added-successfully")
      );
    }
  };

  const showDocumentContainerError = (messageContent) => {
    setDocumentContainerErrorMessage(messageContent);
    setTimeout(() => {
      setDocumentContainerErrorMessage("");
    }, ApiService.messageTimeout);
  };

  const showDocumentContainerSuccess = (messageContent) => {
    setDocumentContainerSuccessMessage(messageContent);
    setTimeout(() => {
      setDocumentContainerSuccessMessage("");
    }, ApiService.messageTimeout);
  };

  React.useEffect(() => {
    let isMounted = true;

    const abortController = new AbortController();
    const signal = abortController.signal;
    let documentContainerParams;

    const getFrontendUrl = () => {
      const url = new URL(`${ApiService.idFrontendURL}/auth/login-page`);
      url.search = new URLSearchParams({
        deploymentId: ApiService.instanceId,
        redirectPath: `/admin/document-container?documentContainerId=${documentContainerParams.documentContainerId}`,
      });
      return url.toString();
    };

    const getQueryParams = async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      documentContainerParams = {
        documentContainerId: queryParams.get("documentContainerId"),
      };
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        setFormattedUserName(
          ApiService.getFormattedUserName(returnData._id, userProfileData)
        );
        params.userId = userProfileData._id;
        params.email = userProfileData.email;
      } else {
        params.userId = returnData._id;
        params.email = returnData.email;
      }
      params.superuser = returnData.superuser;
      setUserId(params.userId);
      return params;
    };

    const handleRefresh = async (
      returnData = undefined,
      signal = undefined
    ) => {
      setRefreshDisabled(true);
      let documentContainerData, userId, userEmail;
      if (!returnData) {
        try {
          const queryParams = await getQueryParams(signal);
          userId = queryParams.userId;
          userEmail = queryParams.email;
          const queryDocumentContainerParams = {
            userId,
            ...documentContainerParams,
          };
          documentContainerData = await ApiService.getDocumentContainer(
            queryDocumentContainerParams,
            signal
          );
          const qrCode = await ApiService.getDocumentContainerQrCode(
            {
              userId,
              frontendUrl: getFrontendUrl(),
              documentContainerId: documentContainerParams.documentContainerId,
            },
            signal
          );
          setDocumentContainerQrCode(qrCode.qrCode);
        } catch (e) {
          console.error(e);
          props.history.push("/");
        }
      } else {
        documentContainerData = returnData;
      }
      const users = {};
      try {
        if (!userId) {
          const queryParams = await getQueryParams(signal);
          userId = queryParams.userId;
          userEmail = queryParams.email;
        }
        const { address } = await ApiService.getPublicKey({ userId }, signal);
        const responseData = await ApiService.getAllUsers({}, signal);
        // eslint-disable-next-line no-unused-vars
        for (const entry of responseData) {
          users[entry.address] = entry;
        }
        setUsersList(
          responseData.filter((entry) => {
            return entry.address !== address;
          })
        );
        setLookupUsers(users);
        setInviteActive(
          !!documentContainerData.accessRights.find(
            (entry) =>
              entry.status === ApiService.accessRightsStatus.active &&
              entry.email === userEmail &&
              entry.type === ApiService.accessRightsTypes.invite
          )
        );
        setOwnerActive(address === documentContainerData.sender);
      } catch (e) {
        console.error(e);
      }
      setShowVerificationDialog(false);
      setSignatureVerification({});
      setShowShareItemDialog(false);
      setShareItemDialogData({});
      setDocumentContainerDetails(documentContainerData);
      setUpdateDocumentContainerTTL([]);
      setDocumentContainerStoreForever([]);
      setDocumentContainerTTL("-1");
      setShowDocumentContainerProgress(false);
      setDocumentContainerProgress(0);
      setUpdateDocumentContainerButtonText(t("update-document-container"));
      setDocumentContainerAccess(documentContainerData.access);
      setDocumentContainerTitle(documentContainerData.title);
      setDocumentContainerMessage(documentContainerData.message);
      setDocumentContainerComment("");
      setDocumentContainerMetadataName("");
      setDocumentContainerMetadataType(ApiService.metadataTypes.string);
      setDocumentContainerMetadata("");
      setDocumentContainerAccessRightsType(ApiService.accessRightsTypes.sign);
      setDocumentContainerAccessRightsEmail("");
      setDocumentContainerAccessRightsEmailUser("");
      setDocumentContainerAccessRightsTTL("-1");
      setDocumentContainerAccessRightsForever([]);
      setRefreshDisabled(false);
    };

    const apiOperations = async () => {
      try {
        await ApiService.loginRequired(signal);
        await handleRefresh(undefined, signal);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.history, props.location.search, refreshClicked, t]);

  const handleReturnData = (
    data,
    userId,
    formatSharedItems = true,
    formatPackages = true
  ) => {
    let shareItemsFormatted = [];
    if (formatSharedItems) {
      shareItemsFormatted = data.shareItems
        .filter(
          (elem) =>
            elem.status !== ApiService.shareItemStatus.deleted &&
            elem.status !== ApiService.shareItemStatus.expired
        )
        .map((elem) => {
          if (!elem.endOfLife) {
            elem.endOfLife = t("forever");
          }
          elem.blockchainStoreFormatted = elem.blockchainStore
            ? elem.blockchainStore.toString()
            : "false";
          const queryParameters = new URLSearchParams({
            userId: userId,
            shareId: elem._id,
          }).toString();
          elem.actions = (
            <div className="actions-right">
              <Tooltip title={t("share-item-details")}>
                <Link to={`/admin/share-item?${queryParameters}`}>
                  <Button justIcon round simple color="rose" className="like">
                    <MoreVertIcon />
                  </Button>
                </Link>
              </Tooltip>
              <Tooltip title={t("view-details")}>
                <Button
                  justIcon
                  round
                  simple
                  color="rose"
                  className="remove"
                  onClick={async () => {
                    try {
                      const queryParams = await getQueryParams();
                      const params = {
                        shareId: elem._id,
                        userId: queryParams.userId,
                      };
                      const responseData = await ApiService.getShareItem(
                        params
                      );
                      setShareItemDialogData(responseData);
                      setShowShareItemDialog(true);
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                >
                  <VisibilityIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("download-share-item")}>
                <Button
                  justIcon
                  round
                  simple
                  color="rose"
                  className="edit"
                  onClick={() => {
                    handleDownloadShareItem(elem);
                  }}
                >
                  <CloudDownloadIcon />
                </Button>
              </Tooltip>
            </div>
          );
          return elem;
        });
    }
    let packagesFormatted = [];
    if (formatPackages) {
      packagesFormatted = data.packages
        .filter(
          (elem) =>
            elem.status !== ApiService.packageStatus.deleted &&
            elem.status !== ApiService.packageStatus.expired
        )
        .map((elem) => {
          if (!elem.endOfLife) {
            elem.endOfLife = t("forever");
          }
          elem.shareableFormatted = elem.shareable.toString();
          elem.blockchainStoreFormatted = elem.blockchainStore
            ? elem.blockchainStore.toString()
            : "false";
          elem.actions = (
            <div className="actions-right">
              <Tooltip title={t("package-details-0")}>
                <Link
                  to={`/admin/package?${new URLSearchParams({
                    userId,
                    data_id: elem.data_id,
                    sender: elem.sender,
                    receiver: elem.receiver,
                    hash: elem.hash,
                  }).toString()}`}
                >
                  <Button justIcon round simple color="rose" className="like">
                    <MoreVertIcon />
                  </Button>
                </Link>
              </Tooltip>
              <Tooltip title={t("download-file")}>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    showDownloadPackage(elem);
                  }}
                  color="rose"
                  className="remove"
                >
                  <CloudDownloadIcon />
                </Button>
              </Tooltip>
            </div>
          );
          return elem;
        });
    }
    return {
      shareItemsFormatted,
      packagesFormatted,
    };
  };

  const handleDocumentContainerUpdate = async (event) => {
    event.preventDefault();
    const { userId } = await getQueryParams();

    const ttl =
      documentContainerTTL !== "-1"
        ? documentContainerTTL.endOf("day").toISOString()
        : "-1";
    const validTTL = !(
      ttl === "-1" && documentContainerStoreForever.length === 0
    );
    const updateTTL =
      updateDocumentContainerTTL.length > 0 &&
      !(ttl === "-1" && !documentContainerDetails.endOfLife);

    const checksPassed = !(updateDocumentContainerTTL.length > 0 && !validTTL);
    if (!checksPassed) {
      const messageContent = [];
      if (!validTTL) {
        messageContent.push(t("invalid-eol-defined"));
      }
      return showDocumentContainerError(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`
      );
    }
    const updateAccess =
      documentContainerAccess !== documentContainerDetails.access;

    if (!updateTTL && !updateAccess) {
      return;
    }

    setRefreshDisabled(true);
    setUpdateDocumentContainerButtonText(t("updating-document-container"));
    const abortController = new AbortController();
    const signal = abortController.signal;
    let error = false;
    let returnData = undefined;
    // Set progress bar
    setShowDocumentContainerProgress(true);
    setDocumentContainerProgress(20);
    try {
      returnData = await ApiService.updateDocumentContainer(
        {
          userId,
          access: documentContainerAccess,
          endOfLife: ttl,
          ...documentContainerParams,
        },
        signal
      );
      setDocumentContainerProgress(100);
    } catch (e) {
      error = true;
      showDocumentContainerError(t("error-updating-document-container"));
    }
    handleRefresh(returnData, signal);
    if (!error) {
      return showDocumentContainerSuccess(
        t("document-container-successfully-updated")
      );
    }
  };

  const documentContainerResult = (result, resultMessage, redirect = true) => {
    const handleResult = async () => {
      setAlert(null);
      if (redirect) {
        props.history.push("/");
      }
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleResult}
          onCancel={handleResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={100}
            />
            <Muted>100%</Muted>
            <br />
          </React.Fragment>
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleResult}
          onCancel={handleResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <React.Fragment>
            <CustomLinearProgress
              variant="determinate"
              color="primary"
              value={100}
            />
            <Muted>100%</Muted>
            <br />
          </React.Fragment>
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const deleteDocumentContainerAsync = (data) => {
    const handleDocumentContainerDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        documentContainerId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteDocumentContainer(params, signal);
        return documentContainerResult(
          true,
          t("document-container-successfully-deleted")
        );
      } catch (e) {
        return documentContainerResult(
          false,
          t("error-deleting-document-container")
        );
      }
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-document-container")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleDocumentContainerDeletion();
  };

  const showDeleteDocumentContainer = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-container")}?`}
        onConfirm={() => {
          deleteDocumentContainerAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteDocumentContainerCommentAsync = (data) => {
    const handleDocumentContainerCommentDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        commentId: data._id,
        ...documentContainerParams,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteDocumentContainerComment(params, signal);
        documentContainerResult(
          true,
          t("document-container-comment-successfully-deleted"),
          false
        );
      } catch (e) {
        documentContainerResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-document-container-comment")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleDocumentContainerCommentDeletion();
  };

  const showDeleteDocumentContainerComment = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-comment")}?`}
        onConfirm={() => {
          deleteDocumentContainerCommentAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteDocumentContainerMetadataAsync = (data) => {
    const handleDocumentContainerMetadataDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: documentContainerParams.documentContainerId,
        parentType: ApiService.parentTypes.container,
        metadataId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteMetadata(params, signal);
        documentContainerResult(
          true,
          t("document-container-metadata-successfully-deleted"),
          false
        );
      } catch (e) {
        documentContainerResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-document-container-metadata")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleDocumentContainerMetadataDeletion();
  };

  const showDeleteDocumentContainerMetadata = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-metadata")}?`}
        onConfirm={() => {
          deleteDocumentContainerMetadataAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteDocumentContainerAccessRightsAsync = (data) => {
    const handleDocumentContainerAccessRightsDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: documentContainerParams.documentContainerId,
        parentType: ApiService.parentTypes.container,
        accessRightsId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteAccessRights(params, signal);
        documentContainerResult(
          true,
          t("document-container-access-rights-successfully-deleted"),
          false
        );
      } catch (e) {
        documentContainerResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-document-container-access-rights")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleDocumentContainerAccessRightsDeletion();
  };

  const showDeleteDocumentContainerAccessRights = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-access-rights")}?`}
        onConfirm={() => {
          deleteDocumentContainerAccessRightsAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.email}</b>
        </p>
      </SweetAlert>
    );
  };

  const sendInviteAccessRightsAsync = (data) => {
    const handleSendInviteAccessRights = async () => {
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: documentContainerParams.documentContainerId,
        parentType: ApiService.parentTypes.container,
        accessRightsId: data._id,
        frontendUrl: getFrontendUrl(),
        registerUrl: ApiService.idFrontendURL,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.inviteAccessRights(params, signal);
        documentContainerResult(
          true,
          t("invite-email-successfully-sent"),
          false
        );
      } catch (e) {
        documentContainerResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("sending-email-invite")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleSendInviteAccessRights();
  };

  const showSendInviteAccessRights = (data) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("send-an-email-invite")}?`}
        onConfirm={() => {
          sendInviteAccessRightsAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data.email}</b>
        </p>
      </SweetAlert>
    );
  };

  const deleteDocumentContainerSignatureAsync = (data) => {
    const handleDocumentContainerSignatureDeletion = async () => {
      // Deletion parameters
      const { userId } = await getQueryParams();
      const params = {
        userId,
        parentId: data.parentId,
        parentType: data.type,
        signatureId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteSignature(params, signal);
        documentContainerResult(
          true,
          t("document-container-signature-successfully-deleted"),
          false
        );
      } catch (e) {
        documentContainerResult(false, e.message, false);
      }
      handleRefresh();
    };
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("deleting-document-container-signature")}
        onConfirm={() => setAlert(null)}
        showConfirm={false}
      >
        <CustomLinearProgress
          variant="determinate"
          color="primary"
          value={20}
        />
      </SweetAlert>
    );
    handleDocumentContainerSignatureDeletion();
  };

  const showDeleteDocumentContainerSignature = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`${t("delete-signature")}?`}
        onConfirm={() => {
          deleteDocumentContainerSignatureAsync(data);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{data._id}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleDocumentContainerSigning = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      parentId: documentContainerDetails._id,
      parentType: ApiService.parentTypes.container,
      access: documentContainerDetails.access,
      blockchain: modalBlockchain,
      frontendUrl: getFrontendUrl(),
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    setSignatureAbortController(abortController);

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("processing"));

    // Sign share item
    let responseData;
    try {
      responseData = await ApiService.addSignature(params, signal);
    } catch (e) {
      return showErrorModalMessage(t("error-signing-document-container"));
    }
    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId, userId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        setModalProgress(responseData.progress);
        if (responseData.status === ApiService.jobStatus.failed) {
          return showErrorModalMessage(t("error-signing-document-container"));
        }
        if (
          responseData.status === ApiService.jobStatus.failedBlockchain ||
          responseData.status === ApiService.jobStatus.done
        ) {
          return showSuccessModalMessage(
            t("document-container-successfully-signed")
          );
        }
      }
      if (signal.aborted) {
        return showErrorModalMessage(t("operation-aborted"));
      }
    } catch (e) {
      return showErrorModalMessage(t("error-signing-document-container"));
    }
  };

  const showVerifySignatureDialog = async (elem) => {
    try {
      const { userId } = await getQueryParams();
      const data = await ApiService.verifySignature({
        userId,
        signatureId: elem._id,
        parentId: elem.parentId,
        parentType: elem.type,
      });
      setSignatureVerification({
        _id: elem._id,
        verificationSteps: [
          {
            stage: t("input-data"),
            details: [
              ["parentId", elem.parentId],
              ["hash", elem.signedHash],
              ["metadataHash", elem.metadataHash],
              ["metadata", JSON.parse(elem.metadata)],
            ],
          },
          ...data.verificationSteps,
        ],
      });
      setShowVerificationDialog(true);
    } catch {
      showDocumentContainerError(
        t("failed-to-fetch-document-container-signature")
      );
    }
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const handleUpdateSignature = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const updateAccess = modalUpdateAccess !== signatureDetails.access;
    if (!updateAccess) {
      return clearModalState();
    }

    const { userId } = await getQueryParams();
    const params = {
      userId,
      signatureId: signatureDetails._id,
      parentId: signatureDetails.parentId,
      parentType: signatureDetails.type,
      access: modalUpdateAccess,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("updating"));

    try {
      await ApiService.updateSignature(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("signature-successfully-updated"));
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={7}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <ListAltIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {`${t("document-container")}: ${
                  documentContainerDetails.title
                } ${formattedUserName}`}
              </h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  color="rose"
                  round
                  simple
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                  disabled={refreshDisabled}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button>
                  <ListItemIcon>
                    <PeopleIcon style={{ color: roseColor[0] }} />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={t("details")} />
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("id")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={`${profileClasses.alignRight}`}
                    >
                      {documentContainerDetails._id}
                    </GridItem>
                  </GridContainer>
                </ListItem>
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("sender")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      {formatUserEntry(documentContainerDetails.sender)}
                    </GridItem>
                  </GridContainer>
                </ListItem>
              </List>
              <Divider />
              <ListItem button>
                <ListItemIcon>
                  <CheckCircleOutlineIcon style={{ color: roseColor[0] }} />
                </ListItemIcon>
                <ListItemText disableTypography primary={t("process")} />
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("status")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {documentContainerDetails.status}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("access")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {documentContainerDetails.access}
                  </GridItem>
                </GridContainer>
              </ListItem>
              {documentContainerDetails.access ===
                ApiService.documentContainerAccess.public && (
                <ListItem>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={4}
                      className={profileClasses.alignLeft}
                    >
                      {t("publish-link")}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={8}
                      className={profileClasses.alignRight}
                    >
                      <a
                        href={`${
                          ApiService.serviceFrontendURL
                        }/auth/public-document-container/?${new URLSearchParams(
                          {
                            documentContainerId: documentContainerDetails._id,
                            shareSecret: documentContainerDetails.shareSecret,
                          }
                        ).toString()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${
                          ApiService.serviceFrontendURL
                        }/auth/public-document-container/?${new URLSearchParams(
                          {
                            documentContainerId: documentContainerDetails._id,
                            shareSecret: documentContainerDetails.shareSecret,
                          }
                        ).toString()}`}
                      </a>
                    </GridItem>
                  </GridContainer>
                </ListItem>
              )}
              <Divider />
              <ListItem button>
                <ListItemIcon>
                  <DateRangeIcon style={{ color: roseColor[0] }} />
                </ListItemIcon>
                <ListItemText disableTypography primary={t("date-and-time")} />
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("eol")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {ApiService.formatDateTime(
                      documentContainerDetails.endOfLife
                    )}
                  </GridItem>
                </GridContainer>
              </ListItem>
              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("created-at")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {ApiService.formatDateTime(
                      documentContainerDetails.createdAt
                    )}
                  </GridItem>
                </GridContainer>
              </ListItem>

              <ListItem>
                <GridContainer>
                  <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                    {t("updated-at")}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    className={profileClasses.alignRight}
                  >
                    {ApiService.formatDateTime(
                      documentContainerDetails.updatedAt
                    )}
                  </GridItem>
                </GridContainer>
              </ListItem>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={8}>
                  <img
                    height="155px"
                    src={documentContainerQrCode}
                    alt={t("container-qrcode")}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {ownerActive && (
            <Card>
              <CardHeader color="turq" icon>
                <CardIcon color="turq">
                  <EditIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("edit-document-container")}
                </h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleDocumentContainerUpdate}>
                  <React.Fragment>
                    <GridContainer>
                      <GridItem xs={12} sm={3}>
                        <FormLabel
                          className={
                            formClasses.labelHorizontal +
                            " " +
                            formClasses.labelHorizontalRadioCheckbox
                          }
                          style={{ float: "left" }}
                        >
                          {t("change-eol")}
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={2}>
                        <div className={formClasses.checkboxAndRadio}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => {
                                  const currentIndex =
                                    updateDocumentContainerTTL.indexOf(1);
                                  const newChecked = [
                                    ...updateDocumentContainerTTL,
                                  ];
                                  if (currentIndex === -1) {
                                    newChecked.push(1);
                                  } else {
                                    newChecked.splice(currentIndex, 1);
                                  }
                                  setUpdateDocumentContainerTTL(newChecked);
                                }}
                                checkedIcon={
                                  <Check className={formClasses.checkedIcon} />
                                }
                                icon={
                                  <Check
                                    className={formClasses.uncheckedIcon}
                                  />
                                }
                                classes={{
                                  checked: formClasses.checked,
                                  root: formClasses.checkRoot,
                                }}
                                checked={updateDocumentContainerTTL.length > 0}
                              />
                            }
                            classes={{
                              label: formClasses.label,
                              root: formClasses.labelRoot,
                            }}
                            label={t("eol")}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      {updateDocumentContainerTTL.length > 0 && (
                        <React.Fragment>
                          <GridItem
                            xs={12}
                            sm={3}
                            style={{ float: "left" }}
                          ></GridItem>
                          <GridItem xs={12} sm={4} style={{ float: "left" }}>
                            <div className={formClasses.checkboxAndRadio}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    onClick={() => {
                                      if (
                                        updateDocumentContainerTTL.length === 0
                                      ) {
                                        return;
                                      }
                                      const currentIndex =
                                        documentContainerStoreForever.indexOf(
                                          1
                                        );
                                      const newChecked = [
                                        ...documentContainerStoreForever,
                                      ];
                                      if (currentIndex === -1) {
                                        newChecked.push(1);
                                      } else {
                                        newChecked.splice(currentIndex, 1);
                                      }
                                      setDocumentContainerStoreForever(
                                        newChecked
                                      );
                                      if (newChecked.length > 0) {
                                        setDocumentContainerTTL("-1");
                                      }
                                    }}
                                    checkedIcon={
                                      <Check
                                        className={formClasses.checkedIcon}
                                      />
                                    }
                                    icon={
                                      <Check
                                        className={formClasses.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: formClasses.checked,
                                      root: formClasses.checkRoot,
                                    }}
                                    checked={
                                      updateDocumentContainerTTL.length > 0 &&
                                      documentContainerStoreForever.length > 0
                                    }
                                  />
                                }
                                classes={{
                                  label: formClasses.label,
                                  root: formClasses.labelRoot,
                                }}
                                label={t("store-forever")}
                              />
                            </div>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={5}
                            style={{ marginTop: "10px" }}
                          >
                            <FormControl fullWidth>
                              <Datetime
                                dateFormat={"YYYY-MM-DD"}
                                timeFormat={false}
                                inputProps={{
                                  placeholder: t("select-date"),
                                  disabled:
                                    updateDocumentContainerTTL.length === 0 ||
                                    documentContainerStoreForever.length > 0,
                                }}
                                value={
                                  documentContainerTTL === "-1"
                                    ? undefined
                                    : documentContainerTTL
                                }
                                onChange={(e) => {
                                  setDocumentContainerTTL(e);
                                }}
                                closeOnSelect
                              />
                            </FormControl>
                          </GridItem>
                        </React.Fragment>
                      )}
                    </GridContainer>
                  </React.Fragment>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel
                        className={
                          formClasses.labelHorizontal +
                          " " +
                          formClasses.labelHorizontalRadioCheckbox
                        }
                        style={{ float: "left", paddingTop: "40px" }}
                      >
                        {t("access")}
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9} className={formClasses.alignLeft}>
                      <FormControl
                        fullWidth
                        className={formClasses.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="document-container-access"
                          className={formClasses.selectLabel}
                        ></InputLabel>
                        <Select
                          MenuProps={{
                            className: formClasses.selectMenu,
                          }}
                          classes={{
                            select: formClasses.select,
                          }}
                          value={documentContainerAccess}
                          onChange={(e) =>
                            setDocumentContainerAccess(e.target.value)
                          }
                          inputProps={{
                            name: "documentContainerAccess",
                            id: "document-container-access",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: formClasses.selectMenuItem,
                            }}
                          >
                            {t("access")}
                          </MenuItem>
                          {Object.values(
                            ApiService.documentContainerAccess
                          ).map((accessType) => {
                            return (
                              <MenuItem
                                key={accessType}
                                classes={{
                                  root: formClasses.selectMenuItem,
                                  selected:
                                    formClasses.selectMenuItemSelectedMultiple,
                                }}
                                value={accessType}
                              >
                                {accessType}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  {showDocumentContainerProgress && (
                    <React.Fragment>
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={documentContainerProgress}
                      />
                      <Muted>{documentContainerProgress}%</Muted>
                      <br />
                    </React.Fragment>
                  )}
                  <Button
                    color="primary"
                    style={{ float: "right" }}
                    type="submit"
                    disabled={refreshDisabled}
                  >
                    {updateDocumentContainerButtonText}
                  </Button>
                </form>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={8} md={8}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <LockIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("blockchain-info")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                className={`${profileClasses.description} ${profileClasses.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                  {t("hash")}
                </GridItem>
                <GridItem xs={12} sm={8} className={profileClasses.alignRight}>
                  {documentContainerDetails.hash}
                </GridItem>
              </GridContainer>
              <Divider />
              <GridContainer
                className={`${profileClasses.description} ${profileClasses.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={profileClasses.alignLeft}>
                  {t("hmac-key")}
                </GridItem>
                <GridItem xs={12} sm={8} className={profileClasses.alignRight}>
                  {Buffer.from(
                    documentContainerDetails.shareSecret,
                    "hex"
                  ).toString("base64")}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <SettingsIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("document-container-actions")}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                className={`${profileClasses.description}`}
                style={{ textAlign: "center" }}
                justifyContent="center"
              >
                <GridItem xs={2}>
                  <Tooltip title={t("sign-container-hash-0")}>
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        setConfirmModalButtonText(t("sign"));
                        setShowSignatureModal(true);
                      }}
                      color="rose"
                      className="edit"
                    >
                      <BorderColorIcon />
                    </Button>
                  </Tooltip>
                </GridItem>
                <GridItem xs={2}>
                  <Tooltip title={t("download-report")}>
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        showDownloadReport();
                      }}
                      color="rose"
                      className="remove"
                    >
                      <AssessmentIcon />
                    </Button>
                  </Tooltip>
                </GridItem>
                {ownerActive && (
                  <React.Fragment>
                    <GridItem xs={2}>
                      <Tooltip title={t("container-files")}>
                        <Button
                          justIcon
                          round
                          simple
                          onClick={async () => {
                            const url = await formatRedirectUrl(
                              "/admin/my-files"
                            );
                            props.history.push(url);
                          }}
                          color="rose"
                          className="remove"
                        >
                          <FileCopyIcon />
                        </Button>
                      </Tooltip>
                    </GridItem>
                    <GridItem xs={2}>
                      <Tooltip title={t("container-shared-items")}>
                        <Button
                          justIcon
                          round
                          simple
                          color="rose"
                          className="edit"
                          onClick={async () => {
                            const url = await formatRedirectUrl(
                              "/admin/share-items"
                            );
                            props.history.push(url);
                          }}
                        >
                          <PublicIcon />
                        </Button>
                      </Tooltip>
                    </GridItem>
                    <GridItem xs={2}>
                      <Tooltip title={t("delete-container")}>
                        <Button
                          justIcon
                          round
                          simple
                          color="rose"
                          className="remove"
                          disabled={refreshDisabled}
                          onClick={() => {
                            showDeleteDocumentContainer(
                              documentContainerDetails
                            );
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    </GridItem>
                  </React.Fragment>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <NavPills
            color="info"
            alignCenter
            tabs={[
              {
                tabButton: t("details"),
                tabIcon: MailOutlineIcon,
                tabContent: (
                  <Card>
                    <CardBody>
                      <NavPills
                        color="rose"
                        horizontal={{
                          tabsGrid: { xs: 12, sm: 12, md: 2 },
                          contentGrid: { xs: 12, sm: 12, md: 10 },
                        }}
                        tabs={[
                          {
                            tabIcon: MailOutlineIcon,
                            tabContent: (
                              <span>
                                <h4>{t("title")}</h4>
                                <p>{documentContainerDetails.title}</p>
                                <Divider />
                                <h4>{t("message")}</h4>
                                <p>{documentContainerDetails.message}</p>
                              </span>
                            ),
                          },
                          ownerActive
                            ? {
                                tabIcon: DraftsIcon,
                                tabContent: (
                                  <form
                                    onSubmit={
                                      handleUpdateDocumentContainerTitleAndMessage
                                    }
                                  >
                                    <h4 style={{ marginBottom: "15px" }}>
                                      {t("update-title-and-message")}
                                    </h4>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {t("title")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <CustomInput
                                          id="doc-container-title"
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            type: "text",
                                            value: documentContainerTitle,
                                            onChange: (e) => {
                                              setDocumentContainerTitle(
                                                e.target.value
                                              );
                                            },
                                          }}
                                          helpText={t("title")}
                                        />
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("message")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <TextField
                                          required
                                          id="outlined-required"
                                          variant="outlined"
                                          fullWidth
                                          value={documentContainerMessage}
                                          multiline
                                          rows={10}
                                          onChange={(e) =>
                                            setDocumentContainerMessage(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12}>
                                        <Button
                                          color="primary"
                                          type="submit"
                                          disabled={refreshDisabled}
                                          style={{
                                            float: "right",
                                            marginTop: "15px",
                                          }}
                                        >
                                          {t("update")}
                                        </Button>
                                      </GridItem>
                                    </GridContainer>
                                  </form>
                                ),
                              }
                            : undefined,
                        ].filter((x) => x !== undefined)}
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("signatures"),
                tabIcon: ReceiptIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <ReceiptIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("signatures")}
                      </h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          round
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => {
                            handleRefresh();
                          }}
                          disabled={refreshDisabled}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={
                          "signatures" in documentContainerDetails
                            ? documentContainerDetails.signatures.map(
                                (elem) => {
                                  return {
                                    _id: elem._id,
                                    access: elem.access,
                                    parentId: elem.parentId,
                                    sender: formatUserEntry(elem.sender),
                                    type: elem.type,
                                    signedHash: elem.signedHash,
                                    metadataHash: elem.metadataHash,
                                    transactionId: elem.transactionId,
                                    transactionStatus: elem.transactionStatus,
                                    actions: (
                                      <div className="actions-right">
                                        <Tooltip
                                          title={t("signature-verification")}
                                        >
                                          <Button
                                            justIcon
                                            round
                                            simple
                                            onClick={() => {
                                              showVerifySignatureDialog(elem);
                                            }}
                                            color="rose"
                                            className="remove"
                                          >
                                            <ReceiptIcon />
                                          </Button>
                                        </Tooltip>
                                        {elem.transactionStatus ===
                                        ApiService.jobTransactionStatus
                                          .written ? (
                                          <a
                                            href={
                                              ApiService.populatePath(
                                                ApiService.blockchainInfoURL,
                                                {
                                                  transactionId:
                                                    elem.transactionId,
                                                }
                                              ).populatedPath
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <Tooltip
                                              title={t("blockchain-info-url")}
                                            >
                                              <Button
                                                justIcon
                                                round
                                                simple
                                                color="rose"
                                                className="remove"
                                              >
                                                <InfoIcon />
                                              </Button>
                                            </Tooltip>
                                          </a>
                                        ) : (
                                          <Tooltip
                                            title={t("blockchain-info-url")}
                                          >
                                            <Button
                                              justIcon
                                              round
                                              simple
                                              color="rose"
                                              className="remove"
                                              disabled
                                            >
                                              <InfoIcon />
                                            </Button>
                                          </Tooltip>
                                        )}
                                        {ownerActive && (
                                          <React.Fragment>
                                            <Tooltip
                                              title={t("update-signature")}
                                            >
                                              <Button
                                                justIcon
                                                round
                                                simple
                                                color="rose"
                                                className="edit"
                                                onClick={() => {
                                                  setSignatureDetails(elem);
                                                  setModalUpdateAccess(
                                                    elem.access
                                                  );
                                                  setShowModal(true);
                                                }}
                                              >
                                                <EditIcon />
                                              </Button>
                                            </Tooltip>
                                            <Tooltip
                                              title={t("delete-signature")}
                                            >
                                              <Button
                                                justIcon
                                                round
                                                simple
                                                className="remove"
                                                onClick={() => {
                                                  showDeleteDocumentContainerSignature(
                                                    elem
                                                  );
                                                }}
                                              >
                                                <ClearIcon
                                                  style={{
                                                    color: grayColor[17],
                                                  }}
                                                />
                                              </Button>
                                            </Tooltip>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    ),
                                  };
                                }
                              )
                            : []
                        }
                        filterable
                        columns={[
                          {
                            Header: t("id"),
                            accessor: "_id",
                          },
                          {
                            Header: t("access"),
                            accessor: "access",
                          },
                          {
                            Header: t("user"),
                            accessor: "sender",
                          },
                          {
                            Header: t("type"),
                            accessor: "type",
                          },
                          {
                            Header: t("signed-hash"),
                            accessor: "signedHash",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("metadata-hash"),
                            accessor: "metadataHash",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("txid"),
                            accessor: "transactionId",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("status"),
                            accessor: "transactionStatus",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={6}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("files"),
                tabIcon: FileCopyIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <FileCopyIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>{t("files")}</h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          round
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => {
                            handleRefresh();
                          }}
                          disabled={refreshDisabled}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                        {ownerActive && (
                          <Button
                            color="primary"
                            style={{ marginTop: "20px" }}
                            round
                            onClick={async () => {
                              const url = await formatRedirectUrl(
                                "/admin/my-files"
                              );
                              props.history.push(url);
                            }}
                          >
                            {t("details")}
                          </Button>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={
                          handleReturnData(
                            documentContainerDetails,
                            userId,
                            false,
                            true
                          ).packagesFormatted
                        }
                        filterable
                        columns={[
                          {
                            Header: t("title"),
                            accessor: "title",
                          },
                          {
                            Header: t("file-name"),
                            accessor: "fileName",
                          },
                          {
                            Header: t("file-format"),
                            accessor: "dataType",
                          },
                          {
                            Header: t("file-size"),
                            accessor: "fileSize",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("service"),
                            accessor: "storageService",
                          },
                          {
                            Header: t("blockchain-store"),
                            accessor: "blockchainStoreFormatted",
                          },
                          {
                            Header: t("eol"),
                            accessor: "endOfLife",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {ApiService.formatDateTime(row.value)}
                              </div>
                            ),
                          },
                          {
                            Header: t("hash"),
                            accessor: "hash",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("txid"),
                            accessor: "transactionId",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={6}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("shared-items"),
                tabIcon: PublicIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <PublicIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("shared-items")}
                      </h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          round
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => {
                            handleRefresh();
                          }}
                          disabled={refreshDisabled}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                        {ownerActive && (
                          <Button
                            color="primary"
                            style={{ marginTop: "20px" }}
                            round
                            onClick={async () => {
                              const url = await formatRedirectUrl(
                                "/admin/share-items"
                              );
                              props.history.push(url);
                            }}
                          >
                            {t("details")}
                          </Button>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={
                          handleReturnData(
                            documentContainerDetails,
                            userId,
                            true,
                            false
                          ).shareItemsFormatted
                        }
                        filterable
                        columns={[
                          {
                            Header: t("id"),
                            accessor: "_id",
                          },
                          {
                            Header: t("item-id"),
                            accessor: "itemId",
                          },
                          {
                            Header: t("access"),
                            accessor: "access",
                          },
                          {
                            Header: t("service"),
                            accessor: "sharingService",
                          },
                          {
                            Header: t("blockchain-store"),
                            accessor: "blockchainStoreFormatted",
                          },
                          {
                            Header: t("type"),
                            accessor: "dataType",
                          },
                          {
                            Header: t("status"),
                            accessor: "status",
                          },
                          {
                            Header: t("eol"),
                            accessor: "endOfLife",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {ApiService.formatDateTime(row.value)}
                              </div>
                            ),
                          },
                          {
                            Header: t("hash"),
                            accessor: "hash",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("txid"),
                            accessor: "transactionId",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={6}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("metadata"),
                tabIcon: LibraryBooksIcon,
                tabContent: (
                  <Card>
                    <CardBody>
                      <NavPills
                        color="rose"
                        horizontal={{
                          tabsGrid: { xs: 12, sm: 12, md: 2 },
                          contentGrid: { xs: 12, sm: 12, md: 10 },
                        }}
                        tabs={[
                          {
                            tabIcon: LibraryBooksIcon,
                            tabContent: (
                              <React.Fragment>
                                <ReactTable
                                  data={
                                    "metadata" in documentContainerDetails
                                      ? documentContainerDetails.metadata.map(
                                          (elem) => {
                                            return {
                                              id: elem._id,
                                              user: formatUserEntry(elem.user),
                                              date: elem.date,
                                              type: elem.type,
                                              name: elem.name,
                                              metadata: elem.metadata,
                                              actions: (
                                                <div className="actions-right">
                                                  <Tooltip
                                                    title={t(
                                                      "download-metadata"
                                                    )}
                                                  >
                                                    <Button
                                                      justIcon
                                                      round
                                                      simple
                                                      color="rose"
                                                      className="edit"
                                                      onClick={() => {
                                                        handleDownloadMetadata(
                                                          elem
                                                        );
                                                      }}
                                                    >
                                                      <CloudDownloadIcon />
                                                    </Button>
                                                  </Tooltip>
                                                  {ownerActive && (
                                                    <Tooltip
                                                      title={t(
                                                        "delete-metadata"
                                                      )}
                                                    >
                                                      <Button
                                                        justIcon
                                                        round
                                                        simple
                                                        className="remove"
                                                        onClick={() => {
                                                          showDeleteDocumentContainerMetadata(
                                                            elem
                                                          );
                                                        }}
                                                      >
                                                        <ClearIcon
                                                          style={{
                                                            color:
                                                              grayColor[17],
                                                          }}
                                                        />
                                                      </Button>
                                                    </Tooltip>
                                                  )}
                                                </div>
                                              ),
                                            };
                                          }
                                        )
                                      : []
                                  }
                                  columns={[
                                    {
                                      Header: t("user"),
                                      accessor: "user",
                                    },
                                    {
                                      Header: t("date"),
                                      accessor: "date",
                                      headerStyle: { textAlign: "right" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "right" }}>
                                          {ApiService.formatDateTime(row.value)}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("type"),
                                      accessor: "type",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("name"),
                                      accessor: "name",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("metadata"),
                                      accessor: "metadata",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("actions"),
                                      accessor: "actions",
                                      sortable: false,
                                      filterable: false,
                                    },
                                  ]}
                                  filterable
                                  defaultPageSize={8}
                                  showPaginationTop={false}
                                  showPaginationBottom
                                  className="-striped -highlight"
                                />
                              </React.Fragment>
                            ),
                          },
                          ownerActive
                            ? {
                                tabIcon: LibraryAddIcon,
                                tabContent: (
                                  <form
                                    onSubmit={
                                      handleAddDocumentContainerMetadata
                                    }
                                  >
                                    <h4 style={{ marginBottom: "15px" }}>
                                      {t("enter-your-metadata")}
                                    </h4>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {t("name")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <CustomInput
                                          id="doc-container-1-title"
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            type: "text",
                                            value:
                                              documentContainerMetadataName,
                                            onChange: (e) => {
                                              setDocumentContainerMetadataName(
                                                e.target.value
                                              );
                                            },
                                          }}
                                          helpText={t("metadata-name")}
                                        />
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("type")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <FormControl
                                          fullWidth
                                          className={
                                            formClasses.selectFormControl
                                          }
                                        >
                                          <InputLabel
                                            htmlFor="data-type"
                                            className={formClasses.selectLabel}
                                          ></InputLabel>
                                          <Select
                                            value={
                                              documentContainerMetadataType
                                            }
                                            onChange={(e) => {
                                              setDocumentContainerMetadataType(
                                                e.target.value
                                              );
                                            }}
                                            MenuProps={{
                                              className: formClasses.selectMenu,
                                            }}
                                            classes={{
                                              select: formClasses.select,
                                            }}
                                            inputProps={{
                                              name: "metadataType",
                                              id: "metadata-type",
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                              }}
                                            >
                                              {t("data-type")}
                                            </MenuItem>
                                            {Object.values(
                                              ApiService.metadataTypes
                                            ).map((metadataType) => {
                                              return (
                                                <MenuItem
                                                  key={metadataType}
                                                  classes={{
                                                    root: formClasses.selectMenuItem,
                                                    selected:
                                                      formClasses.selectMenuItemSelectedMultiple,
                                                  }}
                                                  value={metadataType}
                                                >
                                                  {metadataType}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("metadata")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={11}>
                                        <TextField
                                          required
                                          id="outlined-required"
                                          variant="outlined"
                                          fullWidth
                                          value={documentContainerMetadata}
                                          multiline
                                          rows={3}
                                          onChange={(e) =>
                                            setDocumentContainerMetadata(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </GridItem>
                                    </GridContainer>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      disabled={refreshDisabled}
                                      style={{
                                        float: "right",
                                        marginTop: "15px",
                                      }}
                                    >
                                      {t("add-metadata")}
                                    </Button>
                                  </form>
                                ),
                              }
                            : undefined,
                        ].filter((x) => x !== undefined)}
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("comments"),
                tabIcon: QuestionAnswerIcon,
                tabContent: (
                  <Card>
                    <CardBody>
                      <NavPills
                        color="rose"
                        horizontal={{
                          tabsGrid: { xs: 12, sm: 12, md: 2 },
                          contentGrid: { xs: 12, sm: 12, md: 10 },
                        }}
                        tabs={[
                          {
                            tabIcon: QuestionAnswerIcon,
                            tabContent: (
                              <React.Fragment>
                                <ReactTable
                                  data={
                                    "comments" in documentContainerDetails
                                      ? documentContainerDetails.comments.map(
                                          (elem) => {
                                            return {
                                              id: elem._id,
                                              user: formatUserEntry(elem.user),
                                              date: elem.date,
                                              comment: elem.comment,
                                              actions: (
                                                <div className="actions-right">
                                                  <Tooltip
                                                    title={t("delete-comment")}
                                                  >
                                                    <Button
                                                      justIcon
                                                      round
                                                      simple
                                                      className="remove"
                                                      onClick={() => {
                                                        showDeleteDocumentContainerComment(
                                                          elem
                                                        );
                                                      }}
                                                    >
                                                      <ClearIcon
                                                        style={{
                                                          color: grayColor[17],
                                                        }}
                                                      />
                                                    </Button>
                                                  </Tooltip>
                                                </div>
                                              ),
                                            };
                                          }
                                        )
                                      : []
                                  }
                                  columns={[
                                    {
                                      Header: t("user"),
                                      accessor: "user",
                                    },
                                    {
                                      Header: t("date"),
                                      accessor: "date",
                                      headerStyle: { textAlign: "right" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "right" }}>
                                          {ApiService.formatDateTime(row.value)}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("comment"),
                                      accessor: "comment",
                                      headerClassName: "textLeft",
                                      sortable: false,
                                      headerStyle: { textAlign: "left" },
                                      Cell: (row) => (
                                        <div style={{ textAlign: "left" }}>
                                          {row.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: t("actions"),
                                      accessor: "actions",
                                      sortable: false,
                                      filterable: false,
                                    },
                                  ]}
                                  filterable
                                  defaultPageSize={8}
                                  showPaginationTop={false}
                                  showPaginationBottom
                                  className="-striped -highlight"
                                />
                              </React.Fragment>
                            ),
                          },
                          {
                            tabIcon: AddCommentIcon,
                            tabContent: (
                              <form
                                onSubmit={handleAddDocumentContainerComment}
                              >
                                <h4 style={{ marginBottom: "15px" }}>
                                  {t("enter-your-comment")}
                                </h4>
                                <TextField
                                  required
                                  id="outlined-required"
                                  variant="outlined"
                                  fullWidth
                                  value={documentContainerComment}
                                  multiline
                                  rows={15}
                                  onChange={(e) =>
                                    setDocumentContainerComment(e.target.value)
                                  }
                                />
                                <Button
                                  color="primary"
                                  type="submit"
                                  disabled={refreshDisabled}
                                  style={{
                                    float: "right",
                                    marginTop: "15px",
                                  }}
                                >
                                  {t("add-comment")}
                                </Button>
                              </form>
                            ),
                          },
                        ]}
                      />
                    </CardBody>
                  </Card>
                ),
              },
              ownerActive || inviteActive
                ? {
                    tabButton: t("access-rights"),
                    tabIcon: LockIcon,
                    tabContent: (
                      <Card>
                        <CardBody>
                          <NavPills
                            color="rose"
                            horizontal={{
                              tabsGrid: { xs: 12, sm: 12, md: 2 },
                              contentGrid: { xs: 12, sm: 12, md: 10 },
                            }}
                            tabs={[
                              {
                                tabIcon: LockIcon,
                                tabContent: (
                                  <React.Fragment>
                                    <ReactTable
                                      data={
                                        "accessRights" in
                                        documentContainerDetails
                                          ? documentContainerDetails.accessRights.map(
                                              (elem) => {
                                                return {
                                                  id: elem._id,
                                                  type: elem.type,
                                                  email: elem.email,
                                                  invited:
                                                    elem.invited.toString(),
                                                  endOfLife: elem.endOfLife,
                                                  actions: (
                                                    <div className="actions-right">
                                                      <Tooltip
                                                        title={t(
                                                          "send-an-email-invite"
                                                        )}
                                                      >
                                                        <Button
                                                          justIcon
                                                          round
                                                          simple
                                                          color="rose"
                                                          className="edit"
                                                          onClick={() => {
                                                            showSendInviteAccessRights(
                                                              elem
                                                            );
                                                          }}
                                                        >
                                                          <EmailIcon />
                                                        </Button>
                                                      </Tooltip>
                                                      <Tooltip
                                                        title={t(
                                                          "delete-access-rights"
                                                        )}
                                                      >
                                                        <Button
                                                          justIcon
                                                          round
                                                          simple
                                                          className="remove"
                                                          onClick={() => {
                                                            showDeleteDocumentContainerAccessRights(
                                                              elem
                                                            );
                                                          }}
                                                        >
                                                          <ClearIcon
                                                            style={{
                                                              color:
                                                                grayColor[17],
                                                            }}
                                                          />
                                                        </Button>
                                                      </Tooltip>
                                                    </div>
                                                  ),
                                                };
                                              }
                                            )
                                          : []
                                      }
                                      columns={[
                                        {
                                          Header: t("email"),
                                          accessor: "email",
                                        },
                                        {
                                          Header: t("type"),
                                          accessor: "type",
                                        },
                                        {
                                          Header: t("invited"),
                                          accessor: "invited",
                                        },
                                        {
                                          Header: t("eol"),
                                          accessor: "endOfLife",
                                          headerStyle: { textAlign: "right" },
                                          Cell: (row) => (
                                            <div style={{ textAlign: "right" }}>
                                              {ApiService.formatDateTime(
                                                row.value
                                              )}
                                            </div>
                                          ),
                                        },
                                        {
                                          Header: t("actions"),
                                          accessor: "actions",
                                          sortable: false,
                                          filterable: false,
                                        },
                                      ]}
                                      filterable
                                      defaultPageSize={8}
                                      showPaginationTop={false}
                                      showPaginationBottom
                                      className="-striped -highlight"
                                    />
                                  </React.Fragment>
                                ),
                              },
                              {
                                tabIcon: EnhancedEncryptionIcon,
                                tabContent: (
                                  <form
                                    onSubmit={
                                      handleAddDocumentContainerAccessRights
                                    }
                                  >
                                    <h4 style={{ marginBottom: "15px" }}>
                                      {t("add-access-rights")}
                                    </h4>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {t("eol")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem
                                        xs={12}
                                        sm={2}
                                        style={{ float: "left" }}
                                      >
                                        <div
                                          className={
                                            formClasses.checkboxAndRadio
                                          }
                                        >
                                          <FormControlLabel
                                            style={{ float: "left" }}
                                            control={
                                              <Checkbox
                                                tabIndex={-1}
                                                onClick={() => {
                                                  const currentIndex =
                                                    documentContainerAccessRightsForever.indexOf(
                                                      1
                                                    );
                                                  const newChecked = [
                                                    ...documentContainerAccessRightsForever,
                                                  ];
                                                  if (currentIndex === -1) {
                                                    newChecked.push(1);
                                                  } else {
                                                    newChecked.splice(
                                                      currentIndex,
                                                      1
                                                    );
                                                  }
                                                  setDocumentContainerAccessRightsForever(
                                                    newChecked
                                                  );
                                                  if (newChecked.length > 0) {
                                                    setDocumentContainerAccessRightsTTL(
                                                      "-1"
                                                    );
                                                  }
                                                }}
                                                checkedIcon={
                                                  <Check
                                                    className={
                                                      formClasses.checkedIcon
                                                    }
                                                  />
                                                }
                                                icon={
                                                  <Check
                                                    className={
                                                      formClasses.uncheckedIcon
                                                    }
                                                  />
                                                }
                                                classes={{
                                                  checked: formClasses.checked,
                                                  root: formClasses.checkRoot,
                                                }}
                                                checked={
                                                  documentContainerAccessRightsForever.length >
                                                  0
                                                }
                                              />
                                            }
                                            classes={{
                                              label: formClasses.label,
                                              root: formClasses.labelRoot,
                                            }}
                                            label={t("forever")}
                                          />
                                        </div>
                                      </GridItem>
                                      <GridItem xs={12} sm={4}>
                                        <div
                                          className={
                                            formClasses.checkboxAndRadio
                                          }
                                        >
                                          <FormControl fullWidth>
                                            <Datetime
                                              dateFormat={"YYYY-MM-DD"}
                                              timeFormat={false}
                                              inputProps={{
                                                placeholder: t("select-date"),
                                                disabled:
                                                  documentContainerAccessRightsForever.length >
                                                  0,
                                              }}
                                              value={
                                                documentContainerAccessRightsTTL ===
                                                "-1"
                                                  ? undefined
                                                  : documentContainerAccessRightsTTL
                                              }
                                              onChange={(e) => {
                                                setDocumentContainerAccessRightsTTL(
                                                  e
                                                );
                                              }}
                                              closeOnSelect
                                            />
                                          </FormControl>
                                        </div>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal +
                                            " " +
                                            formClasses.labelHorizontalRadioCheckbox
                                          }
                                          style={{
                                            float: "left",
                                            paddingTop: "40px",
                                          }}
                                        >
                                          {t("type")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={6}>
                                        <FormControl
                                          fullWidth
                                          className={
                                            formClasses.selectFormControl
                                          }
                                        >
                                          <InputLabel
                                            htmlFor="access-rights-type"
                                            className={formClasses.selectLabel}
                                          ></InputLabel>
                                          <Select
                                            value={
                                              documentContainerAccessRightsType
                                            }
                                            onChange={(e) => {
                                              setDocumentContainerAccessRightsType(
                                                e.target.value
                                              );
                                            }}
                                            MenuProps={{
                                              className: formClasses.selectMenu,
                                            }}
                                            classes={{
                                              select: formClasses.select,
                                            }}
                                            inputProps={{
                                              name: "accessRightsType",
                                              id: "access-rights-type",
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                              }}
                                            >
                                              {t("type")}
                                            </MenuItem>
                                            {Object.values(
                                              ApiService.accessRightsTypes
                                            ).map((accessRightsType) => {
                                              return (
                                                <MenuItem
                                                  key={accessRightsType}
                                                  classes={{
                                                    root: formClasses.selectMenuItem,
                                                    selected:
                                                      formClasses.selectMenuItemSelectedMultiple,
                                                  }}
                                                  value={accessRightsType}
                                                >
                                                  {accessRightsType}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                      <GridItem xs={12} sm={1}>
                                        <FormLabel
                                          className={
                                            formClasses.labelHorizontal
                                          }
                                          style={{ float: "left" }}
                                        >
                                          {t("email")}
                                        </FormLabel>
                                      </GridItem>
                                      <GridItem xs={12} sm={6}>
                                        <FormControl
                                          fullWidth
                                          className={
                                            formClasses.selectFormControl
                                          }
                                        >
                                          <InputLabel
                                            htmlFor="access-rights-email"
                                            className={formClasses.selectLabel}
                                          ></InputLabel>
                                          <Select
                                            value={
                                              documentContainerAccessRightsEmailUser
                                            }
                                            onChange={(e) => {
                                              const selected = e.target.value;
                                              if (selected === otherUser) {
                                                setDocumentContainerAccessRightsEmail(
                                                  ""
                                                );
                                              } else {
                                                setDocumentContainerAccessRightsEmail(
                                                  selected
                                                );
                                              }
                                              setDocumentContainerAccessRightsEmailUser(
                                                selected
                                              );
                                            }}
                                            MenuProps={{
                                              className: formClasses.selectMenu,
                                            }}
                                            classes={{
                                              select: formClasses.select,
                                            }}
                                            inputProps={{
                                              name: "accessRightsEmail",
                                              id: "access-rights-email",
                                            }}
                                          >
                                            <MenuItem
                                              disabled
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                              }}
                                            >
                                              {t("email")}
                                            </MenuItem>
                                            {usersList.map((entry) => {
                                              return (
                                                <MenuItem
                                                  key={entry.user._id}
                                                  classes={{
                                                    root: formClasses.selectMenuItem,
                                                    selected:
                                                      formClasses.selectMenuItemSelectedMultiple,
                                                  }}
                                                  value={entry.user.email}
                                                >
                                                  {formatUserEntry(
                                                    entry.address
                                                  )}
                                                </MenuItem>
                                              );
                                            })}
                                            <MenuItem
                                              classes={{
                                                root: formClasses.selectMenuItem,
                                                selected:
                                                  formClasses.selectMenuItemSelectedMultiple,
                                              }}
                                              value={otherUser}
                                            >
                                              {t("other")}
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    </GridContainer>
                                    {documentContainerAccessRightsEmailUser ===
                                      otherUser && (
                                      <GridContainer>
                                        <GridItem xs={12} sm={1}>
                                          <FormLabel
                                            className={
                                              formClasses.labelHorizontal
                                            }
                                            style={{ float: "left" }}
                                          ></FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={3}>
                                          <CustomInput
                                            id="email"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              type: "text",
                                              value:
                                                documentContainerAccessRightsEmail,
                                              onChange: (e) => {
                                                setDocumentContainerAccessRightsEmail(
                                                  e.target.value
                                                );
                                              },
                                            }}
                                            helpText={t("email")}
                                          />
                                        </GridItem>
                                      </GridContainer>
                                    )}
                                    <GridContainer>
                                      <GridItem xs={12} sm={7}>
                                        <Button
                                          color="primary"
                                          type="submit"
                                          disabled={refreshDisabled}
                                          style={{
                                            float: "right",
                                            marginTop: "15px",
                                          }}
                                        >
                                          {t("add-access")}
                                        </Button>
                                      </GridItem>
                                    </GridContainer>
                                  </form>
                                ),
                              },
                            ]}
                          />
                        </CardBody>
                      </Card>
                    ),
                  }
                : undefined,
            ].filter((x) => x !== undefined)}
          />
        </GridItem>
      </GridContainer>
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={documentContainerSuccessMessage}
        open={documentContainerSuccessMessage.length > 0}
        closeNotification={() => showDocumentContainerSuccess("")}
        close
      />
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={documentContainerErrorMessage}
        open={documentContainerErrorMessage.length > 0}
        closeNotification={() => showDocumentContainerError("")}
        close
      />
      <Dialog
        classes={{
          root: dialogClasses.center,
          paper: dialogClasses.modal,
        }}
        open={showVerificationDialog}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth={true}
        maxWidth="md"
        onClose={() => setShowVerificationDialog(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <Button
            justIcon
            className={dialogClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowVerificationDialog(false)}
          >
            <Close className={dialogClasses.modalClose} />
          </Button>
          <h4 className={dialogClasses.modalTitle}>
            {t("signature-verification-0")}: <b>{signatureVerification._id}</b>
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={dialogClasses.modalBody}
        >
          {signatureVerification.verificationSteps &&
            signatureVerification.verificationSteps.map((element) => {
              return (
                <React.Fragment key={uuidv4()}>
                  <GridContainer
                    className={dialogClasses.cardMargin}
                    key={uuidv4()}
                    style={{ fontWeight: "600" }}
                  >
                    <GridItem xs={12} sm={6} md={4}>
                      ${t("stage")}
                    </GridItem>
                    <GridItem xs={12} sm={6} md={8}>
                      {element.stage.toUpperCase()}
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    style={{
                      borderBottom: "1px solid #c4c4c4",
                      paddingBottom: "15px",
                    }}
                  >
                    {element.details.map(([fieldName, fieldValue]) => {
                      let data;
                      if (typeof fieldValue === "object") {
                        data = (
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            {JSON.stringify(fieldValue, undefined, 2)}
                          </div>
                        );
                      } else {
                        data = (
                          <React.Fragment>
                            {fieldValue.toString()}
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment key={uuidv4()}>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={4}
                            style={{ fontWeight: "400" }}
                          >
                            {fieldName}
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={8}
                            className={dialogClasses.wordBreak}
                          >
                            {ApiService.checkValidLink(fieldValue) ? (
                              <a
                                href={fieldValue}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {fieldValue}
                              </a>
                            ) : (
                              data
                            )}
                          </GridItem>
                        </React.Fragment>
                      );
                    })}
                  </GridContainer>
                </React.Fragment>
              );
            })}
        </DialogContent>
        <DialogActions
          className={
            dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
          }
        >
          <Button
            onClick={() => setShowVerificationDialog(false)}
            color="rose"
            simple
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("update-signature-0")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleUpdateSignature}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("access")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="access-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalUpdateAccess}
                    onChange={(e) => {
                      setModalUpdateAccess(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "accessType",
                      id: "access-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("access")}
                    </MenuItem>
                    {Object.values(ApiService.signatureAccess).map(
                      (accessType) => {
                        return (
                          <MenuItem
                            key={accessType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={accessType}
                          >
                            {accessType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: dialogClasses.center,
          paper: dialogClasses.modal,
        }}
        open={showShareItemDialog}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth={true}
        maxWidth="md"
        onClose={() => setShowShareItemDialog(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <Button
            justIcon
            className={dialogClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="rose"
            onClick={() => setShowShareItemDialog(false)}
          >
            <Close className={dialogClasses.modalClose} />
          </Button>
          <h4 className={dialogClasses.modalTitle}>
            {t("share-item")}:
            <b>
              {shareItemDialogData.shareItem
                ? shareItemDialogData.shareItem._id
                : ""}
            </b>
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={dialogClasses.modalBody}
        >
          <GridContainer>
            <GridItem className={dialogClasses.wordBreak}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(shareItemDialogData, undefined, 2)}
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={
            dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
          }
        >
          <Button
            onClick={() => setShowShareItemDialog(false)}
            simple
            color="rose"
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showSignatureModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>
            {t("sign-container-hash-0")} {formattedUserName}
          </h4>
        </DialogTitle>
        <form onSubmit={handleDocumentContainerSigning}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("blockchain")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="blockchain-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalBlockchain}
                    onChange={(e) => {
                      setModalBlockchain(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "blockchainType",
                      id: "blockchain-type",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("blockchain")}
                    </MenuItem>
                    {Object.values(ApiService.blockchainTypes).map(
                      (blockchainType) => {
                        return (
                          <MenuItem
                            key={blockchainType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={blockchainType}
                          >
                            {blockchainType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                if (signatureAbortController) {
                  signatureAbortController.abort();
                }
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {alert}
    </div>
  );
};

DocumentContainerDetails.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default DocumentContainerDetails;
